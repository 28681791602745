.searchBar-wrap {
    background-color: #f0f0f0;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 5px;
    height: fit-content;
  }
  
  .searchBar-wrap form {
    display: flex;
    align-items: center;
  }
  
  .searchBar-wrap input {
    background-color: #f0f0f0;
    outline: none;
    border: none;
  }
  .searchBar-wrap input::placeholder{
    color: #000;
    font-size: 14px;
  }
  
  .searchBar-wrap span {
    padding-right: 0.5rem;
    cursor: pointer;
  }
  
  .searchBar-wrap button {
    outline: none;
    border: none;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    background-color: #34A9FF;
    color: #fff;
  }
  .blog-buttons {
    display: flex;
    justify-content: end;
    column-gap: 15px;
    align-items: center;
    height: fit-content;
  }
  .blog-search {
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
    align-items: center;
}
.blog-buttons button.search-btn {
  height: fit-content;
  border: none;
  background: none;
  padding: 0px;
}
.blog-buttons button.search-btn a{
  font-size: 16px;
  line-height: 24px;
  font-family: 'urbanist-medium';
  color: #000;
}