.widerangencxproducts {
    background: #FFFFFF;
    padding: 100px 0px 120px 0px;
    position: relative;
    /* height: 1000px !important; */
}
.whatwe-head p {
    font-size: 16px;
    font-family: 'urbanist-medium';
    text-transform: uppercase;
    color: #34A9FF;
    display: none;
}
.whatwe-head h1 {
    font-family: 'urbanist-regular';
    font-size: 52px;
    padding-top: 0px;
    margin-bottom: 25px;
}
button.pro-tabs {
    font-size: 22px;
    font-family: 'urbanist-light';
    color: #000;
    border: none;
    padding: 0px;
    background: none !important;
    position: relative;
}
button.pro-tabs:after {
    background-color: #34a9ff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 32px;
    transition: 1s;
    width: 0;
}
button.pro-tabs:hover:after {
    width: 100%;
}
/* button.pro-tabs.active {
    color: #349AFF;
} */
button.pro-tabs.active:after{
    background-color: #34a9ff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 32px;
    transition: 1s;
    width: 100%;
}
.whatwe-head {
    padding: 0px 20px;
}
.product-tabs-btn {
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.pro-content {
    display: none;
}
.pro-content.active {
    display: block;
}
.deri-row {
    background: #EBEBEB;
    background-color: #EBEBEB;
    padding: 40px 50px 40px 50px;
    display: flex;
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
    min-width: 1261px !important;
}
.deri-row .col {
    width: 50%;
}
img.deri-image {
    width: 100%;
    max-width: 400px;
}
.derivatives-trade .product-img {
    /* text-align: end; */
    align-items: center;
    display: flex;
    justify-content: end;
}
.product-img{
    text-align: center;
}
.bullets {
    display: flex;
    column-gap: 8px;
    margin-bottom: 20px;
}
.bullets span {
    background: #212121;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 15px;
    padding: 5px 15px;
    border-radius: 50px;
    min-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.deri-row .col h1 {
    font-size: 32px;
    font-family: 'urbanist-medium';
    line-height: 42px;
    margin-bottom: 40px;
    text-align: left;
}
.derivatives-trade h1 {
    width: 107% !important;
}
p.deriv-p {
    width: 65%;
    padding-top: 15px;
    text-align: left;
}
.prod-butns {
    display: flex;
    margin-top: 30px;
    column-gap: 15px;
}
a.der-buttons {
    font-size: 16px;
    font-family: 'urbanist-regular';
    border: 1px solid #349AFF;
    border-radius: 50px;
    text-align: center;
    color: #349AFF;
    width: 130px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}
a.der-buttons:hover{
    background: #34A9FF;
    color: #FFFFFF;
    text-decoration: none;
}
a.der-buttons:focus, a.der-buttons:focus-visible{
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
a.get-app {
    font-size: 16px;
    font-family: 'urbanist-regular';
    color: #FFF;
    background: #000000;
    width: 150px;
    height: 46px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
a.get-app:hover{
    text-decoration: none !important;
}
.crypto-trade{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689499591/ncx-api-bg_bczp4l.jpg);
    background-color: #ADB9D1;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 50px 50px 50px 70px;
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
}
.crypto-row {
    display: flex;
}
.crypto-trade h1 {
    font-size: 36px !important;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    width: 84%;
    margin-bottom: 5px;
    text-align: left;
}
.crypto-trade p {
    font-size: 20px !important;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    margin-bottom: 25px;
    text-align: left;
}
.crypto-row .bullets span {
    background: #FFFFFF !important;
    color: #000000;
}
p.pro-desc {
    margin-top: 20px;
}
.crypto-trade a.der-buttons {
    color: #FFFFFF !important;
    border-color: #FFFFFF !important;
}
.crypto-trade a.der-buttons:hover{
    border-color: #349AFF !important;
}
.buycrypt-btn{
    font-size: 16px;
    font-family: 'urbanist-regular';
    border: 1px solid #349AFF;
    background: #349AFF;;
    border-radius: 50px;
    text-align: center;
    color: #FFFFFF;
    width: 150px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buycrypt-btn:hover{
    background: #000000 !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    border: 1px solid #000000 !important;
}
.crypto-row .col.product-img {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    padding-right: 40px;
}
.cutting-edge-trade h1 {
    margin-bottom: 25px !important;
}
p.p-01 {
    margin-top: 20px;
}
.cutting-edge-trade .product-img{
    position: relative;
    text-align: end;
}
.cutting-edge-trade .product-img img {
    max-width: 520px;
    position: relative;
    top: 40px;
    right: -60px;
}
.crypto-trade img.deri-image{
    width: 350px;
}
img.dex-image {
    width: 100%;
    max-width: 520px;
}
.ncx-dexphone-sect .col.product-img {
    text-align: end;
}
p.dexp1 {
    font-size: 18px;
    line-height: 24px;
    width: 70%;
    height: 84px;
    font-family: 'urbanist-regular';
    text-align: left;
}
.ncx-dexphone-sect h1 {
    margin-bottom: 20px !important;
    margin-top: 20px;
}
.ncx-dexphone-sect .deri-row {
    padding-left: 80px !important;
}
p.dexp2 {
    font-size: 18px;
    font-family: 'urbanist-regular';
    line-height: 24px;
    width: 50%;
    padding-top: 10px;
    text-align: left;
}
.ncx-card-trade{
    /* background: url(../../../images/ncx-card-traebg.jpg); */
    /* background-color: #B5C0D6; */
    background-color: #EBEBEB;
    background: #EBEBEB;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* padding: 50px 50px 50px 70px; */
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
    max-height: 586px;
    position: relative;
}
video#buy-video {
    height: 586px;
    width: auto;
}
.card_video.row {
    margin: 0 !important;
}
.card_video.row .col:nth-child(1) {
    width: 40%;
    padding: 0px;
}
.card_video.row .col:nth-child(2) {
    width: 60%;
    padding: 0px;
}
.cardtrade-row {
    display: flex;
    position: absolute;
    top: 0px;
}
.cardtrade-row .col{
    width: 50% !important;
    flex: inherit !important;
}
.cardtrade-row .col:nth-child(1){
    padding: 50px 50px 50px 70px;
}
.cardtrade-row .col.product-img {
    position: relative;
}
.cardtrade-row .col.product-img img {
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -85px;
    min-width: 603px;
}
.ncx-card-trade .cardtrade-row h1 {
    font-size: 34px;
    line-height: 40px;
    color: #000000;
    font-family: 'urbanist-medium';
    margin-bottom: 20px;
    text-align: left;
}
.ncx-card-trade p.cardp1 {
    color: #000000;
    font-family: 'urbanist-regular';
    font-size: 18px;
    line-height: 24px;
    width: 80%;
    margin-bottom: 20px;
    text-align: left;
}
.cardtrade-row .bullets span {
    color: #FFFFFF;
    background: #000000;
}
p.cardp2{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    font-family: 'urbanist-regular';
    color: #000000;
    text-align: left;
}
.cardtrade-row a.der-buttons {
    color: #34A9FF;
    border-color: #34A9FF;
}
.cardtrade-row a.der-buttons:hover {
    color: #FFFFFF;
    border-color: #34A9FF;
    background: #34A9FF;
}
.cardtrade-row a.buycrypt-btn {
    color: #FFFFFF;
    background: #000000;
    border-color: #000000;
}
.cardtrade-row a.buycrypt-btn:hover {
    text-decoration: none;
}
h1.margin-bottomzero {
    margin-bottom: 0px !important;
}
.cryptotrading-div img.deri-image {
    width: 100%;
    max-width: 600px !important;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
}
p.deriv-p.crypttradep2 {
    width: 100%;
    padding: 0px;
}
.cryptotrading-div .col.product-img {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    position: relative;
}
.cryptotrading-div .col.product-img img{
    position: relative;
    right: -30px;
}
.ncx-apisect{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689499591/ncx-api-bg_bczp4l.jpg);
    background-color: #ACBAD2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 60px 50px 50px 50px;
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
    max-height: 886px;
}
.api-row{
    display: flex;
}
.api-row .col:nth-child(1) {
    width: 70%;
}
.api-row .col:nth-child(2) {
    width: 30%;
    flex: inherit;
}
.api-monitoring{
    max-width: 350px;
    height: 400px;
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689499591/ncx-api-img_t39ypz.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 20px;
    border-radius: 15px;
    background-position: center;
}
.api-monitoring h3 {
    font-size: 24px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 30px;
    margin-bottom: 15px;
}
.api-monitoring p {
    font-size: 16px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 23px;
}
.col.apiproduct-img {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    border-radius: 8px;
}
.api-row h1 {
    font-size: 34px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 40px;
    text-align: left;
}
.API-search .searchbar-api{
    width: 100%;
}
.ncx-apisect .accordian_wrapper {
    max-width: 700px;
    background: #FFFFFF;
}
.API-search .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #F1F4FB;
    padding: 15px 0px;
    cursor: pointer;
}
.API-search .accordian h2 {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    font-family: 'urbanist-regular' !important;
    text-align: left;
}
.API-search {
    max-width: 700px;
    background: #FFFFFF;
    margin-top: 30px;
}
.ncx-apisect .accordian_wrapper {
    padding: 20px;
}
span.apisvg.transform {
    transform: rotate(90deg);
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
span.apisvg {
    transform: rotate(0deg);
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
.API-search .content p {
    color: #000000 !important;
    font-family: 'urbanist-regular' !important;
    font-size: 16px !important;
}
.content.show{
    margin-bottom: 20px !important;
}
.API-search .item:nth-child(1) .title{
    border: none !important;
}
.ncx-tradeassets{
    background: #EBEBEB;
    padding: 40px 50px 40px 50px;
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
    max-height: 2410px;
}
.asset-hed {
    text-align: end;
}
.asset-hed h1 {
    font-size: 35px;
    line-height: 44px;
    color: #000000;
    font-family: 'urbanist-medium';
}
.asset-hed p {
    font-family: 'urbanist-regular';
    font-size: 17px;
    line-height: 24px;
    color: #34A9FF;
}
.ncx-tradeassets div#trade-tabs-sect {
    max-width: 987px !important;
    padding: 20px 0px 0px 0px !important;
}
.ncx-tradeassets button.trade-tabs.active{
    color: #34a9ff !important;
}
.ncx-tradeassets .content-tabs {
    background: #FFFFFF;
    padding: 30px 30px;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 25%);
    border-radius: 0px 5px 5px 5px;
}
.ncx-tradeassets button.conttrade-tabs.active{
    border-color: #34A9FF !important;
}
.TradeNCXToken{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689499591/ncx-api-bg_bczp4l.jpg);
    background-color: #acbad2;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 60px 50px 50px 50px;
    max-width: 1261px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 586px;
    max-height: 586px;
}
.TradeNCXToken .row.tokenrow {
    display: none;
}
.TradeNCXToken .ncxtoken {
    padding: 0px !important;
}
.TradeNCXToken .tokentable {
    padding: 0px !important;
}
.TradeNCXToken .color, .TradeNCXToken .coltwo p, .TradeNCXToken .colthree p, .TradeNCXToken .colfour p, .TradeNCXToken .colfive p, 
.TradeNCXToken .colsix p, .TradeNCXToken .colseven p, .TradeNCXToken .coleight p, .TradeNCXToken .colnine p {
    background: rgba(255,255,255,0.6);
    color: #000000;
    transform: translateY(0%);
    transition: .4s ease;
}
.TradeNCXToken .color:hover, .TradeNCXToken .coltwo p:hover, .TradeNCXToken .colthree p:hover, .TradeNCXToken .colfour p:hover, 
.TradeNCXToken .colfive p:hover, .TradeNCXToken .colsix p:hover, .TradeNCXToken .colseven p:hover, .TradeNCXToken .coleight p:hover, 
.TradeNCXToken .colnine p:hover{
    transform: translateY(0%) scale(1.06);
    -webkit-transform: translateY(0%) scale(1.06);
    -moz-transform: translateY(0%) scale(1.06);
    cursor: pointer;
}
.TradeNCXToken .colfour p:nth-child(1):hover, .TradeNCXToken .colsix p:nth-child(1):hover, 
.TradeNCXToken .colsix p:nth-child(2):hover, .TradeNCXToken .colsix p:nth-child(3):hover, 
.TradeNCXToken .colseven p:nth-child(1):hover, .TradeNCXToken .colseven p:nth-child(2):hover, 
.TradeNCXToken .colseven p:nth-child(3):hover, .TradeNCXToken .colseven p:nth-child(4):hover, 
.TradeNCXToken .coleight p:nth-child(1):hover, .TradeNCXToken .coleight p:nth-child(2):hover, 
.TradeNCXToken .coleight p:nth-child(3):hover, .TradeNCXToken .coleight p:nth-child(4):hover, 
.TradeNCXToken .coleight p:nth-child(5):hover, .TradeNCXToken .colfive p:first-child:hover,
.TradeNCXToken .colseven p:nth-child(5):hover, .TradeNCXToken .coleight p:nth-child(6):hover{
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    cursor: inherit !important;
}
.colone p{
    color: #000000 !important;
}
.TradeNCXToken .colfour p:nth-child(1), .TradeNCXToken .colsix p:nth-child(1), .TradeNCXToken .colsix p:nth-child(2), 
.TradeNCXToken .colsix p:nth-child(3), .TradeNCXToken .colseven p:nth-child(1), .TradeNCXToken .colseven p:nth-child(2), 
.TradeNCXToken .colseven p:nth-child(3), .TradeNCXToken .colseven p:nth-child(4), .TradeNCXToken .colseven p:nth-child(5), 
.TradeNCXToken .coleight p:nth-child(1), .TradeNCXToken .coleight p:nth-child(2), .TradeNCXToken .coleight p:nth-child(3), 
.TradeNCXToken .coleight p:nth-child(4), .TradeNCXToken .coleight p:nth-child(5), .TradeNCXToken .coleight p:nth-child(6), 
.TradeNCXToken .colfive p:first-child{
    background: rgba(241, 250, 241, 0.3) !important;
}
.TradeNCXToken .colnine, .TradeNCXToken p.nine {
    display: none !important;
}
.TradeNCXToken .colone, .TradeNCXToken .coltwo, .TradeNCXToken .colthree, .TradeNCXToken .colfour, .TradeNCXToken .colfive, 
.TradeNCXToken .colsix, .TradeNCXToken .colseven, .TradeNCXToken .coleight, .TradeNCXToken .colnine{
    width: 13% !important;
}
.TradeNCXToken .tokenhead p{
    width: 13% !important;
}




/* *********************************************************************************************************************** */
.sections-links {
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
}
.sections-links a.nav-linkss {
    font-size: 22px;
    color: #000000;
    font-family: 'urbanist-semibold';
}
.nav-linkss.active{
    color: #349AFF !important;
}
.sections-content .hide {
    display: block !important;
}
.sections-content .active{
    display: block;
}
.stickyrow{
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
    padding-top: 80px !important;
}
@supports (position: sticky) {
    .is-sticky { 
    }
}
.pro-content.active {
    opacity: 1;
    animation: fade 1s linear;
}
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

/* ************************************************************* */
.widerangencxproducts.active {
    background: #000000;
    padding: 100px 0px 0px 0px !important;
}
.widerangencxproducts.active .whatwe-head h1{
    color: #FFFFFF !important;
}
.widerangencxproducts.active button.pro-tabs {
    color: #FFFFFF;
}
.widerangencxproducts.active .crypto-trade{
    position: relative !important;
    background: #000000 !important;
    background-color: #000000 !important;
    padding: 50px !important;
}
.widerangencxproducts.active .crypto-row {
    display: flex;
    position: absolute;
    top: 0;
    width: 100% !important;
}
.widerangencxproducts.active .crypto-row .col:nth-child(1) {
    padding: 50px 0px 50px 0px;
}
.widerangencxproducts.active .crypto-row .bullets span {
    background: rgba(33, 33, 33, 0.7) !important;
    color: #FFFFFF !important;
}
.widerangencxproducts.active a.buycrypt-btn {
    background: rgba(33, 33, 33, 0.7) !important;
    border: none;
}
.widerangencxproducts.active .crypto-trade video#buy-video{
    height: 526px !important;
    position: relative;
    top: -30px !important;
}


span.mbl-bltshow {
    display: none;
}
.pro-content .col.product-img video#buy-video {
    display: none;
}
@media screen and (max-width: 1280px){
    .deri-row, .ncx-card-trade{
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .cardtrade-row .col.product-img img{
        min-width: 100% !important;
    }
}
@media screen and (max-width: 800px){
    .widerangencxproducts{
        padding: 30px 0px 20px 0px !important;
        margin-top: -1px;
    }
    .whatwe-head{
        padding: 0px;
    }
    .whatwe-head p{
        font-size: 12px;
    }
    .whatwe-head h1{
        font-size: 26px;
        line-height: 32px;
    }
    .product-tabs-btn {
        width: 100% !important;
        display: block;
        padding: 0px !important;
    }
    button.pro-tabs{
        font-size: 15px;
        font-family: 'urbanist-medium';
        min-width: fit-content;
        margin-right: 15px;
        margin-bottom: 10px;
    }
    /* button.pro-tabs.active{
        font-family: 'urbanist-semibold';
    } */
    button.pro-tabs.active:after, button.pro-tabs:after{
        top: 24px !important;
    }
    .deri-row {
        min-width: inherit !important;
        max-width: inherit !important;
        display: block !important;
        padding: 20px 10px !important;
        margin-top: 10px !important;
    }
    .deri-row .col{
        width: 100% !important;
    }
    .deri-row .col h1{
        font-size: 22px !important;
        font-family: 'urbanist-medium';
        line-height: 26px !important;
        width: 100% !important;
        margin-bottom: 20px !important;
    }
    .bullets span {
        font-size: 10px !important;
        padding: 5px 10px !important;
        min-height: inherit !important;
    }
    .bullets{
        margin-bottom: 10px !important;
        column-gap: 5px !important;
    }
    span.mbl-bltshow {
        display: block;
    }
    span.mbl-blthide {
        display: none;
    }
    p.deriv-p{
        width: 100% !important;
        padding-top: 10px !important;
        font-size: 14px;
        line-height: 20px !important;
    }
    .prod-butns {
        display: none;
    }
    .pro-content .col.product-img{
        justify-content: center !important;
        padding: 0px !important;
    }
    .pro-content .col.product-img img{
        right: inherit;
        text-align: center !important;
        width: 100% !important;
        position: relative;
        min-width: inherit !important;
        top: inherit !important;
        left: inherit !important;
        margin-top: 20px;
    }
    .crypto-trade h1, .cardtrade-row h1, .api-row h1{
        font-size: 22px !important;
        font-family: 'urbanist-medium';
        line-height: 26px !important;
        width: 100% !important;
        margin-bottom: 10px !important;
    }
    .asset-hed h1{
        font-size: 26px !important;
        font-family: 'urbanist-medium';
        line-height: 32px !important;
    }
    .crypto-trade, .ncx-card-trade{
        background-size: cover;
        min-width: inherit !important;
        max-width: inherit !important;
        display: block !important;
        padding: 20px 10px !important;
        margin-top: 10px !important;
    }
    .crypto-trade p, p.cardp1, p.cardp2, .asset-hed p{
        font-size: 14px !important;
        line-height: 19px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }
    .crypto-row {
        display: block;
    }
    .ncx-dexphone-sect .deri-row, .ncx-tradeassets{
        min-width: inherit !important;
        max-width: inherit !important;
        display: block !important;
        padding: 20px 10px !important;
    }
    #content-3 .ncx-dexphone-sect h1{
        margin-top: 0px;
        margin-bottom: 10px !important;
    }
    p.dexp1 {
        font-size: 14px;
        line-height: 19px;
        width: 100%;
        height: inherit !important;
        margin-bottom: 20px !important;
    }
    p.dexp2 {
        font-size: 14px;
        font-family: 'urbanist-regular';
        line-height: 19px;
        width: 100%;
        padding-top: 0px;
        margin-bottom: 20px;
    }
    .cardtrade-row, .api-row{
        display: block;
    }
    .api-row .col{
        width: 100% !important;
    }
    #content-4 .ncx-card-trade, .ncx-apisect{
        max-height: inherit !important;
        background-size: cover !important;
        background-position: center !important;
    }
    .ncx-apisect{
        padding: 20px 10px !important;
        margin-top: 10px !important;
    }
    #content-5 h1.margin-bottomzero {
        margin-bottom: 0px !important;
    }
    #content-5 .bullets{
        column-gap: 2px !important;
    }
    #content-5 .bullets span{
        padding: 5px 7px !important;
        font-size: 9px !important;
    }
    span.apisvg svg {
        width: 8px;
        height: auto;
    }
    .api-monitoring{
        max-width: inherit !important;
        height: 320px !important;
        background-size: cover !important;
        background-position: center bottom;
        margin-top: 20px;
    }
    .api-monitoring h3{
        font-size: 22px;
        line-height: 24px !important;
        margin-bottom: 5px;
    }
    .api-monitoring p{
        font-size: 16px !important;
        line-height: 24px;
    }
    #content-7 button.trade-tabs{
        min-width: 70px !important;
    }
    #content-7 .ncx-tradeassets .content-tabs {
        padding: 20px 15px !important;
    } 
    #content-7 .currecnycol-txt h1{
        font-size: 14px !important
    }
    #content-7 .ncx-tradeassets{
        max-height: 2850px !important;
        margin-top: 10px !important;
    }
    .TradeNCXToken{
        padding: 20px 10px !important;
        min-width: inherit !important;
        max-width: inherit !important;
        margin-top: 10px !important;
        max-height: inherit !important;
        background-size: cover !important;
    }
    .TradeNCXToken .ncxtoken .container {
        padding: 0px !important;
    }
    .mbl-tokrow h3{
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'urbanist-regular';
        line-height: 19px !important;
        padding-bottom: 2px;
        border-bottom: 1px solid #FFF;
        width: fit-content;
    }
    .mbl-tokrowitems {
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
    }
    .mbl-tokrowitems .color {
        display: block !important;
        padding: 5px;
        text-align: center;
        margin: 0px !important;
        width: 10.5%;
        min-height: 35px;
        border-radius: 3px;
        background: rgba(31, 31, 31, 0.6);
    }
    .mbl-tokrowitems .color div {
        width: 13px;
        height: 13px;
        margin: 0 auto;
    }
    .mbl-tokrowitems p {
        font-size: 10px;
        line-height: 12px;
        padding-top: 3px;
        font-family: 'urbanist-regular';
    }
    .mbl-tokrow {
        margin-bottom: 15px;
    }
    .mbl-tokrowitems.sd2 .color {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .color.inactive {
        background: rgba(241, 250, 241, 0.3);
    }
    .derivative .color.inactive {
        width: 21%;
    }
    .mbl-tokrowitems.sd2 .color svg {
        width: 14px;
    }
    .widerangencxproducts .pro-content .bullets {
        display: flex;
    }
    .card_video.row {
        display: none;
    }
    .pro-content .col.product-img video#buy-video {
        display: block;
        width: 100%;
        height: auto !important;
    }
    .ncx-card-trade .cardtrade-row {
        position: relative;
    }
    .cardtrade-row .col{
        width: 100% !important;
        padding: 0px !important;
    }
    #content-4 .ncx-card-trade{
        padding-bottom: 0px !important;
    }
    .cardtrade-row .col:nth-child(1) {
        margin-bottom: 50px !important;
    }
    .widerangencxproducts.active .crypto-trade{
        padding: unset !important;
    }
    .widerangencxproducts.active .crypto-row{
        min-width: inherit !important;
        max-width: inherit !important;
        display: block !important;
        padding: 20px 10px !important;
        margin-top: 10px !important;
    }
    .widerangencxproducts.active .crypto-row .col:nth-child(1){
        padding: unset !important;
    }
    .widerangencxproducts.active .crypto-trade video#buy-video{
        top: 0px !important;
    }
}