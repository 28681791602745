.indices__hdd{
    /* background: url(../../../images/ind__bg.png);
    background-repeat: no-repeat;
    background-size: 100% 140%; */
    padding: 50px 0px 80px 0px;
    /* background-position: center top; */
}
.indices__hdd .row {
    margin: 0px;
    display: flex;
}
.indices__hdd .row .col{
    flex: 0 0 50%;
    padding: 0px;
}
/* .col.mgg{
    text-align: center;
} */
.tvt__f h1 {
    font-size: 38px;
    line-height: 40px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    width: 60%;
    margin-bottom: 30px;
}
.points__ff span {
    background: rgba(33, 33, 33, 0.7);
    border-radius: 50px;
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    padding: 6.5px 15px;
}
.points__ff{
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
}
.hh__btn{
    display: flex;
    column-gap: 15px;
    padding-top: 20px;
}
a.up_sin {
    width: 135px;
    height: 44px;
    /* background: #5ED3F0; */
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border-radius: 40px !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-family: 'urbanist-regular' !important;
    text-transform: capitalize !important;
}
a.get__ap {
    min-width: 150px;
    height: 44px;
    /* background: rgba(33, 33, 33, 0.7); */
    /* border: 1px solid #5ED3F0; */
    border-radius: 25px;
    color: #FFF;
    font-family: 'urbanist-regular';
    font-size: 16px;
    text-transform: capitalize;
}
a.up_sin:hover, a.up_sin:focus, a.up_sin:focus-visible{
    text-decoration: none !important;
    outline: none !important;
}
a.get__ap:hover{
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
a.get__ap:focus, a.get__ap:focus-visible{
    outline: none !important;
    text-decoration: none;
}
a.up_sin:hover{
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    color: #000000 !important;
    background: #FFFFFF;
}
/* .col.mgg {
    display: flex;
    gap: 10px;
} */
/* .col.mgg img {
    width: 100%;
    max-width: 330px;
    border: 1px solid rgba(255,255,255,0.14);
} */

.col.tvt__f{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.col.mgg video {
    width: 100%;
    max-height: 600px;
}
.tvt__f.mobile{
    display: none !important
}

@media screen and (min-width: 1024px){
    .indices__hdd .container {
        padding: 0px 50px !important;
    }
}
@media screen and (max-width: 1100px){
    .col.mgg video{
        max-height: 500px !important;
    }
}