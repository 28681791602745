.ind__fq summary{
    color: #FFFFFF !important;
    padding: 0px !important;
    font-size: 18px !important;
    font-family: 'roboto-light' !important;
    background: hsla(0,0%,100%,.05);
    border: 1px solid hsla(0,0%,100%,.15);
    padding: 12.5px 20px !important;
    border-radius: 10px !important;
}
.ind__fq .contt{
    color: #FFFFFF !important;
}
.ind__fq .blue svg{
    width: 16px !important;
    transition: all .4s cubic-bezier(1,1,1,1);
}
.ind__fq details[open] .blue svg{
    transform: rotate(180deg);
    transition: all .4s cubic-bezier(1,1,1,1);
}
.ind__fq .accord {
    max-width: 900px;
    margin: 0 auto;
}
.ind__fq details {
    margin-bottom: 15px !important;
}
.ind__fq details[open] .contt {
    padding: 20px 20px 0px 20px;
    transition: none !important;
}
.ind__fq{position: relative; padding: 100px 0px;}
/* .ind__fq {
    padding: 0px 0px 80px 0px;
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689500938/ind__bg_c72nk0.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top -90px;
    position: relative;
} */
.ind__fq .container{
    position: relative;
}
.ind__fq details:last-child{margin: 0px !important;}
.ind__fq:before{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 2%;
    margin: 0 auto;
}
.ind__fq:after{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);;
    display: block;
    position: absolute;
    top: 0;
    right: 2%;
    margin: 0 auto;
}
.contt ul {
    padding: 0px;
    margin-bottom: 10px;
}
.contt ul li {
    list-style-type: none;
    font-size: 16px;
    font-family: 'roboto-light' !important;
    line-height: 24px;
    margin-bottom: 10px;
}
.contt p {
    font-size: 16px !important;
    font-family: 'roboto-light' !important;
    line-height: 24px;
    margin-bottom: 15px;
}
.contt p span{
    display: block;
}
.contt p strong{
    font-family: 'roboto-medium';
    font-weight: 500;
    letter-spacing: 0.3px;
}