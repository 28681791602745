.wallet__defi {
    padding: 100px 0px;
}
.cfd__cnt{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689418505/about_defi_v4g8qu.png);
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    min-height: 800px;
    overflow: hidden;
    position: relative;
    display: flex;
    background-position: center;
    align-items: center;
}
.cfd__cnt .cd {
    text-align: center;
    width: 60%;
}
.cfd__cnt h1 {
    font-size: 48px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    padding: 0px 50px;
    margin-bottom: 20px;
}
.cfd__cnt p {
    font-size: 15px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
}