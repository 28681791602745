.ncxearingsteps{
    background: #F7F9FE;
    padding: 80px 0px;
    text-align: center; 
}
.ncxearingsteps h1 {
    font-size: 42px;
    font-family: 'urbanist-medium';
    line-height: 46px;
    color: #40475A;
}
.steps-row {
    display: flex;
    column-gap: 50px;
    padding-top: 50px;
}
.steps-row .col{
    width: 33.333%;
}
.steps-row img {
    max-height: 200px;
    width: auto;
    margin-bottom: 30px;
}
.steps-row p {
    width: 78%;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'urbanist-regular';
    line-height: 24px;
    color: #2C2236;
}
.ncxearingsteps a.earn-toget{
    font-size: 18px;
    font-family: 'urbanist-semibold';
    width: 165px;
    height: 52px;
    background: #925BCA;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 50px;
}
.ncxearingsteps a.earn-toget:hover{
    background: #f6fafd;
    color: #000;
}
.ncxearingsteps a.earn-toget:focus, .ncxearingsteps a.earn-toget:focus-visible{
    outline: none;
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .ncxearingsteps{
        padding: 50px 0px;
    }
    .ncxearingsteps h1{
        font-size: 26px;
        line-height: 32px;
    }
    .steps-row{
        display: block;
    }
    .steps-row .col{
        width: 100%;
        text-align: center;
    }
    .steps-row img {
        max-height: 150px;
    }
    .steps-row p{
        width: 100%;
        font-size: 16px;
        line-height: 24px;
    }
    .steps-row .col:nth-child(2){
        margin: 40px 0px;
    }
    .ncxearingsteps a.earn-toget{
        width: 145px;
        height: 40px;
        font-size: 16px;
    }
}