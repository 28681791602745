.ncx_token_aa {
    margin-top: -56px;
    position: relative;
}
.row.slider_countdown {
    visibility: hidden;
}
video#token_video {
    width: 100%;
    max-height: 950px;
    object-fit: cover;
    object-position: center;
}
.token_first_sect{
    width: 100%;
    padding: 200px 0px 50px 0px;
    background-position: center;
    position: absolute;
    top: 0px;
}
.row:before, .row:after{
    display: none;
}
.txt112 h1 {
    font-size: 26px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    width: 74%;
    line-height: 45px;
    margin-bottom: 40px;
}
a.token_signin {
    background: #5ED3F0;
    padding: 15px 30px;
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    border-radius: 50px;
    border: 1px solid #5ED3F0;
}
a.token_signin:hover{
    background: rgba(31, 31, 31, 0.55);
    border: 1px solid #5ED3F0;
    text-decoration: none;
}
a.token_signin:focus, a.token_signin:focus-visible{
    text-decoration: none !important;
    outline: none;
    box-shadow: none;
}
a.claim_airdrop{
    background: rgba(31, 31, 31, 0.55);
    border: 1.5px solid #5ED3F0;
    border-radius: 50px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    padding: 15px 30px;
    font-size: 18px;
}
a.claim_airdrop:hover{
    background: #5ED3F0;
    text-decoration: none;
}
.btnss {
    display: flex;
    column-gap: 15px;
}
.txt112 p {
    margin-top: 15px;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'urbanist-light';
}
.how_and_why22 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ds22 {
    display: flex;
    column-gap: 15px;
}
.work {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.social-col svg path {
    fill: #FFFFFF !important;
}
.social-col svg{
    font-size: 20px;
}
.social-col {
    display: flex;
    column-gap: 20px;
    height: 20px;
}
.social-col a:hover .social-col svg path{
    fill: #34A9FF !important;
}
.work p, .work a {
    font-size: 14px;
    font-family: 'urbanist-regular';
    line-height: 20px;
    color: #FFFFFF;
}
.work a:hover{
    text-decoration: none;
}
.work a:focus, .work a:focus-visible{
    text-decoration: none;
}
.col.time_contribute {
    width: 50% !important;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    column-gap: 50px;
}
.bonus_col p {
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'urbanist-regular';
    line-height: 20px;
}
p.contributors {
    font-size: 12px;
}
.bonus_col h1 {
    font-size: 30px !important;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    margin: 5px 0px !important;
}
.row.slider_countdown {
    margin-bottom: 70px;
}
.slider_countdown h1 {
    font-size: 20px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    margin-bottom: 13px;
}
.row.txxss {
    margin-bottom: 230px;
}
.row.slider_countdown {
    margin-bottom: 70px;
    display: flex;
    align-items: center;
}
.slider_countdown span.MuiSlider-rail {
    color: #E6E6E6 !important;
    opacity: 1 !important;
    border-radius: 0px;
    height: 5px;
}
.slider_countdown span.MuiSlider-track {
    background-image: linear-gradient(190deg, #3FAEFE 0%, #39D8FF 100%) !important;
    border: none;
    border-radius: 0px;
    height: 5px;
}
.slider_countdown span.MuiSlider-thumb {
    display: none;
}
.slider_countdown span.MuiSlider-mark {
    display: none;
}
.slider_countdown span.MuiSlider-markLabel {
    left: 96% !important;
    top: -36px;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'urbanist-medium';
    padding-bottom: 15px;
}
.slider_countdown span.MuiSlider-markLabel:before {
    content: 'Completed';
    font-size: 12px;
    font-family: 'urbanist-light';
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.slider_countdown .MuiSlider-root {
    margin-bottom: 0px !important;
}
.bonus_col {
    display: none;
}

@media screen and (max-width: 1240px){
    .row.txxss {
        margin-bottom: 150px;
    }
    .row.slider_countdown {
        margin-bottom: 50px;
    }
    a.token_signin, a.claim_airdrop{
        padding: 12px 20px;
        font-size: 16px;
    }
    .txt112 h1{
        width: 80%;
    }
}
@media screen and (max-width: 1080px){
    .row.txxss {
        margin-bottom: 100px;
    }
    .row.slider_countdown {
        margin-bottom: 30px;
    }
    .txt112 h1 {
        width: 80%;
        font-size: 22px;
        line-height: 40px;
    }
    a.token_signin, a.claim_airdrop{
        font-size: 14px;
    }
    .token_first_sect{
        padding: 140px 0px 50px 0px;
    }
    .work a svg {
        width: 30px;
    }
}
@media screen and (max-width: 800px){
    div#ncxtokenVieo {
        display: none;
    }
    .token_first_sect{
        position: relative;
        background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689419776/Token_bg_xoas9k.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 150px !important;
        background-position: right;
    }
}

.time {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new__timer .time p{
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
}

.new__timer .time span{
    font-size: 11px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
}
.new__timer {
    display: flex;
}
.colon {
    height: fit-content;
    font-size: 32px;
    color: #FFFFFF;
    padding: 0px 20px;
    line-height: 32px;
}
.custom__timer h1{
    font-size: 14px;
    font-family: 'urbanist-medium';
    margin-bottom: 5px;
}
.times__up #end{
    color: red;
    font-size: 26px;
    font-family: 'urbanist-regular';
    text-align: center;
    line-height: 32px;
}