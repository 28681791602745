div#ncx_token_Page {
    background: #000000;
    background-color: #000000;
}
#ncx_token_Page .dex-table {
    padding: 30px 0px 120px 0px;
    display: none;
}


/************************************ Token Header Styling ************************************** */
#ncx_token_Page header#header-row{
    background: transparent;
}
#ncx_token_Page .header-logo a img {
    width: 100% !important;
    max-width: 160px;
}
#ncx_token_Page .login-buttons a.signin{
    width: fit-content !important;
    /* min-width: 110px !important; */
    font-size: 12px !important;
    line-height: 20px !important;
    background: #5ED3F0 !important;
    border: 1px solid #5ED3F0 !important;
}
#ncx_token_Page .login-buttons a.signin:hover{
    background: #000000 !important;
    border: 1px solid #5ED3F0 !important;
}
#ncx_token_Page .login-buttons a.launch-dex{
    font-size: 12px !important;
    line-height: 20px !important;
    background: #000000 !important;
    border: 1px solid #5ED3F0 !important;
    width: fit-content !important;
    min-width: 75px !important;
}
#ncx_token_Page .login-buttons a.launch-dex:hover{
    background: #5ED3F0 !important;
    border: 1px solid #5ED3F0 !important;
}
#ncx_token_Page #main-header .logo-nav{
    column-gap: 15px !important;
}
#ncx_token_Page #main-header ul.navlink{
    column-gap: 13px !important;
}
#ncx_token_Page #main-header ul.navlink li:after{
    background-color: #5ED3F0 !important;
}
.lang-convet a {
    color: #000000 !important;
    font-size: 14px !important;
    font-family: 'urbanist-regular';
}
.lang-convet a:hover{
    color: #5ED3F0 !important;
}

@media screen and (min-width: 1400px){
    #ncx_token_Page #main-header ul.navlink a{
        font-size: inherit !important;
        font-family: inherit !important;
    }
}
@media screen and (min-width: 1450px){
    #ncx_token_Page #main-header ul.navlink a{
        font-size: 15px !important;
        font-family: 'urbanist-light' !important;
    }
    #ncx_token_Page #main-header ul.navlink{
        column-gap: 13px !important;
    }
}
@media screen and (max-width: 1380px){
    #ncx_token_Page .header-logo a img{
        width: 140px !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 26% !important;
    }
    #ncx_token_Page #main-header ul.navlink{
        column-gap: 15px !important;
    }
}
@media screen and (max-width: 1310px){
    #ncx_token_Page #main-header .logo-nav{
        column-gap: 15px !important;
        width: 72% !important;
    }
    #ncx_token_Page #main-header ul.navlink{
        column-gap: 15px !important;
    }
    #ncx_token_Page .login-buttons a.signin, #ncx_token_Page .login-buttons a.launch-dex{
        font-size: 13px !important;
    }
    #ncx_token_Page .header-logo a img{
        width: 130px !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 28% !important;
    }
}
@media screen and (min-width: 801px) and (max-width: 1240px){
    #ncx_token_Page #main-header .login-buttons a{
        font-size: 14px !important;
    }
    #ncx_token_Page #main-header #tran_lang h1{
        font-size: 14px !important;
    }
    #ncx_token_Page #main-header .logo-nav{
        width: 72% !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 28% !important;
    }
    #ncx_token_Page #main-header ul.navlink{
        width: fit-content !important;
        column-gap: 15px !important;
    }
    #ncx_token_Page #main-header ul.navlink a{
        font-size: 13px !important;
    }
    #ncx_token_Page #main-header .signin, #ncx_token_Page #main-header .launch-dex{
        width: fit-content !important;
        padding: 6px 5px !important;
    }
}
@media screen and (min-width: 801px) and (max-width: 1170px){
    #ncx_token_Page #tran_lang h1{min-width: fit-content !important;}
    #ncx_token_Page #main-header .logo-nav{
        width: 74% !important;
        column-gap: 14px !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 26% !important;
    }
    #ncx_token_Page #main-header ul.navlink a{
        font-size: 12px !important;
    }
    #ncx_token_Page #main-header .header-logo a img {
        width: 140px !important;
    }
    #ncx_token_Page #main-header .signin, #ncx_token_Page #main-header .launch-dex{
        padding: 6px 10px !important;
        min-width: inherit !important;
    }
    #ncx_token_Page #main-header .login-buttons a{
        font-size: 11px !important;
        height: 30px !important;

    }
    #ncx_token_Page #main-header .storeicons{
        column-gap: 12px;
        width: 60px;
        height: 30px;
    }
    #ncx_token_Page #main-header #tran_lang h1{
        font-size: 13px !important;
    }
    #ncx_token_Page #main-header div#tran_lang svg {
        width: 7px;
        padding-top: 0px;
    }
    #ncx_token_Page #main-header .downloadQRCode{
        display: none !important;
    }
    #ncx_token_Page #main-header .header-more svg{
        width: 14px !important;
        height: 14px !important;
    }
    #ncx_token_Page #header-row .MuiToolbar-regular{
        padding: 0px 15px !important
    }
    #ncx_token_Page #main-header ul.navlink{
        column-gap: 10px !important;
    }
}
@media screen and (max-width: 1040px){
    #ncx_token_Page #main-header .header-logo a img{
        width: 120px !important;
    }
}
@media screen and (min-width: 801px) and (max-width: 1010px){
    #ncx_token_Page #main-header .logo-nav{
        width: 76% !important;
        column-gap: 14px !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 24% !important;
    }
    #ncx_token_Page #main-header ul.navlink a{
        font-size: 12px !important;
    }
    /* #ncx_token_Page #main-header .header-logo a img {
        width: 80px !important;
    } */
    #ncx_token_Page #main-header .signin, #ncx_token_Page #main-header .launch-dex{
        padding: 6px 5px !important;
        height: 30px !important;
        font-size: 10px !important;
    }
    #ncx_token_Page #main-header .storeicons{
        height: 30px !important;
        width: fit-content !important;
        column-gap: 10px !important;
        padding: 0px 10px !important;
    }
    #ncx_token_Page #main-header .header-more svg {
        width: 13px !important;
        height: 13px !important;
    }
    #ncx_token_Page #main-header .storeicons svg {
        width: 12px;
    }
}
@media screen and (min-width: 801px) and (max-width: 1000px){
    #ncx_token_Page #main-header .logo-nav{
        width: 75% !important;
        column-gap: 14px !important;
    }
    #ncx_token_Page #main-header .login-buttons{
        width: 25% !important;
    }
    #ncx_token_Page #main-header ul.navlink a{
        font-size: 10px !important;
    }
    #ncx_token_Page #main-header .header-logo a img {
        width: 110px !important;
    }
    #ncx_token_Page #main-header .signin, #ncx_token_Page #main-header .launch-dex{
        padding: 6px 5px !important;
        height: 30px !important;
        font-size: 10px !important;
    }
    #ncx_token_Page #main-header .storeicons{
        display: none;
    }
    #ncx_token_Page #main-header .header-more svg {
        width: 13px !important;
        height: 13px !important;
    }
    #ncx_token_Page #main-header .storeicons svg {
        width: 12px;
    }
}

/************************************ Token Footer Styling ************************************** */
#ncx_token_Page .footer-row ul li a:after{
    background-color: #5ED3F0;
}
#ncx_token_Page .fot-newsleter form button{
    background: #5ED3F0 !important;
    border-color: #5ED3F0 !important;
}
#ncx_token_Page .social-icons .icons a:hover{
    background: #5ED3F0 !important;
}

/************************************ Token Countdown Styling ************************************** */
div#token_countdown .show-counter {
    display: flex;
}
.expired-notice > span {
    font-size: 30px;
    font-family: 'urbanist-medium';
    color: red;
    padding-left: 20px;
}
.expired-notice > p {
    font-size: 14px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    padding-left: 20px;
}
/* .show-counter {
    padding: 40px;
} */
.show-counter .countdown {
    line-height: 1.25rem;
    padding: 0px 20px 0px 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.countdown.danger p {
    color: #ff0000 !important;
}
div#token_countdown .show-counter p{
    font-size: 30px;
    color: #FFFFFF;
}
.show-counter .countdown > p {
    margin: 0;
    font-size: 30px;
    line-height: 44px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
}
.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 16px;
    font-family: 'urbanist-regular';
    text-transform: capitalize;
    color: #FFFFFF;
}
#token_countdown h1{
    font-size: 14px;
    font-family: 'urbanist-medium';
    line-height: 20px;
    color: #FFFFFF;
    padding-left: 20px;
    margin-bottom: 0px;
}


/************************************ Token Generation Video Styling ************************************** */
div#token_video {
    background: #000000 !important;
    padding: 100px 0px 150px 0px ;
}
div#token_video .generate-div h1{
    color: #FFFFFF !important;
}
div#token_video .generate-div p, div#token_video .taskdiv h3{
    color: #FFFFFF !important;
}
div#token_video .text212 h4{
    color: #FFFFFF !important;
    font-size: 17px !important;
    width: 103% !important;
}
div#token_video .taskdiv {
    background: #151515 !important;
}
div#token_video .buttoninfo a{
    background: #5ED3F0 !important;
}
div#token_video .buttoninfo a:hover{
    background: #5ED3F0 !important;
    color: #FFFFFF !important;
}
div#token_video .taskdiv:hover{
    box-shadow: 0 0 40px 5px rgb(255 255 255 / 25%) !important;
}
div#token_video .taskdiv{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
div#token_video .taskdiv.two{
    gap: 36px !important;
}


@media screen and (max-width: 800px){
    /* .token_first_sect{
        padding: 200px 0px 50px 0px !important;
        background-position: right !important;
    } */
    .txxss .col:nth-child(2) {
        display: none;
    }
    .txt112{
        padding: 0px !important;
    }
    .txt112 h1{
        width: 100% !important;
        font-size: 23px !important;
        line-height: 30px !important;
        text-align: center !important;
    }
    a.claim_airdrop, a.token_signin{
        font-size: 14px !important;
        padding: 10px 20px !important;
    }
    .btnss{
        justify-content: center !important;
    }
    .txt112 p{
        font-size: 13px !important;
        text-align: center !important;
    }
    .slider_countdown .MuiBox-root {
        width: 100% !important;
    }
    .slider_countdown .col {
        padding: 0px !important;
        width: 100% !important;
        flex: inherit !important;
    }
    .slider_countdown {
        margin-top: 60px !important;
    }
    div#token_countdown {
        width: 100% !important;
    }
    #token_countdown .show-counter{
        justify-content: center !important;
    }
    .slider_countdown .col.time_contribute{
        width: 100% !important;
        margin-top: 20px;
        margin-bottom: 30px;
        justify-content: center !important;
    }
    .work a svg {
        width: 20px !important;
        height: auto;
    }
    .work p {
        font-size: 10px !important;
    }
    .social-col{
        column-gap: 10px !important;
    }
    .single_map p span {
        display: unset !important;
        padding-left: 5px;
    }
    

    /* Mobile NCXT Stake styling */
    #stake_ncxT .stake_ncx h1{
        font-size: 22px;
        width: 60%;
        margin-bottom: 40px !important;
    }
    #stake_ncxT .mbl-tokrowitems p{
        color: #FFFFFF !important;
        font-size: 11px !important;
    }
    .mbl-tokrow .color.height_q {
        width: 21.8%;
    }
    .mbl-tokrow .color.inactive {
        background: rgba(31, 31, 31, 0.6) !important;
        width: 10.5% !important;
    }
    .color.height_q.black {
        width: 43.9%;
    }
    /* Mobile NCXT Presale Styling */
    #presale_ncxT .mbl-tokrowitems p{
        color: #FFFFFF !important;
        font-size: 8px !important;
    }
    #presale_ncxT .color.mbl-hid {
        visibility: hidden;
    }


    /* Mobile NCXT Video Generation */
    div#token_video{
        padding: 50px 0px 50px 0px !important;
    }
    div#token_video .text212 h4{
        font-size: 14px !important;
    }
    div#token_video .generate-div p{
        font-size: 14px !important;
        font-family: 'urbanist-light' !important;
    }
    div#token_video .taskdiv {
        min-height: 200px !important;
        width: 49% !important;
    }
    #ncx_token_Page .feat-txt {
        width: 100%;
    }
    div#token_video .text212 p{
        font-size: 10px !important;
        font-family: 12px !important;
    }
    .roadMap_slider{
        padding: 50px 0px !important;
    }
}