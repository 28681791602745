#ncx_market_table tr, .table_search_tabs {
    border-bottom: 1px solid hsla(0,0%,100%,.1)!important;
}
#table_divddd thead tr {
    padding: 18px 0px;
}
#ncx_market_table tr td h1 {
    color: #fff!important;
    font-family: 'urbanist-medium';
    font-size: 14px!important;
    font-weight: 500;
    text-transform: uppercase;
}
tbody#ncx_market_table tr td:nth-child(6) img {
    max-width: 140px;
    width: 100%;
}
#table_divddd thead th:nth-child(1), tbody#ncx_market_table tr td:nth-child(1){
    width: 20%;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: unset;
}
#table_divddd thead th:nth-child(2), tbody#ncx_market_table tr td:nth-child(2){
    width: 12.5%;
}
#table_divddd thead th:nth-child(3), tbody#ncx_market_table tr td:nth-child(3){
    width: 12.5%;
}
#table_divddd thead th:nth-child(4), tbody#ncx_market_table tr td:nth-child(4){
    width: 12.5%;
}
#table_divddd thead th:nth-child(5), tbody#ncx_market_table tr td:nth-child(5){
    width: 12.5%;
}
#table_divddd thead th:nth-child(6), tbody#ncx_market_table tr td:nth-child(6){
    width: 15%;
    text-align: center;
}
#table_divddd thead th:nth-child(7), tbody#ncx_market_table tr td:nth-child(7){
    width: 15%;
    text-align: end;
}
#ncx_market_table tr:hover{
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
@media screen and (max-width: 800px){
    #ncx_market_table tr td h1{
        font-size: 12px !important;
    }
    #ncx_market_table tr td:nth-child(1) {
        padding-right: 15px;
        gap: 5px !important;
    }
    #ncx_market_table tr td:nth-child(3) {
        text-align: center;
    }
    #ncx_market_table tr td:nth-child(3) {
        text-align: center;
    }
    #ncx_market_table tr:hover {
        margin-left: -15px;
        margin-right: -15px;
        padding: 0px 10px !important;
    }
}