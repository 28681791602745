div#pagenot_found {
    height: 100vh;
    text-align: center;
}
.contfound img {
    width: 100%;
    max-width: 400px;
}
.contfound {
    height: 100%;
    padding: 100px 0px !important;
}
.contfound h1 {
    font-size: 38px;
    font-family: 'urbanist-regular';
    margin-bottom: 10px;
}
.contfound p{
    font-size: 22px;
    line-height: 26px;
    font-family: 'urbanist-regular';
}
.contfound a{
    font-size: 16px;
    line-height: 19px;
    font-family: 'urbanist-medium';
    color: #5ed3f0;
    padding: 15px 20px;
    border: 1px solid #5ed3f0;
    background: #FFFFFF;
    text-decoration: none;
    border-radius: 10px;
    position: relative;
    display: block;
    max-width: 170px;
    margin: 0 auto;
    margin-top: 20px;
}
.contfound a:hover{
    background: #5ed3f0;
    color: #FFFFFF;
}

@media screen and (max-width: 800px){
    .contfound img{
        max-width: 200px;
    }
    .contfound h1{
        font-size: 26px;
        line-height: 32px;
        font-family: 'urbanist-semibold';
    }
    .contfound p{
        font-size: 14px;
        line-height: 20px;
        font-family: 'urbanist-regular';
    }
}