.feature__dx {
    padding: 100px 0px 100px 0px;
    position: relative;
    margin-top: 2px;
}
.lkj {
    display: flex;
    justify-content: center;
    gap: 5px;
    /* border-bottom: 1px solid rgba(255,255,255,0.25); */
}
.uiop {
    width: 15%;
    padding: 11.5px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    background: rgba(31, 31, 31, 0.6);
}
.lkj .uiop:nth-child(1) {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25% !important;
}
.uiop.clr {
    background: #1A1A1A;
}
.lkj .uiop h3 {
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'urbanist-medium';
}
.lkj.head.container {
    border-bottom: 0.5px solid rgba(255,255,255,0.5) !important;
    margin-bottom: 10px;
}
a.open_acnt {
    font-size: 14px;
    font-family: 'urbanist-regular';
    text-transform: initial;
    letter-spacing: 0;
    color: #FFFFFF;
    background: transparent;
    padding: 7px 10px;
    width: fit-content;
    line-height: 17px;
    border-radius: 5px;
    border: 0.5px solid #FFFFFF;
}
a.open_acnt:hover, a.open_acnt:focus, a.open_acnt:focus-visible{
    outline: none;
    text-decoration: none;
    box-shadow: none;
}
a.open_acnt:hover{
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #FFFFFF;
}
.lkj.head{
    border: none !important;
}
.sticky_pos{
    position: sticky;
    top: -10px;
    z-index: 999;
    background: #000000;
}
.lkj.head .uiop {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    padding: 30px 0px 20px 0px;
}
.bynt p {
    font-size: 16px !important;
    line-height: 20px !important;
}
.bynt span {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 12px;
    line-height: 14px;
}
p.nhgt {
    color: rgba(255,255,255,0.8) !important;
    font-size: 16px !important;
    font-family: 'urbanist-regular' !important;
    display: flex;
    flex-direction: column;
    text-align: center;
}
p.nhgt span{
    font-size: 12px;
}
.uiop p {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 16px;
}
.bf__pok {
    margin-bottom: 60px;
    text-align: center;
}
.bf__pok h1 {
    font-size: 40px;
    line-height: 50px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    text-align: center;
}
.bf__pok p {
    color: #FFFFFF;
    font-size: 14px;
}
#ngbv.lkj.head .uiop {
    padding: 10px 0px;
}
.lkj:last-child{
    border: none !important;
}
.lkj.head.container .uiop {
    background: transparent !important;
    margin-bottom: 0px !important;
}
#ngbv.lkj.head .uiop:first-child {
    background: transparent;
}
.bf__pok button {
    width: 167px;
    height: 44px;
    background: #000000;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    font-size: 16px;
    font-family: 'urbanist-regular';
    margin-bottom: 0px !important;
}
.bf__pok button:hover{
    background: #FFFFFF;
    color: #000000;
}
.bf__pok button:focus, .bf__pok button:focus-visible, .bf__pok button:active{
    outline: none !important;
    background: transparent !important;
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5) !important;
}
.feature__dd{
    font-size: 32px;
    line-height: 36px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
}

@media screen and (min-width: 800px){
    #mb__feat{
        display: none;
    }
}
@media screen and (max-width: 800px){
    #mb__feat{
        display: block;
        padding: 0px 0px 0px 0px;
    }
    .bf__pok.container {
        display: none;
    }
    #mb__feat h1 {
        color: #FFFFFF;
        font-size: 24px;
        font-family: 'urbanist-regular';
        text-align: center;
        margin-bottom: 20px;
    }
    .m33C__v {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .m33C__v h2 {
        font-size: 16px;
        color: #FFFFFF;
        font-family: 'urbanist-medium';
        border-bottom: 1px solid #FFFFFF;
        padding-bottom: 10px !important;
        text-align: center;
    }
    .m33C__v h2:nth-child(1), .rtt__rr{
        width: 33%;
        padding: 0px;
    }
    .m33C__v h2:nth-child(2), .ww__ncx{
        width: 21%;
        padding: 0px;
    }
    .m33C__v h2:nth-child(3), .ww__cex{
        width: 21%;
        padding: 0px;
    }
    .m33C__v h2:nth-child(4), .ww__dex{
        width: 21%;
        padding: 0px;
    }
    #mb__feat .row {
        display: flex;
        justify-content: space-between;
    }
    .rtt__rr h3, .ww__cex h3, .ww__dex h3 {
        background: rgba(31, 31, 31, 0.7);
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        padding: 10px 5px;
        text-align: center;
        margin-bottom: 5px;
        line-height: 16px;
        flex-direction: column;
    }
    .rtt__rr h3 span {
        font-size: 8px;
        line-height: 12px;
        font-family: 'urbanist-light';
    }
    .ww__ncx h3{
        background: #1F1F1F;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        padding: 10px 10px;
        text-align: center;
        margin-bottom: 5px;
        line-height: 16px;
    }
    .ww__cc {
        background: rgba(31, 31, 31, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin-bottom: 5px;
    }
    .ww__ncx .ww__cc {
        background: #1f1f1f;
    }
    .ww__xx{
        background: rgba(31, 31, 31, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin-bottom: 5px;
    }
    .hh__qa {
        min-height: 52px;
    }
    .hh__qa.mlkj{min-height: 60px;}
    .mki__bh{
        display: none;
    }
}