/* .blog-wrap {
    max-width: 1300px;
    margin: 0 auto;
  }
   */
  .blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
  }
  .blog-wrap header {
    text-align: center;
  }
  
  .blog-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
  }
  
  .blog-wrap img {
    width: 100%;
    max-height: 800px;
    object-fit: cover;
  }
  .blog-content img {
    padding: 10px 0px;
  }
  /* .blog-desc {
    padding: 1rem;
    margin-top: 1.5rem;
  } */

  /* s\******************* */
  .blog_detailspage{
    padding: 50px 0px 90px 0px;
  }
  .blog_detailspage .container .row .col{
    flex: inherit;
  }
  .blog_detailspage .col.first {
    position: relative;
    width: 20%;
    padding-left: 0px;
    padding-right: 40px;
  }
  .blog_detailspage .col.second {
    width: 60%;
    position: relative;
    padding: 0px 40px;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
  }
  .blog_detailspage .col.third {
    width: 20% !important;
    position: relative;
    padding-left: 40px;
    padding-right: 0px;
  }
  .blog_detailspage .container .row {
    margin: 0px !important;
    width: 100% !important;
}
.blog_detailspage .container {
  padding: 30px 0px;
}
.leftsidebar svg path:nth-child(2) {
  fill: #000000 !important;
}
.trend {
  display: flex;
  align-items: center;
}
.trend svg {
  width: 110px;
} 
.blog-search .blog_heading .trend svg {
  width: 100%;
  min-width: 140px;
  height: auto;
}
.blog-search .blog_heading .trend h3{
  font-size: 36px;
}
.trend h3 {
  font-size: 30px;
  font-family: 'urbanist-regular';
  padding-left: 10px;
  border-left: 1px solid #000000;
  margin-left: 10px;
}
.leftsidebar ul {
  padding: 0px;
  margin: 0px;
  margin-top: 40px;
}
.leftsidebar ul li{
  list-style-type: none;
  padding: 8px 0px;
}
.leftsidebar ul li a{
  font-family: 'urbanist-regular';
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.blog_detailspage .searchBar-wrap {
  margin-top: 10px;
}
.leftsidebar h1 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: 'urbanist-regular';
}
p.left-text {
  font-family: 'urbanist-regular';
  font-size: 16px;
  line-height: 24px;
}
.leftsidebar button.btn01.signup {
  width: 80% !important;
  margin-top: 30px;
  height: 40px !important;
  background: #34A9FF !important;
  border-color: #34A9FF !important;
  color: #FFFFFF !important;
}
h1.blog-title {
  font-size: 48px;
  font-family: 'urbanist-regular';
  line-height: 68px;
  margin-bottom: 20px;
}
p.blog-date {
  color: #000;
  font-size: 14px;
  font-family: 'urbanist-regular';
  padding-top: 0px;
  margin-bottom: 15px;
}
.blog-subCategory {
  display: flex;
  justify-content: left;
  margin-top: 10px;
  margin-bottom: 20px;
}
.blog-subCategory p.chip {
  font-size: 12px;
  line-height: 18px;
  padding: 6px 10px;
  margin-right: 10px;
  border-radius: 8px;
}
.blog-content p {
  font-size: 15px;
  line-height: 24px;
  font-family: 'hauora-regular';
  margin-bottom: 12px;
  text-align: justify;
}
.blog-content p a, .blog-content ul li a {
  color: #2460FF;
  font-size: 15px;
  line-height: 24px;
  font-family: 'hauora-regular';
}
.blog-content h3{
  font-family: 'urbanist-medium';
  font-size: 20px;
  line-height: 24px;
  margin: 10px 0px;
}
.blog-content ul {
  margin-top: 15px;
}
.blog-content ul li{
  font-size: 15px;
  font-family: 'hauora-regular';
  line-height: 24px;
}
.trend svg path:nth-child(2) {
  fill: #000000;
}
.author-details img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.author-details h1 {
  font-size: 15px;
  font-family: 'urbanist-regular';
  color: #000000;
  margin-top: 10px;
}
.author-details {
  display: flex;
  align-items: center;
  column-gap: 30px;
  border: 1px solid #EEEEEE;
  padding: 20px 30px;
  border-radius: 5px;
  margin-top: 60px;
}
.author-details p {
  font-size: 15px;
  font-family: 'hauora-regular' !important;
  width: 80%;
  line-height: 24px;
}
.profile {
  width: fit-content;
}
.social-links a svg path{
  fill: #FFFFFF;
}
.social-links a{
  width: 44px;
  height: 44px;
  background: rgba(0,0,0,0.2);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.social-links a:hover{
  background: #34A9FF !important;
}
.social-links {
  display: flex;
  margin-top: 40px;
  column-gap: 10px;
}
.rightsidebar h1 {
  font-size: 22px;
  line-height: 26px;
  font-family: 'urbanist-regular';
}
.rightsidebar section#slider-blog{
  width: 100% !important;
  padding: 15px !important;
  height: fit-content !important;
  margin-top: 20px;
  padding-top: 50px !important;
}
.rightsidebar h1.latest-post {
  display: none;
}
.rightsidebar #slider-blog h1 {
  font-size: 16px;
  line-height: 24px;
  padding: 0px 0px 10px 0px;
  min-height: 120px;
  color: #FFFFFF;
}
.rightsidebar #slider-blog p {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'urbanist-regular';
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  min-height: 63px;
  max-height: 63px;
}
.rightsidebar #slider-blog a.blogItem-link{
  margin-top: 40px;
}
.rightsidebar #slider-blog .moving-btm {
  width: 100%;
  margin: 0 auto;
  margin-right: 0px;
  justify-content: space-between;
  padding: 0px;
  position: relative;
  float: inherit !important;
  left: unset !important;
  display: flex !important;
  margin-top: 0px;
  top: unset !important;
  bottom: 330px;
}
.rightsidebar .blog-desc{
  padding: 0px !important;
  margin: 0px !important
}

/* Blog Page Styling starts from here */
.blog-header {
  /* background: url(../../images/kick-scaled.jpg); */
  background: #000000;
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
  padding: 70px 0px;
  /* min-height: 750px; */
  background-color: #000000;
}
.blog-page .blog-header .row-col:nth-child(1) {
  padding: 100px 0px;
}
.blog-header p {
  font-size: 14px;
  line-height: 24px;
  padding: 4px 0px;
  /* border-left: 1px solid #fff; */
  color: #FFFFFF;
  font-family: 'urbanist-regular';
  margin: 20px 0px;
}
.blog-header h1 {
  color: #FFFFFF;
  font-family: 'urbanist-medium';
  font-size: 48px;
  line-height: 58px;
}
.blog-header input[type="email"] {
  width: 60%;
  background: transparent;
  border: none;
  font-size: 14px;
  line-height: 20px !important;
  color: #fff;
  font-family: 'Hauora-regular';
  padding: 16px 3px;
  position: relative;
  z-index: 2;
  border-bottom: 0.5px solid #fff;
  caret-color: #59f7a4;
}
.blog-header input:focus{
  outline: none;
}
::placeholder{
  color: #FFFFFF;
  font-family: 'Hauora-regular';
  font-size: 14px;
}
.blog-header button {
  width: 165px;
  max-width: 40%;
  padding: 0;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 38px;
  background-position: 80% center;
  text-align: left;
  /* padding-left: 40px; */
  background: #34a9ff;
  border: 1px solid #34A9FF ;
  border-radius: 5px !important;
  position: relative;
  top: 8px;
  margin-left: 30px;
  font-family: 'urbanist-regular';
  text-align: center;
  box-shadow: none !important;
  outline: none !important;
}
.blog-header .row {
  margin: 0px;
}
.blog-header .row .row-col {
  width: 50%;
}
#insight_page section#slider-blog {
  width: 100%;
  margin-top: 30px;
  height: inherit !important;
}
#insight_page section#slider-blog h1.latest-post {
  display: none;
}
#insight_page section#slider-blog h1 {
  margin: 0px !important;
  color: #FFFFFF !important;
  font-size: 16px !important;
  padding: 5px 0px 0px 0px;
  line-height: 24px;
}
#insight_page section#slider-blog p {
  color: #FFFFFF !important;
  font-size: 16px;
  line-height: 24px !important;
  min-height: 140px;
  max-height: 140px !important;
}
#insight_page section#slider-blog p a{
  color: #FFFFFF !important;
  text-decoration: underline;
}
#insight_page section#slider-blog a.blogItem-link {
  margin: 0 auto !important;
  margin-right: 0px !important;
  margin-top: 20px !important;
}
.searching_btns{
  display: grid;
}
.searching_btns .btn-dark:nth-child(7){
  order: 1;
}
.searching_btns .btn-dark:nth-child(6){
  order: 2;
}
.searching_btns .btn-dark:nth-child(2){
  order: 3;
}
.searching_btns .btn-dark:nth-child(5){
  order: 4;
}
.searching_btns .btn-dark:nth-child(4){
  order: 5;
}
.searching_btns .btn-dark:nth-child(3){
  order: 6;
}
.searching_btns .btn-dark:nth-child(1){
  order: 7;
}
.searching_btns button.btn-dark {
  width: 100%;
  text-align: left;
  background: none;
  color: #000000;
  border: none;
  font-size: 16px;
  font-family: 'urbanist-regular';
  padding: 10px 0px;
  width: fit-content;
}
.searching_btns button.btn-dark:hover {
  background: none;
  border: none;
  color: #34A9FF;
  font-size: 16px;
  font-family: 'urbanist-regular';
  padding: 10px 0px;
  width: fit-content;
}
.searching_btns button.btn-dark:focus, .searching_btns button.btn-dark:focus-visible{
  border: none !important;
  outline: none;
  box-shadow: none;
  color: #34A9FF;
}
#fixed_sticky_insights.blog-stick {
  position: sticky;
  top: 100px;
}
#insight_detail .sticky-bar, .leftsidebar.blog-stick, .rightsidebar.blog-stick, .support_leftbar.blog-stick,
.support_articles.blog-stick {
  position: sticky;
  top: 100px;
}
.insight-signup{
  background: #34A9FF !important;
    border: none !important;
    color: #FFFFFF !important;
    height: 44px !important;
    width: 170px !important;
    font-size: 16px !important;
    font-family: 'urbanist-medium' !important;
    line-height: 24px !important;
    border-radius: 8px !important;
    margin-top: 20px !important;
}
.insight-signup:hover, .insight-signup:focus, .insight-signup:focus-visible{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
}