.new__homE_rewar .container {max-width: 1150px;}
.new__homE_rewar{padding: 100px 10px; position: relative; margin-top: -1px;}
.sdss__cc.row {margin: 0px !important;}
.sdss__cc.row .firsttv.col{flex: inherit; width: 40%; padding: 0px !important; display: flex; align-items: center; flex-direction: row;}
.sdss__cc.row .secondff.col{flex: inherit; width: 60%; padding: 0px !important;}
.firsttv .rr {padding-right: 100px;}
.firsttv .rr h2 {font-size: 32px; font-family: 'urbanist-regular'; line-height: 40px; color: #FFFFFF; margin-bottom: 15px;}
.firsttv .rr p {font-size: 16px; font-family: 'urbanist-light'; line-height: 24px; color: #FFFFFF; margin-bottom: 15px;}
.ddf.sect {display: flex; gap: 20px; width: 100%;}
.dfss_m.claim {width: 50%; padding: 30px 30px 40px 30px; background: #151515 !important; transform: translateY(0%);
    transition: .4s ease; display: flex; flex-direction: column; justify-content: space-between; gap: 50px;}
.dfss_m.claim:hover {box-shadow: 0 0 16px rgb(255 255 255 / 30%);}
.dfss_m.claim h3 {color: #FFFFFF; font-size: 48px; font-family: 'urbanist-medium';}
.dfss_m.claim h3 span{font-size: 20px;padding-left: 5px;}
.gnnll__l {margin-bottom: 15px;}
.gnnll__l h4 {font-size: 19px; font-family: 'urbanist-medium'; margin-bottom: 5px; color: #FFFFFF;}
.gnnll__l p {font-size: 14px; line-height: 20px; font-family: 'urbanist-light'; color: #FFFFFF; padding-right: 10px;;}
.btn__ncx {display: flex; align-items: end; justify-content: space-between;}
.btn__ncx a, .firsttv .rr a{
    background: transparent; color: #FFFFFF; box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5); border: 1px solid #FFFFFF;
    border-radius: 50px; width: 140px; height: 44px; font-size: 16px; font-family: 'urbanist-regular'; text-transform: none; min-width: fit-content;}
.btn__ncx a:hover, .firsttv .rr a:hover{ background: #FFFFFF;color: #000000;}
.new__homE_rewar:before {
    content: ""; display: block; height: 0.5px; margin: 0 auto; position: absolute; top: 0; 
    width: 48%; background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0)); left: 2.1%; }
.new__homE_rewar:after {
    content: ""; display: block; height: 0.5px; margin: 0 auto; position: absolute; top: 0;
    width: 48%; background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0)); right: 2.1%;}

@media screen and (max-width: 1150px){
    .firsttv .rr h2{font-size: 28px; line-height: 38px;}
    .gnnll__l h4 {font-size: 16px; line-height: 22px; margin-bottom: 10px;}
    .gnnll__l p{padding: 0px;}
    .firsttv .rr p{font-size: 14px; line-height: 18px;}
    .dfss_m.claim h3{font-size: 42px;}
    .dfss_m.claim{gap: 30px;}
    .firsttv .rr {padding-right: 60px;}
    .rf__aab.row .col{flex: 0 0 23% !important;}
}
@media screen and (max-width: 955px){
    .firsttv .rr h2{font-size: 24px; line-height: 32px;}
    .gnnll__l h4 {font-size: 14px; line-height: 20px; margin-bottom: 5px;}
    .gnnll__l p{padding: 0px; font-size: 12px; line-height: 18px;}
    .firsttv .rr p{font-size: 14px; line-height: 18px;}
    .dfss_m.claim h3{font-size: 32px;}
    .dfss_m.claim{gap: 30px; padding: 25px 25px 30px 25px;}
    .btn__ncx a, .firsttv .rr a{font-size: 14px; width: 130px;}
    .firsttv .rr {padding-right: 30px;}
    .rf__aab.row .col{flex: 0 0 47% !important;}
}
@media screen and (max-width: 800px){
    .new__homE_rewar{padding: 50px 0px;}
    .sdss__cc.row .firsttv.col, .sdss__cc.row .secondff.col{width: 100% !important;}
    .sdss__cc.row .secondff.col{padding-top: 20px !important;}
    .firsttv .rr{padding: 0px !important;}
    .firsttv .rr h2{margin-bottom: 5px !important;}
    .firsttv .rr p{font-size: 16px !important; line-height: 22px !important;}
    .btn__ncx a, .firsttv .rr a {font-size: 12px; width: 100px; height: 34px; font-family: 'urbanist-regular' !important;}
    .dfss_m.claim{padding: 15px 15px 15px 15px; }
    .dfss_m.claim h3{font-size: 28px;}
    .dfss_m.claim h3 span{font-size: 12px;}
    .gnnll__l h4{font-size: 13px !important; line-height: 18px !important;}
    .gnnll__l p{font-size: 10px !important; line-height: 14px !important;}
    .ddf.sect{gap: 10px;}
    .firsttv .rr a{display: none;}
}