table#table_divddd {
    width: 100% !important;
    position: relative;
    display: block;
    background: #FFFFFF;
    padding: 20px 30px;
}
section.market_pag {
    padding: 0px 0px 50px 0px;
    background: #f5f5f5;
}
#table_divddd thead {
    width: 100%;
    display: block;
}
#table_divddd body {
    width: 100%;
    display: block;
}
#table_divddd th {
    font-size: 14px;
    font-family: 'urbanist-medium';
    color: #85899C;
    font-weight: 500;
}
#table_divddd thead tr{
    border: none !important;
}
#ncx_market_table tr{
    padding: 2px 0px !important;
    border-bottom: 1px solid #f5f5f5 !important;
    min-height: 60px;
    position: relative;
}
/* #table_divddd thead th:nth-child(1), tbody#ncx_market_table tr td:nth-child(1){
    width: 20%;
}
#table_divddd thead th:nth-child(2), tbody#ncx_market_table tr td:nth-child(2) {
    width: 12.5%;
}
#table_divddd thead th:nth-child(3), tbody#ncx_market_table tr td:nth-child(3) {
    width: 12.5%;
}
#table_divddd thead th:nth-child(4), tbody#ncx_market_table tr td:nth-child(4) {
    width: 12.5%;
}
#table_divddd thead th:nth-child(5), tbody#ncx_market_table tr td:nth-child(5) {
    width: 12.5%;
}
#table_divddd thead th:nth-child(6), tbody#ncx_market_table tr td:nth-child(6) {
    width: 15%;
    text-align: center;
}
#table_divddd thead th:nth-child(7), tbody#ncx_market_table tr td:nth-child(7) {
    width: 15%;
    text-align: end;
} */
.star-svg svg {
    width: 17px;
    height: auto;
}
button.star-svg {
    padding: 0px;
    border: none;
    background: none;
}
#ncx_market_table{
    width: 100%;
    display: block;
    position: relative;
}
#ncx_market_table td.pair_name {
    display: flex;
    align-items: center;
    gap: 10px;
}
.pair_name h2 {
    font-size: 14px;
    font-family: 'urbanist-medium';
    text-transform: uppercase;
    font-weight: 500;
    color: #1B1E28 !important;
}
tbody#ncx_market_table tr td h2 {
    font-size: 14px !important;
    font-family: 'urbanist-medium';
    font-weight: 500;
    color: #1B1E28;
}
tbody#ncx_market_table tr td p{
    font-size: 12px !important;
    font-family: 'urbanist-medium';
    font-weight: 500;
    color: #85889C;
}
#ncx_market_table .price_trend_graph img {
    width: 100%;
    max-width: 140px;
}
tbody#ncx_market_table tr td h2.negative, .trend_pairs p.negative {
    color: #E65261 !important;
}
tbody#ncx_market_table tr td h2.positive, .trend_pairs p.positive {
    color: #17BE9E !important;
}
tbody#ncx_market_table tr:last-child{
    border: none !important;
}
tbody#ncx_market_table tr:hover .star-svg svg path{
    fill: #7650FF;
}
.table_search_tabs {
    display: flex;
    background: #FFFFFF;
    justify-content: space-between;
    margin: 0px 30px;
    padding: 20px 0px;
    align-items: center;
    border-bottom: 1px solid #f5f5f5 !important;
}
.market_pag .container {
    background: #FFFFFF;
}
.table_search_tabs .col:nth-child(1) {
    width: 50%;
    flex: inherit !important;
    column-gap: 10px;
    display: flex;
}
.table_search_tabs button{
    background: rgba(133, 136, 156, 0.1);
    color: rgba(133, 136, 156, 1);
    font-size: 14px;
    font-family: 'urbanist-medium';
    border-radius: 2px;
    width: 92px;
    height: 27px;
    border: none;
}
.active_all, .active_usdc, .active_usdt, .active_usd, .active_btc{
    background: #7650FF !important;
    color: #FFFFFF !important;
}
.table_search_tabs .searchinput {
    width: fit-content;
    margin: 0 auto;
    margin-right: 0px;
    display: flex;
    position: relative;
}
.table_search_tabs .searchinput input {
    border: 1px solid #DADBE1 !important;
    border-radius: 50px;
    width: 250px;
    height: 32px;
    color: #85889C;
    padding: 10px 20px 10px 40px;
    font-size: 12px;
    font-family: 'urbanist-medium';
}
.table_search_tabs .searchinput input::placeholder{
    color: #85889C;
    font-size: 12px;
    font-family: 'urbanist-medium';
}
.table_search_tabs .searchinput input:active, .table_search_tabs .searchinput input:hover, 
.table_search_tabs .searchinput input:focus, .table_search_tabs .searchinput input:focus-visible{
    border: 1px solid #DADBE1 !important;
    outline: none;
}
.searchinput svg {
    position: absolute;
    top: 8px;
    left: 15px;
}
.spiner_div {
    text-align: center;
}
.spiner_div div#loading{
    margin: 50px !important;
}

#featured_slider, #featured_slider_mobile{
    position: relative;
    background: #FFFFFF;
}
#featured_slider:after, #featured_slider_mobile:after{
    content: '';
    width: 10px;
    height: 118px;
    background: #FFFFFF;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}
#featured_slider:before, #featured_slider_mobile:before{
    content: '';
    width: 10px;
    height: 118px;
    background: #FFFFFF;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}
#featured_slider .slider_market .slide, #featured_slider_mobile .slider_market .slide{
    border-right: 10px solid #f5f5f5;
}
#featured_slider .sliderTray___-vHFQ.sliderAnimation___300FY.carousel__slider-tray.carousel__slider-tray--horizontal,
#featured_slider_mobile .sliderTray___-vHFQ.sliderAnimation___300FY.carousel__slider-tray.carousel__slider-tray--horizontal {
    width: 100%;
    min-height: 118px;
}
#featured_slider .slider_market, #featured_slider_mobile .slider_market {
    display: flex !important;
    width: 100%;
    height: 118px !important;
}
.trending_markets {
    background: #f5f5f5;
    padding: 50px 0px 10px 0px;
}
.featured_market {
    background: #FFFFFF !important;
}
p.fet_tag {
    width: fit-content;
    background: #7650FF;
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'urbanist-medium';
    padding: 1px 10px;
    border-radius: 0px 0px 10px 0px;
}
.trend_pairs h1 {
    font-size: 16px;
    color: #1C1D28;
    font-family: 'urbanist-medium';
    text-transform: uppercase;
}
.trend_pairs {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
}
.trend_pairs p{
    font-size: 14px;
    font-family: 'urbanist-medium';
} 
.price_graph {
    display: flex;
    padding: 0px 20px 10px 20px;
}
.price_graph .prix {
    width: 50% !important;
}
.prix h3 {
    font-size: 20px;
    font-family: 'urbanist-medium';
    text-align: left;
    margin-bottom: 10px;
}
.prix p {
    font-size: 12px;
    font-family: 'urbanist-medium';
    color: #85889C;
    text-align: left;
}
.price_graph img {
    width: 50% !important;
    max-width: 110px;
    position: relative;
    left: 10px;
    display: none;
}
.trending_markets .spiner_div {
    position: relative;
    top: 70px;
    z-index: 1;
}

.trending_markets .container.mobile {
    display: none;
}
@media screen and (max-width: 800px){
    .table_search_tabs {
        width: 100% !important;
        margin: 0px !important;
        display: block !important;
    }
    .table_search_tabs .col{
        width: 100% !important;
    }
    .table_search_tabs button{
        font-size: 10px !important;
        width: fit-content !important;
    }
    .table_search_tabs .col:nth-child(1){
        column-gap: 5px !important;
    }
    .table_search_tabs .col:nth-child(2) {
        margin-top: 15px;
    }
    table#table_divddd {
        padding: 0px;
    }
    #table_divddd thead tr th, #ncx_market_table tr td{
        width: 25% !important;
        font-size: 12px !important;
    }
    #table_divddd thead tr th:nth-child(4), #table_divddd thead tr th:nth-child(5), #table_divddd thead tr th:nth-child(7){
        display: none;
    }
    #ncx_market_table tr td:nth-child(4), #ncx_market_table tr td:nth-child(5), #ncx_market_table tr td:nth-child(7){
        display: none;
    }
    .star-svg svg {
        width: 13px;
        height: auto;
    }
    #ncx_market_table td.pair_name{
        column-gap: 5px !important;
    }
    tbody#ncx_market_table tr td h2{
        font-size: 12px !important;
    }
    tbody#ncx_market_table tr td p{
        font-size: 10px !important;
    }
    #table_divddd thead th:nth-child(1) {
        order: 1;
    }
    #table_divddd thead th:nth-child(6) {
        text-align: center !important;
    }
    #table_divddd thead th:nth-child(2) {
        order: 3;
    }
    #table_divddd thead th:nth-child(3) {
        order: 4;
        text-align: end;
        display: none;
    }
    #ncx_market_table tr td.pair_name{
        order: 1;
        padding-right: 15px;
    }
    #ncx_market_table tr td.price_trend_graph{
        order: 2;
    }
    #ncx_market_table tr td.latest_price{
        order: 3;
    }
    #ncx_market_table tr td.latest_change{
        order: 4;
        text-align: end;
        display: none
    }
    #table_divddd thead tr th:nth-child(7){
        order: 4 !important;
        display: block !important;
    }
    #table_divddd thead th:nth-child(6), tbody#ncx_market_table tr td:nth-child(6){
        order: 3 !important;
    }
    #ncx_market_table .graphing{
        width: 25% !important;
    }
    .table_search_tabs .searchinput input{
        width: 200px;
    }
    #table_divddd thead tr{
        padding: 10px 0px !important;
    }
    section.market_pag{
        padding: 0px 20px 50px 20px !important;
    }
    .market_pag .container{
        padding: 0px 0px !important;
    }
    #featured_slider .slider_market .slide{
        min-width: 180px !important;
    }
    .trending_markets .container.mobile {
        display: block;
    }
    .trending_markets .container.desktop {
        display: none;
    }
    p.fet_tag{
        font-size: 8px !important;
    }
    .trend_pairs h1{
        font-size: 12px !important;
    }
    .trend_pairs p{
        font-size: 10px !important;
    }
    .trend_pairs{
        padding: 10px !important;
    }
    .price_graph{
        padding: 0px 10px 10px 10px;
    }
    .prix h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .prix p{
        font-size: 10px;
    }
    #featured_slider_mobile .sliderTray___-vHFQ.sliderAnimation___300FY.carousel__slider-tray.carousel__slider-tray--horizontal,
    #featured_slider_mobile .slider_market, #featured_slider_mobile:before, #featured_slider_mobile:after {
        height: 98px !important;
        min-height: 98px !important;
    }
    
}

.graphing {
    width: 15% !important;
    margin: 0 auto;
    margin-right: 0px;
    position: absolute;
    right: 0px !important;
}
.price_trend_graph {
    text-align: end;
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 10px;
    position: relative;
    z-index: 1;
}
#ncx_market_table tr:nth-child(9) td.quote_volume h2, #ncx_market_table tr:nth-child(20) td.quote_volume h2,
#ncx_market_table tr:nth-child(35) td.quote_volume h2{
    position: relative;
    width: fit-content;
    margin: 0 auto;
}
#ncx_market_table tr:nth-child(9) td.quote_volume h2:after, #ncx_market_table tr:nth-child(20) td.quote_volume h2:after,
#ncx_market_table tr:nth-child(35) td.quote_volume h2:after {
    content: ' BTC';
}
#ncx_market_table tr:nth-child(9) td.quote_volume h2:before, #ncx_market_table tr:nth-child(20) td.quote_volume h2:before,
#ncx_market_table tr:nth-child(35) td.quote_volume h2:before{
    content: '';
    width: 8px;
    height: 20px;
    background: #FFFFFF;
    display: block;
    position: absolute;
}
#ncx_market_table .graphing {
    position: absolute;
    height: 100% !important;
    overflow: hidden;
}
tr.hiddddd {
    display: none !important;
}