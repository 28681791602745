.about_desktop, .inst_desktop, .otc__desktop {background: #000000;}
.ind__fq summary{display: flex;justify-content: space-between;}
.brd__btm{position: relative;}
.brd__btm:before{content: ""; display: block; height: 0.75px; margin: 0 auto; position: absolute; top: 0; width: 48%; background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0)); left: 2.1%;}
.brd__btm:after{content: ""; display: block; height: 0.75px; margin: 0 auto; position: absolute; top: 0; width: 48%; background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0)); right: 2.1%;}
.team_pairs span.balance, .team_pairs h1 {font-size: 38px !important; line-height: 44px !important; font-family: 'urbanist-medium' !important; display: block; width: 100%; text-align: center; color: #FFFFFF;}
.team_pairs p {font-family: 'urbanist-light' !important; font-size: 16px !important; display: block; width: 50%; margin: 0 auto; text-align: center; color: #FFFFFF;}
.about_team .bar-grade33 {margin: 0px 0px 30px 0px !important;}
.about_team {padding: 50px 0px;}
.about-txt p {font-size: 18px; font-family: 'hauora-extralight'; line-height: 30px; text-align: justify; margin-bottom: 30px; color: #FFFFFF;}
.ncx-office .img12 img {width: 47.5%; filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25)); transform: translateY(0%); transition: .4s ease;}
.ncx-office .img12 {display: flex; width: 100%; position: relative; justify-content: space-between; padding-top: 50px;}
.row.team_pairs {margin: 0px !important;}
.row.team_pairs .col {padding: 0px !important; flex: inherit !important; width: 25%;}
.about_content {padding: 50px 0px 100px 0px;}
.ncx-office .img12 img:hover {transform: translateY(0%) scale(1.06); -webkit-transform: translateY(0%) scale(1.06); -moz-transform: translateY(0%) scale(1.06); cursor: pointer;}
.row.team_pairs.mobile{display: none;}
.inst__itute {padding: 100px 0px;}
.inst__itute h1 {font-size: 32px; color: #FFFFFF; font-family: 'urbanist-regular'; text-align: center; line-height: 36px; margin-bottom: 50px;}
.inst__itute .OTCsect.row {margin: 0px !important; width: 100%; justify-content: space-between; padding: 0px;}
.inst__itute .OTCsect.row.second{padding-top: 25px;}
.inst__itute .OTCsect.row .col {min-height: unset !important; flex: inherit; width: 32%; border: 1px solid rgba(255, 255, 255, 0.15); padding: 30px 20px 30px 20px;}
.inst__itute .OTCsect.row .col:hover, .vx__vrd .vf.row .ml__huc:hover{ box-shadow: 0 0 15px rgba(255,255,255,.5);}
.inst__itute .otctxt p{ min-height: unset !important; margin: 0px !important; font-size: 15px !important; color: #FFFFFF;}
.inst__itute .otctxt h2{ margin-bottom: 20px !important; font-size: 20px !important; color: #FFFFFF;}
.vx__vrd h2{ font-size: 32px; color: #FFFFFF; font-family: 'urbanist-regular'; text-align: center; line-height: 36px; margin-bottom: 50px;}
.vx__vrd{padding: 100px 0px;}
.vx__vrd .wallet__defi{padding: 0px !important;}
.vx__vrd .vf.row {margin: 0px; display: flex; justify-content: space-between;}
.vx__vrd .vf.row .ml__huc {width: 32% !important; flex: inherit; padding: 30px 20px; border: 1px solid rgba(255,255,255,0.15); text-align: center;}
.vx__vrd .vf.row .ml__huc img { width: 80px; height: 80px; margin-bottom: 30px;}
.vx__vrd .vf.row .ml__huc h3{ margin-bottom: 20px !important; font-size: 20px !important; font-family: 'urbanist-medium'; color: #FFFFFF;}
.vx__vrd .vf.row .ml__huc p{font-size: 15px !important; font-family: 'urbanist-regular'; color: #FFFFFF;}
.ind__fq details[open] .red svg {transform: rotate(180deg); transition: all .4s cubic-bezier(1,1,1,1);}
.ind__fq .red svg {width: 16px !important; transition: all .4s cubic-bezier(1,1,1,1); }

/* Institutional Page Styling */
.inst_desktop .ml__vwxf .insse h1 {width: 380px !important;}
.rates_div .marker_lowest{width: 50%; text-align: center;}
.rates_div {display: flex; align-items: center; justify-content: center; margin-top: 20px;}
.marker_lowest h4 {font-family: 'hauora-medium'; font-size: 24px; line-height: 30px; color: #FFFFFF;}
.marker_lowest p {font-family: 'hauora-regular'; font-size: 12px; line-height: 16px; margin-top: 5px; color: #FFFFFF;}
h3.vcwe {text-align: center; color: #FFFFFF; font-family: 'hauora-medium'; font-size: 16px; line-height: 24px;}
.ind__fq.inst__itute.instV__vf .OTCsect.one.row {margin-top: 100px !important;}


/* NCX OTC Styling */
.otc__desktop .OTCsect.one.row {padding-top: 25px;}
.otc__desktop .ml__huc h4 {font-size: 20px !important; font-family: 'urbanist-regular'; line-height: 28px; color: #FFFFFF;}