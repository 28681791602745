video#video-scss {
    width: 100%;
    max-width: 250px;
    border-radius: 42px;
}
.sieze__ppp{
    padding: 50px 0px;
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689500938/ind__bg_c72nk0.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    position: relative;
}
.sieze__ppp:before{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 2%;
    margin: 0 auto;
}
.sieze__ppp:after{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);;
    display: block;
    position: absolute;
    top: 0;
    right: 2%;
    margin: 0 auto;
}
h1.fg__rer {
    font-size: 32px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    text-align: center;
    line-height: 36px;
    margin-bottom: 50px;
}
.txt__vvv h2 {
    font-size: 26px;
    line-height: 28px;
    font-family: 'roboto-light';
    color: #FFFFFF;
    margin-bottom: 45px;
}
.row.der__vido {
    margin: 0px !important;
    display: flex;
    flex-flow: wrap;
    padding-top: 50px;
}
.row.der__vido .col {
    padding: 0px;
    flex: 0 0 50%;
}
.col.bg__vdo{
    text-align: center;
}
/* .col.bg__vdo {
    text-align: center;
    background: url(../../../images/indice_ellipse.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
} */
.txt__vvv ul {
    padding: 0px;
    margin-bottom: 65px;
}
.txt__vvv li {
    font-size: 17px;
    line-height: 23px;
    color: #FFFFFF;
    font-family: 'roboto-light';
    margin-bottom: 25px;
    list-style-type: none;
    position: relative;
    padding-left: 30px;
}
.txt__vvv li strong{
    font-family: 'roboto-medium';
    font-weight: 500;
}
.col.txt__vvv {
    padding-right: 130px !important;
    padding-left: 70px !important;
    padding-top: 20px !important;
}
.txt__vvv li:before{
    content: '';
    background: url(../../../images/indices_arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    left: 0px;
    top: 5px;
}
.tiy__bd a.download {
    width: 140px;
    height: 44px;
    background: transparent;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'urbanist-light';
}
.tiy__bd {
    display: flex;
    column-gap: 10px;
}
.tiy__bd a.download:hover{
    background: #FFFFFF;
    color: #000000;
    border: 1px solid #FFFFFF;
}
.tiy__bd a.download:focus, .tiy__bd a.download:focus-visible,
.tiy__bd a.sign__up:focus, .tiy__bd a.sign__up:focus-visible{
    text-decoration: none !important;
    outline: none !important;
}
.tiy__bd a.sign__up:hover{
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border: 1px solid #FFFFFF;
    /* border: 1px solid #5ED3F0; */
}
.tiy__bd a.sign__up {
    min-width: 130px;
    height: 44px;
    filter: drop-shadow(0px 0px 20px rgba(94, 211, 240, 0.5));
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'urbanist-light';
}
@media screen and (min-width: 800px) and (max-width: 1200px){
    .col.txt__vvv {
        padding-right: 50px !important;
        padding-left: 30px !important;
    }
    .txt__vvv ul{
        margin-bottom: 45px;
    }
    .fg__rer {
        margin-bottom: 30px !important;
    }
}

@media screen and (max-width: 800px){
    .trade__cfd{
        padding: 70px 0px 50px 0px !important;
    }
    .col.txt__vvv {
        padding-right: 50px !important;
        padding-left: 30px !important;
    }
    .txt__vvv ul{
        margin-bottom: 20px;
    }
    .fg__rer {
        margin-bottom: 30px !important;
        font-size: 24px !important;
        line-height: 32px !important;
        font-family: 'urbanist-regular' !important;
    }
    .col.bg__vdo {
        order: 2;
        width: 100% !important;
        flex: 0 0 100% !important;
    }
    .col.txt__vvv {
        order: 1;
        width: 100% !important;
        padding: 0px !important;
        flex: 0 0 100% !important;
    }
    .row.der__vido{
        padding: 0px 0px 0px 0px !important;
    }
    .txt__vvv h2 {
        font-size: 18px;
        line-height: 24px;
        padding-right: 50px;
        margin-bottom: 30px;
    }
    .txt__vvv li:before{
        width: 10px !important;
        height: 10px !important;
    }
    .txt__vvv li {
        font-size: 14px !important;
        line-height: 20px !important;
        padding-left: 20px !important;
        margin-bottom: 10px !important;
    }
    .col.bg__vdo {
        padding: 50px 0px 0px 0px !important;
    }
    video#video-scss {
        width: 100%;
        max-width: 200px;
        border-radius: 32px;
    }
    #indicies__page .ind__fq{
        padding: 50px 0px 50px 0px !important;
        margin-top: 2px;
    }
    #indicies__page .ind__fq h1.fg__rer {
        text-align: center !important;
    }
    #indicies__page .ind__fq .blue svg {
        width: 14px !important;
    }
    #indicies__page .ind__fq summary {
        font-size: 14px !important;
        line-height: 20px !important;
        column-gap: 20px !important;
    }
    #indicies__page .steps__tr {
        padding: 50px 0px 50px 0px;
    }
    #indicies__page .fyu {
        flex: 0 0 100%;
    }
    #indicies__page .steps__ff{
        gap: 20px;
        padding-bottom: 0px;
    }
    #indicies__page .fyu h1 {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
    #indicies__page .trading__platform {
        padding: 50px 0px 50px 0px !important;
    }
    #indicies__page .fyu p{
        font-size: 16px;
        line-height: 24px;
    }
    .row_ban {
        flex-direction: column;
    }
}