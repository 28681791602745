/* .home-header {
    text-align: center;
  }
  
  .home-header h2 {
    color: #0080ff;
    font-size: 2rem;
  }
  
  .home-header h1 {
    font-size: 3rem;
    color: #0f52ba;
    margin-bottom: 1rem;
  }
  
  .home-header h1 span {
    color: #b0c4de;
  }
  
  .home-header p {
    color: #a9a9a9;
    font-weight: 500;
  } */
  .header-form .newsletter_form button svg{
    display: none;
  }
  .header-form .newsletter_form button p {
    margin: 0px !important;
    padding: 10px 0px;
    font-size: 16px !important;
}