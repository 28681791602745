#bannss.ind__banner h1 {
    font-size: 32px;
    line-height: 53px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    text-align: center;
}
#bannss.ind__banner p{
    font-size: 18px;
    line-height: 25px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    text-align: center;
}
.btn__dtr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 0px 0px;
    gap: 20px;
}
a.byyy {
    width: 140px;
    height: 44px;
    background: transparent;
    box-shadow: 0px 0px 15px (255,255,255,0.5) !important;
    border-radius: 50px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-family: 'urbanist-light';
    font-size: 16px;
    text-transform: capitalize;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5) !important;
}
a.marr {
    width: 140px;
    height: 44px;
    border: 1px solid #000000;
    border-radius: 50px;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    font-size: 16px;
    text-transform: capitalize;
}
a.byyy:hover{
    background: #FFFFFF !important;
    border: 1px solid #FFFFFF;
    color: #000000;
}
a.byyy:focus, a.byyy:focus-visible, a.byyy:active{
    outline: none;
    text-decoration: none;
}
a.marr:hover{
    border: 1px solid #fFFFFF;
    color: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5) !important;
}
a.marr:focus, a.marr:focus-visible, a.marr:active{
    text-decoration: none;
    outline: none;
}
#bannss.ind__banner .slvs {
    padding: 50px 0px 30px 0px;
}
/* #bannss.ind__banner{
    padding-bottom: 0px !important
} */
#bannss #single__part__row:before, #bannss #single__part__row:after{
    display: none !important;
}

@media screen and (max-width: 800px){
    #bannss.ind__banner .slvs{
        padding: 20px 0px 0px 0px !important;
        display: none !important;
    }
    /* #bannss.ind__banner .btn__dtr{
        margin-bottom: 50px;
    } */
    div#single__part__row {
        padding: 40px 0px 0px 0px !important;
    }
    div#single__part__row:before, div#single__part__row:after{
        display: none !important;
    }
    .btn__dtr .marr{
        display: none;
    }
}