.earingingcomplet-sect {
    text-align: center;
    padding: 80px 0px;
}
.earingingcomplet-sect h1 {
    color: #40475A;
    font-size: 42px;
    font-family: 'urbanist-medium';
    line-height: 46px;
}
.earingingcomplet-sect a.earn-toget {
    font-size: 14px;
    font-family: 'urbanist-medium';
    width: fit-content;
    height: 48px;
    background: #925BCA;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 50px;
    padding: 6px 15px;
}
.earingingcomplet-sect a.earn-toget:hover{
    background: #f6fafd;
    color: #000;
}
.earingingcomplet-sect a.earn-toget:focus, .earingingcomplet-sect a.earn-toget:focus-visible{
    outline: none;
    text-decoration: none;
}
.getocodesect {
    display: flex;
    column-gap: 50px;
    padding-top: 80px;
    justify-content: center;
}
.getocodesect .col{
    width: 33.33%;
    background: #F7F9FE;
    min-height: 215px;
    max-width: 360px;
    padding: 20px;
}
.getocodesect .col:hover{
    background: #FFFFFF;
    box-shadow: 0 0 16px rgb(184 201 255 / 30%);
    cursor: pointer;
}
.quesion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}
.quesion p {
    font-size: 10px;
    font-family: 'urbanist-regular';
    text-transform: uppercase;
}
.getocodesect h1 {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    font-family: 'urbanist-semibold';
    color: #2C2236;
    margin-bottom: 5px;
}
.getocodesect p{
    font-size: 12px;
    font-family: 'urbanist-light';
    line-height: 16px;
    color: #000000;
    text-align: left;
}
.getocodesect a {
    font-family: 'urbanist-regular';
    font-size: 12px;
    color: #925BCA;
    display: flex;
    justify-content: end;
}
p.getocode-txt {
    min-height: 97px;
}

@media screen and (max-width: 800px){
    .earingingcomplet-sect h1{
        font-size: 26px;
        line-height: 32px;
        font-family: 'urbanist-semibold';
        padding: 0px 10px;
    }
    .earingingcomplet-sect a.earn-toget{
        width: 230px;
        height: 40px;
        font-size: 13px;
    }
    .getocodesect{
        display: block;
        padding-top: 50px;
    }
    .getocodesect .col{
        width: 100%;
        max-width: inherit !important;
    }
    .getocodesect .col:nth-child(2){
        margin: 30px 0px;
    }
    .quesion p{
        font-size: 12px !important;
        font-family: 'urbanist-semibold';
    }
    .getocodesect h1{
        padding: 0px !important;
        text-align: left !important;
        font-size: 18px !important;
        line-height: 24px !important;
    }
    p.getocode-txt {
        min-height: 97px;
        font-size: 14px !important;
        line-height: 20px;
        font-family: 'urbanist-light' !important;
    }
}