/* #cex{
    background: #000000;
    padding-bottom: 100px;
}
#cex .row{
    margin: 0px !important;
}
.cexdex .col{
    width: 50% !important;
    padding: 0px;
}
.cex {
    padding-top: 0px !important;
    padding-left: 0px !important;
    width: 50%;
}
.dex{
    width: 50%;
}
.cexdex h1{
    padding: 0px;
    margin-bottom: 10px;
    font-size: 43px;
    line-height: 50px;
}
.animate__slideInUp.animate__animated {
    animation-duration: 0.8s !important;
}
.dex p{
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    font-family: 'hauora-light';
    text-align: justify;
}
.row.container {
    padding-bottom: 100px !important;
}
.second-container {
    padding: 70px 30px;
    background: #000000;
    max-width: 1300px !important;
    margin: 0 auto;
}
.second-container .cex.asss h1 {
    padding: 0px;
    margin-bottom: 10px;
    font-size: 43px;
    line-height: 50px;
    color: #FFFFFF;
}
.hero-section{
    position: relative;
    z-index: 1;
}
.hh_col {
    width: 25%;
    padding: 0px 15px 0px 15px !important;
    position: relative;
}
.line-div {
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    background: #515151;
    left: 0px;
}
.hh_col p {
    font-family: 'hauora-regular';
    color: #CCCCCC;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    padding-bottom: 20px;
}
.hh_col h1 {
    font-size: 22px;
    text-align: left;
    line-height: 26px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    margin: 0px 0px 50px 0px;
    min-height: 104px;
}
.hh_col h1 span {
    display: block;
}
.hh_row {
    padding-top: 120px;
}
.hh_icon {
    text-align: left !important;
}

.mobils_bs{
    display: none;
}
@media screen and (max-width: 800px){
    .mobils_bs{
        display: block;
    }
    .row.most_deri{
        display: none  !important;
    }
    #cex{
        background: #000000 !important;
        padding: 50px 0px;
    }
    #cex .second-container {
        padding: 0px 20px;
    }
    .hero-section button.btn01 {
        font-size: 12px !important;
        padding: 0px 10px;
        margin-right: 10px !important;
    }
    .img001 img {
        width: 100%;
        max-width: 120px;
        height: auto;
    }
    .cexdex .row {
        margin: 0px !important;
    }
    .cexdex .container{
        background: none !important;
    }
    .cexdex{
        background-size: cover;
        background-position: center right 37%;
        padding-bottom: 50px !important;
        background-color: #000000;
    }
    .cex.asss h1 {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }
    .dex.bsss {
        margin-top: 30px;
    }
    .hh_col {
        width: 50% !important;
        float: left;
        padding: 0px;
        height: 200px;
        margin-bottom: 40px;
    }
    .hh_col h1 {
        font-size: 18px;
        padding-right: 0px !important;
        margin-bottom: 30px;
        line-height: 26px !important;
        min-height: 80px;
    }
    .line-div{
        height: 95%;
    }
    .dex{
        width: 100%;
        padding: 10px 0px 0px 0px;
    }
    #cex .hh_row {
        padding-top: 50px;
    }
} */


/* New CSS */
#hme__gg__ff{
    padding: 100px 0px !important;
    position: relative;
}
.rf__aab.row {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
    margin: 0;
}
.rf__aab.row .col {
    backdrop-filter: blur(2px);
    background: hsla(0,0%,100%,.05);
    border: 1px solid hsla(0,0%,100%,.15);
    border-radius: 4px;
    flex: 0 0 21%;
    min-height: 324px;
    padding: 25px 20px;
    text-align: center;
}
.rf__aab.row img {
    height: 80px;
    width: 80px;
}
.rf__aab.row h3{
    color: #fff;
    font-family: roboto-light;
    font-size: 22px;
    margin: 25px auto 20px
}
.rf__aab.row p{
    color: #b9b9bb;
    font-family: roboto-light;
    font-size: 16px;
    line-height: 22px;
}
.rf__aab.row .col:hover {
    border: 1px solid #fff;
    box-shadow: 0 0 15px hsla(0,0%,100%,.75);
}



@media screen and (max-width: 800px){
    .rf__aab.row {
        display: none;
    }
    #hme__gg__ff{
        display: none;
    }
    #hme__gg__ff .mbl__sec__cr .global__row.row .col{
        min-height: unset !important
    }
    #hme__gg__ff{
        padding: 50px 0px !important;
        margin-top: 5px;
    }
    .mbl__sec__cr h3{
        font-size: 18px;
        color: #FFFFFF;
        line-height: 26px;
        margin: 20px 0px 10px 0px;
    }
    #hme__gg__ff .mbl__sec__cr p{
        min-height: 100px !important;
    }
    #hme__gg__ff .mbl__sec__cr .global__row .carousel__slide{
        padding-bottom: 10px !important;
    }
    #hme__gg__ff .mbl__sec__cr .carousel__dot-group{
        margin-top: 10px !important;
    }
    div#hme__gg__ff:before {
        content: "";
        display: block;
        height: 0.75px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 48%;
        background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
        left: 2.1%;
    }
    #hme__gg__ff:after {
        content: "";
        display: block;
        height: 0.75px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 48%;
        background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
        right: 2.1%;
    }
}