/************************** HomePage Eight Gradient Bar Section Section Starts from here *************************/
/* GradientBar */
.gradient {
    padding: 70px 0px 100px 0px;
    background: #FFFFFF;
    position: relative;
}
.gradientbar {
    background: linear-gradient(90deg, rgba(55,171,255,1) 7%, rgba(238,112,228,1) 36%, rgba(146,91,202,1) 77%, rgba(49,112,246,1) 100%) !important;
    /* transform: rotate(90deg); */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    height: 5px;
    max-width: 1380px;
    margin: 0 auto;
}
.row.gradient-text {
    margin: 0px;
}
.gradient-text .col {
    padding: 0px;
    padding-right: 30px;
    margin-top: 35px;
}
.gradient-text .col:last-child {
    padding: 0px;
    padding-right: 0px !important;
}
.gradient-text .col h1{
    font-family: 'urbanist-medium';
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 15px;
}
.gradient-text .col h3{
    font-family: 'hauora-regular';
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
}
.gradient-text .col p{
    font-family: 'hauora-regular';
    font-size: 16px;
    text-align: left;
}
.gradient-animation-one {
    animation-duration: 0.8s !important;
}
.gradient-animation-two {
    animation-duration: 2.0s !important;
}
.gradient-animation-three {
    animation-duration: 3.2s !important;
}
.gradient-bar {
    overflow: hidden;
}

@media screen and (max-width: 800px){
    .gradient{
        margin-top: -1px;
        padding: 50px 0px 50px 0px;
    }
}