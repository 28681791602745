div#footerdiv {
    padding: 70px 0px 20px 0px;
    background: #000000;
    position: relative;
}
.footer-row {
    display: flex;
    justify-content: space-between;
}
.footer-row ul li {
    list-style-type: none !important;
    text-align: left;
    padding-bottom: 20px;
}
.footer-row ul li a {
    color: #FCFCFD;
    font-family: 'urbanist-regular';
    font-size: 16px;
    line-height: 20px;
    font-weight: 400 !important;
}
.footer-row ul li a:hover{
    text-decoration: none;
    /* color: #5ED3F0; */
}
.footer-row ul li a:focus, .footer-row ul li a:focus-visible{
    text-decoration: none;
    outline: none;
    /* color: #5ED3F0; */
}
.app-barcode-row {
    display: flex;
    margin-top: 30px;
}
.appsttore p {
    font-size: 18px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    margin-bottom: 8px;
    margin-top: 12px;
}
.appsttore svg {
    display: block;
    width: 115px;
    height: auto;
    margin-bottom: 10px;
}
.bardcodd img {
    width: 100px;
    height: auto;
    padding-bottom: 10px;
}
.bardcodd p {
    font-size: 12px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    text-align: center;
    width: 80%;
    margin: 0 auto;
}
.bardcodd {
    text-align: center;
    padding: 10px 10px;
}
.social-media p {
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'urbanist-regular';
    margin-bottom: 10px;
}
.social-icons {
    display: flex;
    column-gap: 20px;
}
.social-icons .icons a {
    width: 44px;
    height: 44px;
    background: rgba(255,255, 255, 0.15);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}
.social-icons .icons a:hover {
    background: #5ED3F0;
}
.social-media {
    padding-top: 10px;
}
.fot-newsleter {
    padding-top: 25px;
}
.fot-newsleter p {
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    font-size: 18px;
    margin-bottom: 10px;
}
.fourth-column {
    max-width: 270px;
}
.fot-newsleter form input {
    width: 100%;
    max-width: 250px;
    background: none;
    border: 0.5px solid rgba(255,255,255,0.5);
    min-height: 45px;
    padding: 10px;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 14px;
}
.fot-newsleter form input::placeholder{
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'urbanist-regular';
}
.fot-newsleter form input:focus, .fot-newsleter form input:focus-visible{
    outline: none;
    box-shadow: none;
    background: none !important;
}
.fot-newsleter form {
    position: relative;
}
.fot-newsleter form button {
    background: #5ED3F0;
    width: 38px;
    height: 36px;
    position: absolute;
    right: 25px;
    top: 4px;
    border-radius: 8px;
    border: 1px solid #5ED3F0 !important;
}
.fot-newsleter form button:foucs, .fot-newsleter form button:focus-visible{
    outline: none;
    box-shadow: none;
}
.fot-newsleter form button svg {
    max-width: 14px;
}
.fot-newsleter form button svg path {
    fill: #FFFFFF;
}
.fot-newsleter form button p {
    display: none;
}
.fot-newsleter form button:focus {
    outline: none;
    border: none !important;
}
.footer-logo a img {
    width: 160px;
    height: auto;
}
.info-mail {
    text-align: end;
}
.info-mail a {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 15px !important;
}
.copyright{
    padding-top: 60px;
}
.copyright p {
    color: #FFFFFF;
    font-family: 'hauora-regular';
    font-size: 11px;
    text-align: center;
    line-height: 16px;
    margin-bottom: 10px !important;
}
.copyright p a{
    color: #FFFFFF;
    font-family: 'hauora-regular';
    text-decoration: none;
}
.copyright p a:hover{
    text-decoration: underline;
}
ul.ul-one {
    padding-left: 0px;
    min-height: 180px;
    margin-bottom: 20px !important;
}
ul.ul-two {
    padding-left: 0px;
}
.footer-row h1 {
    font-family: 'urbanist-medium' !important;
    font-size: 24px !important;
    color: #FCFCFD !important;
    font-weight: 500;
    margin-bottom: 20px;
}
.mobile-footer{
    display: none;
}
.copyright p:nth-child(2) {
    width: 70%;
    margin: 0 auto;
}
li.hiddendss {
    visibility: hidden;
}
.footer-row ul li a {
    list-style-type: none;
    position: relative;
}
.footer-row ul li a:after {
    content: "";
    position: absolute;
    display: block;
    top: 24px;
    width: 0;
    height: 2px;
    background-color: #5ED3F0;
    transition: 1s;
}
.footer-row ul li a:hover:after {
    width: 100%;
}
@media screen and (max-width: 1280px){
    .footer-row ul li a{
        font-size: 14px !important;
    }
}
@media screen and (min-width: 1301px){
    .footer.container {
        max-width: 1250px !important;
        padding: 0px 20px !important;
    }
}
@media screen and (max-width: 1300px){
    .footer.container {
        width: 100%;
        padding: 0px 50px !important;
    }
}
@media screen and (max-width: 1020px){
    .footer.container {
        width: 100%;
        padding: 0px 20px !important;
    }
}
@media screen and (max-width: 800px){
    div#footerdiv{
        padding: 50px 0px 30px 0px !important;
        background: none;
        border-top: 0.5px solid rgba(255, 255, 255, .5);
        background: #000000 !important;
    }
    .mobile-footer{
        display: block;
        padding: 0px 20px;
    }
    #footerdiv .container.footer{
        display: none;
    }
    .mob-foot-ul ul h1 {
        color: #FFFFFF;
        font-family: 'urbanist-medium';
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .app-barcode-row {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .mob-foot-ul ul {
        padding: 0px;
        margin: 0px;
        width: 50% !important;
    }
    .mob-foot-ul ul li {
        list-style-type: none;
        color: #FFFFFF;
    }
    .mob-foot-ul ul li {
        list-style-type: none;
        color: #FFFFFF;
        padding: 5px 0px;
    }
    .mob-foot-ul ul li a {
        color: #FFFFFF;
        font-family: 'urbanist-regular';
        font-size: 13px;
    }
    .mob-foot-ul ul li a:hover{
        text-decoration: none;
        /* color: #5ED3F0; */
    }
    .mob-foot-ul ul li a:focus, .mob-foot-ul ul li a:focus-visible{
        text-decoration: none;
        color: #5ED3F0;
    }
    .mob-foot-ul ul li a {
        list-style-type: none;
        position: relative;
    }
    .mob-foot-ul ul li a:after {
        content: "";
        position: absolute;
        display: block;
        top: 18px;
        width: 0;
        height: 2px;
        background-color: #5ED3F0;
        transition: 1s;
    }
    .mob-foot-ul ul li a:hover:after {
        width: 100%;
    }
    .mob-foot-ul {
        padding-top: 20px;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .copyright {
        padding-top: 40px;
    }
    .mobile-footer .fot-newsleter form {
        position: relative;
        width: 100%;
    }
    .fot-newsleter form input{
        max-width: inherit !important;
        min-height: 45px;
    }
    .fot-newsleter form button {
        border: none !important;
        width: 38px;
        height: 35px;
        right: 5px;
        top: 5px;
    }
    .fot-newsleter p, .social-media p{
        font-size: 15px !important;
        line-height: 20px !important;
    }
    /* .social-media {
        padding-top: 20px;
    } */
    .social-icons .icons a{
        width: 42px;
        height: 42px;
    }
    .social-icons .icons a img{
        width: 16px;
    }
    .bardcodd {
        text-align: center;
        padding: 15px 10px 5px 10px;
    }
    .copyright p:nth-child(2){
        width: 100% !important;
    }
    .copyright p{
        font-family: 'hauora-regular' !important;
    }
    .copyright p a{
        font-family: 'hauora-medium' !important;
    }
    .appsttore img {
        /* width: 100%; */
        max-width: 142px;
        /* margin-bottom: 10px; */
    }

    .mbl--menu.foot--menu {
        padding-top: 25px;
        margin-bottom: 0px;
    }
    .mjh--s {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0px 10px 0px;
    }
    .social-icons{column-gap: 15px;}
    .appsttore p, .social-media p {
        font-size: 16px !important;
        font-family: 'urbanist-regular';
        color: #FFFFFF;
        margin-bottom: 10px !important;
        line-height: 20px !important;
    }
    .appsttore p{text-align: end; margin-top: 0px !important;}
    .social-media{padding-top: 0px;}
}