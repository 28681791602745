.wallet-slider .container{
    max-width: 1440px !important;
}
.coin__chain__ccx{
    column-gap: 8px;
    display: flex;
    justify-content: center;
}
.coin__chain {
    display: flex;
    align-items: center;
    padding: 8px 15px 8px 8px;
    height: 56px;
    border-radius: 80px;
    column-gap: 8px;
    flex-shrink: 0;
    position: relative;
}
.coin__chain img {
    width: 40px;
    height: auto;
}
.coin__chain p{
    font-size: 18px;
    line-height: 22px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
}
.wallet-slider {
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
}
.wallet-slider h1 {
    font-size: 60px;
    color: #FFFFFF;
    line-height: 64px;
    margin-bottom: 40px;
    text-align: center;
}
.wallet-slider h3{
    font-size: 18px;
    line-height: 28px;
    color: #A1A3A7;
    font-family: 'urbanist-medium';
    text-align: center;
    margin-top: 40px;
}
/* .ethereum p{
    color: #000000 !important;
}
.arbitrum{
    background: #28A0F0;
}
.bnb_chain{
    background: #F0B90B;
}
.aurora{
    background: #78D64B;
}
.polygon{
    background: #8248E5;
}
.optimism{
    background: #F01A37;
}
.ethereum{
    background: #FFFFFF;
}
.base{
    background: #0151FE;
}
.avalanche{
    background: #000000;
}
.gnosis{
    background: #48A9A6;
} */

div.highway-slider {
	display:flex;
	justify-content:center;
	width:100%;
	height:56px;
    position: relative;
}
div.highway-barrier {
    overflow:hidden;
    position:relative;
}
.highway-lane {
    display:flex;
    height:100%;
    gap: 8px;
}
@keyframes translatestf {
	0% { 
        transform:translateX(100%); 
    }
	100% {
        transform:translateX(-500%); 
    }
}
.highway-car {
    animation:translatestf 30s linear infinite;
}
@keyframes translateinfinite {
	100% { 
        transform:translateX(calc(-180px * 12));  
    }
}
.highway-lane {
    width:calc(180px * 24);
}
.coin__chain  {
    background: #151515 !important;
    border: none !important;
    animation:translateinfinite 55s linear infinite;
}
div#infinite:before {
    content: '';
    width: 330px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 90%);
    z-index: 1;
}
div#infinite:after {
    content: '';
    width: 330px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 90%);
    z-index: 1;
    left: auto;
    right: -2px;
    transform: scaleX(-1);
}



@keyframes translatestff {
	0% { 
        transform:translateX(-500%); 
    }   
    100% {
        transform:translateX(100%); 
    }
}
#right__left .highway-car {
    animation:translatestff 30s linear infinite;
}
@keyframes translateinfinites {
	0% { 
        transform:translateX(calc(-180px * 12));  
    }
}
#right__left .coin__chain  {
    animation:translateinfinites 90s linear infinite;
}
section#right__left {
    margin-top: 20px;
}

#wallet_main_screen #home__ffgrr.ncxapp-section #ncx_buycrypto_video video{
    max-height: 920px !important;
}
#wallet__main__cc #home__ffgrr.ncxapp-section .apps_barcod {
    min-width: 340px;
}   
#wallet__main__cc #home__ffgrr.ncxapp-section .row .col:first-child h1 span{
    display: block;
}
#wallet__main__cc .ncxapp-section .row .col:first-child p{
    font-size: 18px;
    line-height: 25px;
}
#wallet__main__cc .ncxapp-section .row .col:first-child p span{
    display: block;
}

#wallet_main_screen .ml__bgvv video{
    width: 100%;
    max-height: 600px;
}
#wallet_main_screen .ncxapp-section .row{
    min-height: unset !important;
}
#wallet_main_screen .row .col:nth-child(1) {
    padding: 0px !important;
    padding-top: 30px !important;
}
#wallet_main_screen .row{
    padding: 50px 0px 0px 0px;
}
#wallet_main_screen .ncxapp-section .row .col:last-child{
    align-items: flex-start !important;
}
#wallet__main__cc .ncxapp-section .row .barcodd p {
    width: 105px !important;
}