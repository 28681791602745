#home_coins.wallet-slider h1 span:last-child {
    display: block;
}
#home_coins.wallet-slider h1{
    font-size: 40px;
    line-height: 45px;
    font-family: 'urbanist-light';
}
#home_coins.wallet-slider h1 span strong{
    font-family: 'urbanist-medium';
    font-weight: 500;
}
#home_coins p.gjg_gf {
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    margin: 40px 0px;
}
#home_coins .coin__tb {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
#home_coins .coin__tb a.lnch_dx{
    height: 44px;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'urbanist-light';
    text-transform: capitalize;
    min-width: fit-content;
    padding: 0 4rem;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
#home_coins .coin__tb a.lnch_dx:hover{
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    text-decoration: none;
    color: #000000;
}
#home_coins .coin__tb a.lnch_dx:focus, #home_coins .coin__tb a.lnch_dx:focus-visible{
    outline: none;
    text-decoration: none;
}
#home_coins .coin__tb a.dx_drp{
    height: 44px;
    background: none;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'urbanist-light';
    text-transform: capitalize;
    min-width: fit-content;
    padding: 0 2rem;
}
#home_coins .coin__tb a.dx_drp:hover{
    border: 1px solid #5ED3F0;
    outline: none !important;
    box-shadow: 0px 0px 20px rgba(94, 211, 240, 0.7);
}
#home_coins.wallet-slider {
    min-height: unset;
    padding: 100px 0px 100px 0px;
    margin-top: 100px;
}
#home_coins .coin__chain{
    background: #151515 !important;
    border: none !important;
}