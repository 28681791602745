.earnncx {
    background: #000000;
    padding: 100px 0px 100px 0px;
    position: relative;
    margin-top: 2px;
}
.earnheading{
    margin-bottom: 50px;
}
.earnheading h1 {
    text-align: center;
    font-size: 42px;
    color: #40475A;
    font-family: 'urbanist-medium';
}
.earnbadge .col {
    background: #F7F9FE;
    width: 195px;
    max-width: 195px;
    border-radius: 8px;
    text-align: center;
    height: 262px;
    padding: 0px 20px;
    border: 1px solid #DDDCEA;
    transform: translateY(0%);
    transition: .4s ease;
}
.earnbadge .col:hover{
    transform: translateY(0%) scale(1.06);
    -webkit-transform: translateY(0%) scale(1.06);
    -moz-transform: translateY(0%) scale(1.06);
    cursor: pointer;
}
.earnbadge .col.fuji {
    background: #251E4F;
    border: none !important;
}
.earnbadge.row {
    display: flex;
    width: 100%;
    max-width: 1030px;
    margin: 0 auto !important;
    column-gap: 10px;
}
.earnbadge .col img {
    width: 90px;
    padding-top: 30px;
}
.complete-bar {
    background: #565672;
    width: 100%;
    height: 14px;
    border-radius: 50px;
    margin-top: 40px;
}
.completetext {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.completetext p {
    color: #CBB7E0 !important;
    font-size: 12px !important;
    font-family: 'urbanist-regular';
    text-transform: initial !important;
}
.completetext p span{
    color: #979797;
}
.badgeimg {
    min-height: 152px;
    margin-bottom: 20px;
}
.earnbadge .col p {
    color: #c4c4c4;
    text-transform: uppercase;
    font-size: 13px;
}
.earnbadge .col h3{
    color: #c4c4c4;
    font-family: 'urbanist-semibold' !important;
    font-size: 18px !important;
}
.ncxearnanimate {
    width: 195px !important;
    padding: 0px;
}

.earnbadge.row.mobile{
    display: none !important;
}
@media screen and (max-width: 800px){
    .earnbadge.row {
        display: none;
    }
    .earnbadge.row.mobile{
        display: flex !important;
        justify-content: center;
    }
    .earnbadge.row.mobile .col{
        width: 49% !important;
    }
    .earnncx{
        padding: 0px 0px 50px 0px !important;
        margin-top: 0px;
    }
    .earnncx:after, .earnncx:before{
        display: none;
    }
    .earnheading{
        margin-bottom: 30px !important;
    }
    .earnheading h1{
        font-size: 26px !important;
        line-height: 32px !important;
        text-align: left !important;
    }
    .earnbadge.row::before, .earnbadge.row:after{
        display: none !important;
    }
    .earnbadge .col{
        height: 210px !important;
        margin-bottom: 10px;
    }
    .earnbadge .col img{
        width: 70px !important;
    }
    .badgeimg{
        min-height: 120px;
    }
    .complete-bar{
        margin-top: 30px;
    }
}