/* Splash Screen */
.visibleSplash {
	overflow: visible !important;
    z-index: 999 !important;
}
/* body.body_visible {
    overflow: visible !important;
} */
.splashupperscreen{
    width: 100%;
 	height: 50vh;
	position: absolute;
	top: 0;
	left: 0;
	background: #000000;
	/* border-bottom: 5px solid #000; */
	overflow: hidden;
    z-index: 999;
}
.splashlowerscreen{
    width: 100%;
 	height: 50vh;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #000000;
	/* border-bottom: 5px solid #000; */
	overflow: hidden;
    z-index: 1;
}
.loadingContainer {
	width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	overflow: hidden;
    z-index: 9999;
}
.loadingBox {
	width: 100%;
	height: 100%;
	opacity: 0;
}
.splashLogo {
	width: 50%;
	margin: 0 0 20px;
}
.loadingBarContainer {
    width: 100%;
    background: #30303C;
    height: 1px;
    display: block;
    margin: 40px 0 0;
    overflow: hidden;
}
.loadingbar {
	width: 100%;
	height:10px;
    background-image: linear-gradient(90deg,#37ABFF,#ff78e5 50%,#00d85a);
	background-image: -webkit-gradient(linear,left top,right top,from(#37ABFF),color-stop(50%,#ff78e5),to(#00d85a));
	position: absolute;
	left: -100%;
}

body{
    overflow: hidden;
}
.loading {
    position: fixed;
    left: 50%;
    top: calc(20% - 0px);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 4px;
    z-index: 9999999999;
    width: 100%;
}
.loading span {
    -webkit-animation: loading 1.4s infinite alternate;
    animation: loading 1.4s infinite alternate;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 12px;
    display: inline-block;
    padding: 0px 5px;
}
.loading span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.loading span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.loading span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.loading span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    visibility: hidden;
    width: 0px;
    height: 0px;
}
.loading span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.loading span:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.loading span:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.loading span:nth-child(8) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}
.loading span:nth-child(9) {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.loading span:nth-child(10) {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}
.loading span:nth-child(11) {
    -webkit-animation-delay: 1.0s;
    animation-delay: 1.0s;
}
.loading span:nth-child(12) {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}
.loading span:nth-child(13) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}
.loading span:nth-child(14) {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
}
.loading span:nth-child(15) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}
.loading span:nth-child(16) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.loading span:nth-child(17) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}
.loading span:nth-child(18) {
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}
.loading span:nth-child(19) {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}
.loading span:nth-child(20) {
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}
.loading span:nth-child(21) {
    -webkit-animation-delay: 2.0s;
    animation-delay: 2.0s;
}
.loading span:nth-child(22) {
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.2s;
}
.loading span:nth-child(23) {
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
}
.loading span:nth-child(24) {
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}
.loading span:nth-child(25) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}
.loading span:nth-child(26) {
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s;
}
@-webkit-keyframes loading {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
}
@keyframes loading {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}
.loadingContainer.splashdone{
    width: 100%;
    animation: line 1s linear forwards;
    /* -webkit-animation: loading 1.4s;
    animation: loading 1.4s; */
}
@keyframes line {
    from {
      left:50%;
      width:50%;
      opacity: 1;
    }
    to {
      left:50%;
      width:100%;
      opacity: 0;
    }
}
@media screen and (max-width: 767px){
    .loading {
        width: 100% !important;
        max-width: fit-content !important;
        display: flex;
    }
    .loadingContainer {
        min-width: 300px !important;
        max-width: 300px !important;
    }
}