.inst_mbl{display: none;}
.mbl__version{background: #000000;}
.about_mobile{display: none;}

/* OTC Mobile Page Styling */
.otc__mobile{display: none;}

@media screen and (max-width: 800px){
    .inst_mbl{display: block;}
    .inst_desktop{display: none;}
    .zx__zx video{width: 100%; max-height: 460px;}
    .zx__txt{padding: 10px 20px 50px 20px; text-align: center;}
    .zx__txt h2, .zx__zx2 h2{font-size: 26px; color: #FFFFFF; line-height: 36px; font-family: 'urbanist-regular';}
    .zx__txt p{font-size: 16px; color: #FFFFFF; line-height: 24px; font-family: 'urbanist-light'; margin-top: 15px;}
    .zx__txt a.btn__mail{height: 40px; font-size: 14px; font-family: 'urbanist-regular'; color: #FFFFFF; text-transform: capitalize; border: 1px solid #FFFFFF; border-radius: 50px; padding: 0px; width: 130px; margin-top: 30px; box-shadow: 0 0 15px rgba(255,255,255,.5);}
    .zx__txt a.btn__mail:hover{background: #FFFFFF; color: #000000 !important;}
    .zx__zx2{text-align: center; padding: 50px 20px;}
    .brd__btm{position: relative;}
    .brd__btm:before{content: ""; display: block; height: 0.75px; margin: 0 auto; position: absolute; top: 0; width: 48%; background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0)); left: 2.1%;}
    .brd__btm:after{content: ""; display: block; height: 0.75px; margin: 0 auto; position: absolute; top: 0; width: 48%; background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0)); right: 2.1%;}
    .xs__edf{text-align: left; padding: 20px; border: 1px solid rgba(255,255,255,0.15); margin-bottom: 15px; border-radius: 5px;}
    .xs__edf h3{font-size: 16px; color: #FFFFFF; line-height: 24px; font-family: 'roboto-regular'; margin-bottom: 20px;}
    .xs__edf p{font-size: 14px; color: #FFFFFF; line-height: 20px; font-family: 'roboto-light'; min-height: 60px;}
    .zx__zx2 h2{margin-bottom: 30px;}
    #ncx_institution .mbl__version .wws__xx summary, .about_mobile .wws__xx summary {padding: 20px 0px !important; color: #FFFFFF !important; border-bottom: 1px solid rgba(255,255,255,0.25); display: flex; justify-content: space-between;}
    #ncx_institution .mbl__version .wws__xx .contt, .about_mobile .wws__xx .contt{color: #FFFFFF !important; margin: 20px 0px 10px 0px !important; padding: 0px !important; text-align: justify;}
    #ncx_institution .mbl__version .wws__xx details[open] span.red, .about_mobile .wws__xx details[open] span.red {transform: rotate(180deg); transition: all 0.4s cubic-bezier(1,1,1,1);}
    .ml__huc h3 {font-size: 22px; font-family: 'roboto-light'; color: #FFFFFF; line-height: 28px; margin: 10px 0px 25px 0px;}
    .ml__huc p{font-size: 16px; font-family: 'roboto-light'; color: #B9B9BB; line-height: 22px;}
    .ml__huc img {width: 80px; height: 80px;}
    .ml__huc {width: 85%; margin: 0 auto; border: 1px solid rgba(255,255,255,0.15); margin-bottom: 30px; padding: 30px 35px; border-radius: 5px;}
    .ml__huc:hover, .xs__edf:hover{box-shadow: 0 0 15px rgba(255,255,255,.5);}
    .assets__mng .wws__xx{margin-bottom: 50px;}
    .zx__zx .mb__partner {padding: 50px 0px !important;}
    .zx__zx .mb__partner .box-slide{padding: 0px !important;}
    .zx__zx img {width: 100%;}
    #ncx_institution .mbl__version .wws__xx details:last-child summary, .about_mobile .wws__xx details:last-child summary{border-bottom: none !important; padding-bottom: 0px !important;}

    /* OTC Mobile Page Styling */
    .otc__desktop{display: none;}
    .otc__mobile{display: block;}
    .zx__zx.otc__pag .mb__partner .box-slide{padding-bottom: 15px !important;}
    h2.otc_v span{display: block;}
    h2.otc_v{font-size: 24px !important;}
    p.otc__p{text-align: justify !important;}

    /* About Us Page Styling */
    .about_desktop{display: none;}
    .about_mobile{display: block;}
    .about_mobile .team_pairs p, .about_mobile .team_pairs span.balance, .about_mobile .team_pairs h1{color: #FFFFFF !important;}
    .about_mobile .row.team_pairs.mobile {padding: 30px 20px 14px 20px !important; margin: 0px !important;}
    .about_mobile p.otc__p {font-size: 15px !important; line-height: 22px !important;}
    .about_mobile .ncx-office {padding-top: 30px;}
    .about_mobile .ncx-office .img12 {display: flex !important; flex-direction: column; gap: 20px; padding: 0px;}
    .about_mobile .ncx-office .img12 img{margin-bottom: 0px !important; width: 100% !important;}
    .zx__zx2 .wallet__defi{padding: 0px !important;}
    .about_mobile .mbl__version .ind__cont {padding-bottom: 0px !important;}
}