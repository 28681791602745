.mrkt__cfds {
    padding: 100px 0px;
    position: relative;
    margin-top: 1px;
}
.assets__ro h2 {
    font-size: 16px;
    font-family: 'roboto-medium';
    color: #FFFFFF;
    margin-bottom: 25px;
    line-height: 22px;
}
.assets__ro p {
    padding-right: 15px;
    font-size: 16px;
    font-family: 'roboto-light';
    line-height: 22px;
    color: #B9B9BB;
}
.assets__ro p strong {
    font-family: 'roboto-medium';
    font-weight: 400;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}
.assets__ro.row {
    margin: 0px;
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
}
.assets__ro.row .col {
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    flex: 0 0 21%;
    padding: 30px 20px;
}
.assets__ro.row .col:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 800px){
    .assets__ro h2{
        margin-bottom: 15px;
    }
}