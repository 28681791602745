.global__mart {
    padding: 100px 0px;
    position: relative;
    margin-top: 1px;
}
.global__row.row {
    margin: 0px;
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
}
.global__row.row .col {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
    border-radius: 4px;
    flex: 0 0 21%;
    min-height: 324px;
    padding: 25px 20px;
    text-align: center;
}
.global__mart{
    /* background: url(../../../images/indices_ellipse2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center; */
    /* padding-top: 60px; */
    /* position: relative; */
    /* margin-top: 60px; */
}
.global__row img, .global__row svg {
    width: 80px;
    height: 80px;
}
.global__mart:before{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 2%;
    margin: 0 auto;
}
.global__mart:after{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);;
    display: block;
    position: absolute;
    top: 0;
    right: 2%;
    margin: 0 auto;
}
.global__row h2 {
    font-size: 22px;
    font-family: 'roboto-light';
    color: #FFFFFF;
    margin-bottom: 20px;
    margin-top: 25px;
}
.global__row h2 span {
    display: block;
}
.global__row p{
    font-size: 16px;
    font-family: 'roboto-light';
    line-height: 22px;
    color: #B9B9BB;
    margin-bottom: 20px;
}
.global__row p span{
    display: block;
}
.global__row.row .col:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 15px rgba(255,255,255,.5);
}
@media screen and (max-width: 800px){
    .global__mart{
        background: #000000;
        margin-top: 1px;
        padding: 50px 0px !important;
    }
    .global__mart .global__row.row{
        display: none;
    }
    .mbl__sec__cr .global__row.row {
        display: block;
    }
    #indicies__page .mbl__sec__cr .global__row.row  h2{
        min-height: unset !important;
    }
    #indicies__page .global__row p{
        min-height: 100px !important;
    }
}