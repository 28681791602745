.balance-section {
    background: #000000;
    padding: 0px 0px 60px 0px;
}
.reward-row {
    border-top: 1px solid #565672;
    border-bottom: 1px solid #565672;
    padding: 17px 0px;
}
.reward-center {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.rewardheadrow {
    display: flex;
    justify-content: space-between;
    padding: 0px 50px;
}
.reward-center p {
    font-size: 16px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    line-height: 24px;
    border-right: 1px solid #565672;
    padding-right: 10px;
}
p.star-res {
    color: #E1C8FF;
    display: flex;
    align-items: center;
    gap: 10px;
    border-right: none !important;
    padding-right: 0px;
}
p.complete-tsks {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 16px;
    line-height: 24px;
}
.ncx-baldiv h3 {
    font-size: 32px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 36px;
}
.ncx-baldiv h1 {
    font-size: 80px;
    font-family: 'urbanist-semibold';
    color: #FFFFFF;
    line-height: 86px;
}
.ncx-baldiv h1 span {
    color: #CBB7E0;
}
.ncx-baldiv {
    display: flex;
    justify-content: space-between;
    margin: 40px 50px 30px 50px;
    padding: 25px 50px;
    border: 1px solid #565672;
    border-radius: 20px;
    position: relative;
}
.ncx-balance {
    text-align: center;
}
.ncx-balance .container p {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 24px;
    text-align: center;
    line-height: 30px;
}
.ncx-balance .container a.earn-toget{
    font-size: 18px;
    font-family: 'urbanist-semibold';
    width: 165px;
    height: 52px;
    background: #925BCA;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: 30px;
}
.ncx-balance .container a.earn-toget:hover{
    background: #f6fafd;
    color: #000;
}
.ncx-balance .container a.earn-toget:focus, .ncx-balance .container a.earn-toget:focus-visible{
    outline: none !important;
    text-decoration: none;
}
.ncx-balanceup, .colorncx-balanceup{
    font-size: 80px;
    font-family: 'urbanist-semibold';
    color: #FFFFFF;
    line-height: 86px;
}
.colorncx-balanceup{
    color: #CBB7E0;
}
.ncx-baldiv:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #E1C8FF;
    border-radius: 20px;
    animation: clippath 3s linear infinite;
}
@keyframes clippath{0%, to{
    -webkit-clip-path:inset(95% 0 0 0);
    clip-path:inset(95% 0 0 0)
    }
    20%{
        -webkit-clip-path:inset(0 95% 0 0);
        clip-path:inset(0 95% 0 0)
    }
    50%{
        -webkit-clip-path:inset(0 0 95% 0);
        clip-path:inset(0 0 95% 0)
    }
    70%{
        -webkit-clip-path:inset(0 0 0 95%);
        clip-path:inset(0 0 0 95%)
    }
}

@media screen and (max-width: 800px){
    /* .reward-row{
        margin-top: 7px;
    } */
    .rewardheadrow{
        padding: 0px;
        align-items: center;
    }
    p.complete-tsks, .reward-center p{
        font-size: 12px;
        line-height: 15px;
    }
    p.star-res svg {
        width: 12px;
    }
    .reward-center{
        column-gap: 5px;
    }
    .reward-center p {
        border-right: 1px solid #565672;
        padding-right: 5px;
        border-width: 1.5px;
    }
    p.star-res{
        border: none !important;
        padding: 0px !important;
        column-gap: 5px;
    }
    .ncx-baldiv{
        margin: 40px 0px 30px 0px;
        padding: 16px 24px;
        display: block;
    }
    .ncx-baldiv h3{
        font-size: 16px !important;
        font-family: 'urbanist-regular';
        line-height: 20px !important;
        text-align: left;
    }
    .ncx-balanceup, .colorncx-balanceup{
        font-size: 36px;
        line-height: 46px;
    }
    .balance-counter {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
    }
    .ncx-balance .container p{
        font-size: 16px;
        line-height: 20px;
    }
    .ncx-balance .container a.earn-toget {
        width: 145px;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
    }
    .balance-section{
        padding-bottom: 50px;
    }
}