.mart_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mkij {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 142px;
    background-color: #000000 !important;
    border: 1px solid rgba(255, 255, 255, 0.15);
}
p.tag_lst {
    font-size: 12px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    background: #5ED3F0;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 0px 0px 10px 0px;
}
h1.title_lst {
    font-size: 16px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 20px;
}
h1.title_lst span{
    display: block;
}
a.trd_btn {
    background: transparent;
    border: 1px solid;
    border-color: #5ED3F0;
    border-radius: 50px;
    color: #FFFFFF;
    height: 25px;
    font-size: 12px;
    font-family: 'urbanist-regular';
    text-transform: capitalize;
    width: fit-content;
    min-width: unset;
    padding: 0px 13px;
    transition: all 0.3s;
}
a.trd_btn:hover{
    background: #5ED3F0;
}
.mart1{
    background: url(../../../images/mart-1.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart2{
    background: url(../../../images/mart-2.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart3{
    background: url(../../../images/mart-3.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart4{
    background: url(../../../images/mart-4.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart5{
    background: url(../../../images/algo-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart6{
    background: url(../../../images/pepe-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart7{
    background: url(../../../images/apt-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart8{
    background: url(../../../images/ksm-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart9{
    background: url(../../../images/floki-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart10{
    background: url(../../../images/one-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart11{
    background: url(../../../images/shib-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart12{
    background: url(../../../images/yfi-img.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
}
.mart_row .carousel .carousel__slider-tray .carousel__slide {
    padding: 0px 7.5px;
}
.nhqwe {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}
#market .mart_fly .container {
    max-width: 1340px !important;
}
.mkij:hover {
    box-shadow: 0 0px 30px 0 rgba(255,255,255,.25);
}
.mart_row .carousel .carousel__dot-group {
    text-align: center;
}
.mart_row .carousel button.carousel__dot--selected{
    width: 40px !important;
    background: #FFFFFF !important;
    border: 2px solid #FFFFFF !important;
    border-radius: 50px !important;
}
.mart_row .carousel button.carousel__dot--selected span{
    background: #FFFFFF;
}
.mart_row .carousel .carousel__dot-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}
.mart_row .carousel button.carousel__dot {
    width: 7px;
    border: none;
    background: rgba(255,255,255,0.5);
    border-radius: 50px;
    padding: 0px;
    height: 4px;
}
.mart_row .carousel button.carousel__dot:hover{
    width: 20px;
    background: rgba(255,255,255,0.5);
    transition: all 0.3s;
}






@media screen and (max-width: 1080px){
    .mart3, .mart4{
        background-size: 45%;
    }
    h1.title_lst{
        font-size: 13px;
    }
}
@media screen and (max-width: 1020px){
    h1.title_lst{
        font-size: 12px;
    }
}

div#market__flyes {
    display: none;
}
@media screen and (max-width: 800px){
    .mart_row{
        display: none;
    }
    div#market__flyes {
        display: block;
    }
    /* #market__flyes .mkij{
        height: 150px;
        background-position: right bottom !important;
        background-size: contain !important;
    } */
    #market__flyes .carousel__dot-group {
        text-align: center;
    }
    #market__flyes button.carousel__dot--selected {
        width: 40px !important;
        background: #FFFFFF !important;
        border: 2px solid #FFFFFF !important;
        border-radius: 50px !important;
    }
    #market__flyes button.carousel__dot--selected span{
        background: #FFFFFF;
    }
    #market__flyes .carousel__dot-group {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 20px;
    }
    #market__flyes button.carousel__dot {
        width: 7px;
        border: none;
        background: rgba(255,255,255,0.5);
        border-radius: 50px;
        padding: 0px;
        height: 4px;
    }
    #market__flyes button.carousel__dot:hover{
        width: 20px;
        background: rgba(255,255,255,0.5);
        transition: all 0.3s;
    }
    /* #market__flyes .carousel__slider-tray {
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
    #market__flyes p.tag_lst {
        font-size: 10px;
    }
    .nhqwe{
        padding-left: 20px;
    }
    .mkij .nhqwe h1.title_lst{
        font-size: 16px !important;
        line-height: 22px !important;
    }
    #market__flyes .carousel__slider-tray .carousel__slide .carousel__inner-slide{
        width: 100% !important;
    }
    #market__flyes .carousel__slider-tray .carousel__slide{
        width: 100% !important;
        padding: 0px;
    } */
}