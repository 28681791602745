div#mobile-header {
    display: none;
}
.home-blog .container.mobile{
    display: none;
}

.section-center.mobile{
    display: none;
}
div#header-newmobile {
    display: none;
}
.by-date p:nth-child(1) {
    display: none;
}
#ncx_cryptoCard h1.border-bv:before{
    bottom: 13px !important;
}
.institutional__ser h1:nth-child(2):before{
    bottom: 10px !important;
}
.ncxdebitcreditcardpage #ncx_cryptoCard h1.border-bv:before {
    bottom: 11px !important;
}
@media screen and (max-width: 1440px){
    .wallet-slider .container{
        width: 100% !important;
    }
}
@media (min-width: 1200px){
    .container {
        width: inherit !important;
    }
}
@media screen and (max-width: 1300px){
    .container{
        width: 100% !important;
        padding: 0px 20px !important;
    }
    .product-tabs-btn{
        justify-content: space-between !important;
        column-gap: inherit !important;
    }
    button.pro-tabs{
        font-size: 16px !important;
        min-width: fit-content;
        width: fit-content;
    }
    .api-row{
        column-gap: 50px;
    }
    .ncxequities .row:before, .ncxequities .row:after{
        display: none;
    }
    .tasktext{
        width: 100% !important;
    }
    .col.card_txt .inns-div{
        padding: 0px 20px !important;
    }
    .col.card_imga img{
        max-height: 380px !important;
    }
    .col.card_imgb img{
        max-height: 380px !important;
    }
    .bbttn a.card_apply{
        min-width: fit-content !important;
    }
    .bbttn a.card_learn{
        min-width: fit-content !important;
    }
    .card_imgb video {
        height: 70%;
        border-radius: 20px;
    }
    .card_imga video {
        height: 70%;
        border-radius: 20px;
    }
    .col.card_txt .inns-div h1 {
        font-size: 40px !important;
        line-height: 50px !important;
    }
    h1.border-bv:before, .institutional__ser h1:nth-child(2):before{
        height: 10px !important;
        bottom: 8px !important;
    }
}
@media screen and (min-width: 801px) and (max-width: 1280px){
    .ncxequities #equities-tabs-sect .row .col{
        width: 45% !important;
    }
}
@media screen and (max-width: 800px){
    body{
        overflow: visible !important;
        /* overflow-x: hidden !important; */
    }
    .splashScreen {
        display: none !important;
    }
    .market-exchange .row.marketimg {
        display: none !important;
    }
    .section-center.desktop {
        display: none;
    }
    .section-center.mobile{
        display: block !important;
    }
    .home-blog .container.mobile{
        display: block;
    }
    .home-blog .container.desktop{
        display: none;
    }
    #main-header{
        display: none !important;
    }
    /* .market-table:before{
        content: 'Markets';
        font-size: 26px;
        line-height: 36px;
        font-family: 'urbanist-regular';
        position: relative;
        left: 10px;
    } */
    .header-logo{
        width: 100% !important;
        text-align: left;
        justify-content: left !important;
    }
    header#header-row{
        position: relative !important;
    }
    header#header-row .MuiToolbar-root {
        padding: 0px !important;
    }
    .container {
        width: 100% !important;
        padding: 0px 20px !important;
        max-width: 100% !important;
    }
    .asss {
        width: 100% !important;
    }
    .bsss{
        width: 100%;
        text-align: center;
    }
    .hero-section:before{
        display: none;
    }
    .hero-section{
        padding-top: 30px !important;
        padding-bottom: 0px !important;
        /* background: linear-gradient(180deg,rgba(5,27,96,0) 20%,#051b60 58.85%),#141414 !important; */
        /* background: url(../images/green-bg-mb.png); */
        background: #000000;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .hero-section .row.container{
        padding-bottom: 50px !important;
    }
    /* .second-container{
        display: none;
    } */
    
    .row {
        margin: 0px !important;
    }
    .market-exchange{
        padding: 50px 0px !important;
    }
    
    .currrency_24change h2 {
        text-align: right !important;
    }
    .gradient {
        padding: 50px 0px 80px 0px;
    }
    .gradient-text .col {
        width: 100% !important;
        flex: inherit;
        padding: 0px !important;
    }
    .download{
        padding: 20px 0px !important;
    }
    .stores {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bar12 img {
        max-width: 100%;
    }
    /* section.bg-dark {
        background: #FFFFFF !important;
    } */
    section#slider {
        background: #000000;
    }
    .stores img {
        max-width: 100% !important;
        margin: 5px 0px !important;
    }
    .bar11{
        padding-bottom: 20px !important;
    }
    .faq-0 button {
        width: 100% !important;
        margin: 0 auto !important;
        font-size: 14px;
        display: none;
    }
    .download .bsss img {
        max-width: 100%;
    }
    .home-blog .row {
        width: 100%;
        display: flex !important;
    }
    .home-blog .row .col-xs-2 {
        width: 50% !important;
        border-bottom: 1px solid #333A3D;
        text-align: center;
    }
    a#p2, a#p4, a#p6, a#p8, a#p10, a#p12, a#p14 {
        border-right: 0px !important;
    }
    a#p13, a#p14{
        border-bottom: 0px !important;
    }
    a#p5, a#p15{
        border-right: 1px solid #333A3D !important;
    }
    .home-blog {
        padding: 80px 0px 50px 0px !important;
        background-color: #000000;
    }
    .row.body-text.second .txt-blue:before{
        height: 105% !important;
    }
    .row.body-text.second .tickbox, .row.body-text.second .optionalbox {
        padding: 6px 0px;
    }
    section#slider{
        padding: 50px 0px 0px 0px !important;
    }
    #slider .section-center.mobile{
        padding: 0px 0px !important;
        position: relative;
    }
    #slider h1.include-features {
        font-size: 16px !important;
        line-height: 24px !important;
        width: 100% !important;
        min-height: inherit !important;
        padding: 0px 20px !important;
    }
    #slider > div > article:nth-child(2) > h1{
        width: 100% !important;
        margin: 20px 0px !important
    }
    #title {
        width: 100% !important;
        font-size: 26px;
        line-height: 36px;
        min-height: 72px !important;
        margin: 20px 0px !important;
        padding: 0px 20px !important;
    }
    .tag-class {
        width: 100% !important;
        padding: 0px 20px !important;
        min-height: inherit !important;
    }
    .section-center .tag-class h2 {
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        font-size: 12px !important;
        /* width: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5px 12px !important;
    }
    /* #slider > div.section-center.mobile > article:nth-child(3) > div.tag-class.rowfour,
    #slider > div.section-center.mobile > article:nth-child(4) > div.tag-class.rowthree,
    #slider > div.section-center.mobile > article:nth-child(4) > div.tag-class.rowfour > h2{
        visibility: hidden;
    } */
    #slider .tet-img{
        width: 100% !important;
        min-width: 300px !important;
        min-height: 370px !important;
    }
    #slider .tet {
        padding: 40px 20px 40px 20px !important;
        width: 100% !important; 
    }
    .tet p{
        margin-bottom: 30px !important;
    }
    #slider .tet a {
        width: 70% !important;
        display: block;
        text-align: center;
        margin: 0 auto !important;
        padding: 9px 20px !important;
    }
    img.perspon-img{
        max-width: 280px !important;
        left: 0px !important;
        top: -60px !important;
        right: 0px !important;
        margin-left: auto;
        margin-right: auto;
    }
    .moving-btm{
        width: 100% !important;
        top: 450px !important;
        padding: 0px 20px !important;
        position: absolute !important;
    }
    .footer-row {
        flex-flow: column wrap;
    }
    .first-column{
        order: 2;
        margin-top: 20px !important;
    }
    .third-column{
        display: none !important;
    }
    .second-column{
        order: 3;
    }
    .third-column{
        order: 4;
    }
    .fourth-column {
        order: 1;
        width: 100%;
    }
    .footer-logo {
        text-align: left;
    }
    .footer-logo a img {
        width: 120px !important;
        height: auto !important;
    }
    .info-mail {
        text-align: left !important;
    }
    .footer-row ul {
        padding-left: 0px !important;
        margin-bottom: 0px !important;
    }
    .footer-row ul li{
        padding-bottom: 10px !important;
    }
    .copyright p{
        font-size: 10px !important;
        text-align: center;
    }
    div#videosection {
        margin-top: 50px;
    }

    div#footer-faq{
        /* background: url(../images/ncx-faq-mblbg.png); */
        background: #000000;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #000000;
    }
    .title{
        border-top: 0.5px solid rgba(255, 255, 255, 0.5) !important;
    }
    span.svg {
        background: rgba(255, 255, 255, 0.5) !important;
    }
    .faq-sect{
        background: none !important;
        padding: 80px 0px 50px 0px !important;
    }
    .tet-img{
        background-color: #000000 !important;
        /* background: url(../images/green-bg-dt.png) !important; */
        background-repeat: no-repeat;
        background-size: 100% 100% !important;
    }
    #footer-faq #footerdiv {
        background: transparent !important;
    }
    h3.author-nnn{
        margin-bottom: 0px !important;
    }
    #slider > div.section-center.mobile > article:nth-child(4) > div.tags-div > div.tag-class.rowthree,
    #slider > div.section-center.mobile > article:nth-child(3) > div.tags-div > div.tag-class.rowfour > h2,
    #slider > div.section-center.mobile > article:nth-child(4) > div.tags-div > div.tag-class.rowfour > h2{
        display: none;
    }
    .download .row:before, div#HMPBlogSlider:before, div#HMPBlogSlider:after{
        display: none !important;
    }



    /* ************************************************************************************************** */
    /* ****************************************Blog Page Stlye******************************************* */
    /* ************************************************************************************************** */
    .blog-header {
        padding-top: 100px;
    }
    section#slider-blog {
        margin-right: 0px;
        margin-top: 60px;
        margin-bottom: 60px;
        height: fit-content;
        width: 100% !important;
    }
    #slider-blog h1{
        font-size: 18px;
        line-height: 26px;
        min-height: 100px;
    }
    #slider-blog p{
        font-size: 16px;
        line-height: 24px;
        min-height: 145px;
    }
    #slider-blog a.blogItem-link{
        margin-top: 30px;        
    }
    .blog-search {
        display: block;
        padding-bottom: 20px;
    }
    .blog_heading h1 {
        font-size: 32px !important;
        line-height: 48px !important;
        margin-bottom: 20px !important;
    }
    /* .blogItem-wrap:first-child {
        margin-top: 0px !important;
    } */
    /* .blog-image {
        height: 250px !important;
    } */
    /* .blogItem-wrap:nth-child(odd) {
        margin-top: 0px !important;
    } */
    /* .blogItem-wrap {
        margin-bottom: 50px !important;
    } */
    .blog-heading p {
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .blog_detailspage {
        /* z-index: 999;
        position: relative; */
        padding: 60px 0px !important;
    }
    .blog_detailspage .container .row .col.first, .blog_detailspage .container .row .col.third{
        display: none !important;
    }
    .blog_detailspage .container .row .col.second{
        width: 100% !important;
        padding: 0px 15px !important;
    }
    h1.blog-title{
        font-size: 28px !important;
        line-height: 34px !important;
    }
    .author-details {
        display: block !important;
        width: 100% !important;
        margin-top: 40px !important;
        padding: 20px 20px !important;
    }
    .profile {
        width: 100% !important;
        margin-bottom: 20px;
        display: flex !important;
        column-gap: 20px;
        align-items: center;
    }
    .author-details h1{
        margin-top: 0px !important;
    }
    .author-details p {
        width: 100%;
        font-size: 14px;
        text-align: justify;
        line-height: 20px;
    }
    .blog-content img {
        margin: 10px 0px;
    }
    .blog-subCategory p.chip{
        display: block !important;
        padding: 4px 8px !important;
    }
    .loading span:nth-child(5) {
        display: none;
    }
    .blog-search .blog_heading .trend svg {
        min-width: 130px !important;
        width: 130px !important;
        height: 30px !important;
    }
    .blog-search .blog_heading .trend h3 {
        font-size: 34px !important;
    }
    .blog-search .blog_heading .trend {
        margin-bottom: 30px;
    }
}
