.busi-plan{padding: 50px 0px 100px 0px; background: #000000;}
.busi-plan h2{color: #FFFFFF; text-align: center; font-size: 40px; font-family: 'urbanist-medium'; line-height: 50px;}
.plan-tabs{margin: 50px 0px;}
.bttP {display: flex; gap: 10px; width: fit-content; border-radius: 50px; background: #000; box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.40); padding: 5px; margin: 0 auto;}
.pl-btn {font-size: 18px; font-family: 'urbanist-medium'; border: none; border-radius: 50px; padding: 10px 40px; background: none; color: #FFFFFF; transition: 0.3s ease-in;}
.purpl.active{background: #B9A4FF;}
.green.active{background: #B0F0DA; color: #000000;}
.pl__cont{display: none; transition: 0.9s ease-in;}
.pl__cont.active{display: block; transition: 0.9s ease-in;}
.tab.row {margin: 0 auto;display: flex;justify-content: space-between;}
.tab.row .col {width: 31%; flex: inherit; background: #FFFFFF; padding: 0px;}
.clr{padding: 30px 25px;}
.lgt{background: #EFE9FE;}
.mdm{background: #D5C8FB;}
.hrd{background: #B9A4FF;}
.green1{background: #DFF9F0;}
.green2{background: #CEF6E9;}
.green3{background: #B0F0DA;}
.clr h2 {padding: 0px; text-align: left; color: #000000; font-size: 28px; font-family: 'urbanist-medium'; margin-bottom: 15px; line-height: 34px;}
p.st {font-size: 18px; font-family: 'urbanist-regular'; line-height: 24px; padding: 0px;}
.clr h3 { padding: 0px; font-family: 'urbanist-semibold'; font-size: 28px; margin: 5px 0px 15px 0px;}
p.apr {font-size: 14px;font-family: 'urbanist-regular';padding: 0px;}
p.apr span{font-family: 'urbanist-semibold';}
.bn {margin-top: 7px; border: 1px solid #000000; width: fit-content; padding: 4px 10px !important; font-size: 12px !important; font-family: 'urbanist-semibold' !important; border-radius: 5px !important;}
p.txt {width: 80%; font-size: 14px; padding: 0px; margin-top: 20px; font-family:'urbanist-regular'; line-height: 20px;}
p.txt span{ font-family: 'urbanist-semibold';}
.bg-dov {background: #F9F9F9; padding: 10px 25px; display: flex; align-items: center; gap: 15px; margin-bottom: 15px;}
.bg-dov p{font-size: 16px; font-family: 'urbanist-semibold'; line-height: 24px;}
.points {padding: 15px 25px; display: flex; gap: 15px;}
.tg p {font-size: 16px; font-family: 'urbanist-regular'; color: #000000;}
.ttip {display: flex; align-items: center; gap: 10px;}
.tg span {font-size: 16px; font-family: 'urbanist-semibold';}
.pl svg {padding-top: 3px; width: 18px; height: auto;}
.invoice{margin-top: 15px;}
.pl__cont .bt {text-align: center; margin: 15px 25px 30px 25px;}
.pl__cont .bt a{width: 100%; background: #000000; font-size: 18px; font-family: 'urbanist-semibold'; color: #FFFFFF; border-radius: 10px; text-transform: inherit; height: 52px}
.points.disabled svg {width: 15px;}
.points.disabled .pl {width: 18px; text-align: center;}
.points.disabled .tg p{font-family: 'urbanist-medium'; color: #999896;}
.plans-cont.desktop .col.three {position: relative;}
.plans-cont.desktop .col.three:after{content: ''; background: url(../../images/tag.svg); position: absolute; top: -30px; right: 30px; display: block; width: 60px; height: 80px;}
.topti {position:relative; display: flex;}
.top p {font-size: 12px; color: #FFFFFF;}
.topti .top {top: -5px; left: 0%; transform: translate(-45%,-100%); padding: 5px 10px; color: #ffffff; background-color: rgba(0,0,0,0.8); font-weight: normal;
font-size: 12px; border-radius: 5px; position: absolute; box-sizing: border-box; box-shadow: 0 1px 8px rgba(0,0,0,0.5); display: none;
min-width: 150px; max-width: 300px; text-align: center;}
.topti:hover .top {display:block;}
a:focus, a:focus-visible{outline: none; text-decoration: none;}
.tg.fbgtr {display: flex; gap: 3px;}


/* Section 2 Styling */
.busi-accont{padding: 100px 0px; background: #FFFFFF;}
.busi-accont h1{color: #000000; text-align: center; font-size: 40px; font-family: 'urbanist-medium'; line-height: 50px;}
.busi-accont .container {max-width: 1350px;}
.bat.row{margin: 0px; display: flex; flex-direction: row;}
.bat.row .col{width: 25%; flex: inherit; padding: 0px;}
.njO{height: 100%; display: flex; flex-direction: column; justify-content: end;}
.njO .nu{background: #000000; width: 100%; color: #FFFFFF; font-size: 20px; font-family: 'urbanist-semibold'; padding: 30px 24px;}
.buF {text-align: center;}
.buF a{font-size: 16px; font-family: 'urbanist-medium'; background: #000000; width: 100%; padding: 10px 13px; color: #FFFFFF; text-transform: none; letter-spacing: 0px;}
.buF a:hover{background: #000000; text-decoration: none;}
.buF h2 {text-align: center; margin-bottom: 25px;}
.gec {background: #F5F5F5; padding: 24px; border-bottom: 1px solid rgba(0,0,0,0.1);}
.crd-btn img {width: 75%; height: auto;}
.Cr_d {display: flex; padding-right: 30px;}
button.crd-btn.active { border: 1px solid rgba(0,0,0,0.1); border-radius: 6px; transition: 0.3s ease-in;}
button.crd-btn {width: 25%; height: 40px; display: flex; align-items: center; justify-content: center; background: none; border: none; padding: 0px; transition: 0.3s ease-in; border-radius: 6px; border-color: #F5F5F5;}
.gt-P img {height: 84px; width: auto; margin-top: 10px;}
.gt-P{padding: 24px; display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 193px; border-bottom: 1px solid rgba(0,0,0,0.1);}
.card__cont{display: none;}
.card__cont.active{display: block;}
.gt-P h3 {font-size: 16px; font-family: 'urbanist-semibold';line-height: 20px; margin-bottom: 5px;}
.gt-P p {font-size: 12px; font-family: 'urbanist-regular';line-height: 20px; margin-bottom: 5px;}
.gtf-P{width: fit-content; margin-top: 20px; border-bottom: 1px solid #000000; padding-bottom: 2px;}
.gtf-P a {font-size: 16px;font-family: 'urbanist-medium';color: #000000; width: fit-content; display: flex; transition: 0.1s ease-in; transform: translateX(0%); align-items: center; gap: 10px;}
.gtf-P a:hover svg{transform: translateX(50%); transition: 0.1s ease-in;}
.gec {padding: 24px; min-height: 193px; display: flex; flex-direction: column; justify-content: center;}
.gec .tg {margin-bottom: 20px;}
.grey-c{background: #F5F5F5 !important; }
.bgt--d {padding: 24px; border-bottom: 1px solid rgba(0,0,0,0.1);}
.bgt--d p, .gbd--k p{font-size: 16px; color: #000000;}
.bgt--d .top p{font-size: 12px !important; color: #FFFFFF !important;}
.gbd--k {display: flex; align-items: center; justify-content: center; height: 100%; border-bottom: 1px solid rgba(0,0,0,0.1);}
.erb {display: flex; align-items: center; gap: 10px;}




@media screen and (min-width: 900px) and (max-width: 1200px){
    .busi-plan h2{font-size: 34px; line-height: 40px;}
    .pl-btn{font-size: 14px;}
    .clr h2, .clr h3{font-size: 22px; line-height: 26px;}
    p.st, .bg-dov p{font-size: 15px; line-height: 16px;}
    p.apr{font-size: 12px;}
    p.txt{width: 100%; font-size: 13px;}
    .tg p, .tg span, .bgt--d p, .gbd--k p{font-size: 15px;}
    .njO .nu{font-size: 16px;}
}


.plan--mbl{display: none;}
@media screen and (max-width: 900px){
    .plan-dsktp{display: none;}
    .plan--mbl{display: block !important;}
    .busi-plan h2{font-size: 26px; line-height: 32px;}
    .plan-tabs{margin: 25px 0px;}
    .plan-tabs h3 {font-size: 26px; line-height: 34px; color: #FFFFFF; text-align: center; margin-bottom: 20px;}
    .pl-btn{font-size: 14px; padding: 8px 30px;}
    .wetb{background: #FFFFFF; padding-bottom: 30px; margin-bottom: 15px;}
    .pl__cont .bt{margin: 20px 0px 0px 0px;}
    .pl__cont .bt a{font-size: 16px; height: 48px; border-radius: 5px;}
    .vstT {padding: 24px;}
    .verb {margin: 20px 0px; text-align: center; height: 43px; display: flex; align-items: center; justify-content: center;}
    .verb p {font-size: 16px; font-family: 'urbanist-regular'; color: #000000;}
    .Cr_d{padding: 0px 15px; margin: 30px 0px !important;}
    button.crd-btn{height: 60px;}
    .gtf-P {width: 100%;border: 1px solid rgba(0,0,0,0.1);display: flex;align-items: center;justify-content: center;height: 44px;border-radius: 5px;}
    .gt-P{border: none;}
    .bt_dd {display: flex; align-items: center; padding: 24px; gap: 10px;}
    .bt_dd p {font-size: 16px; width: fit-content; color: #000000; font-family: 'urbanist-regular'; line-height: 24px;}
    .bt_dd .top p {color: #FFFFFF; font-size: 10px; line-height: 13px; text-align: center; width: 100%; padding: 5px 0px;}
    .verb.vew{margin: 0px 24px !important;}
    .bg-dov.grey-c {border: none;}
    .bg-dov{background: none; border-bottom: 1px solid rgba(0,0,0,0.1);}
    .bg-dov.invoice {margin: 0px 24px; padding: 30px 0px 20px 0px !important;}
}
