.trad__detiv {
    padding: 100px 0px;
    position: relative;
    margin-top: 2px;
}
.inn__vmm__az.row {
    margin: 0px;
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: center;
}
.inn__vmm__az.row .col {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.15);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 4px;
    flex: 0 0 21%;
    min-height: 324px;
    padding: 25px 20px;
    text-align: center;
}
.inn__vmm__az img {
    width: 80px;
    height: 80px;
}
.inn__vmm__az h3 {
    font-size: 22px;
    font-family: 'roboto-light';
    color: #FFFFFF;
    min-height: 22px;
    margin-bottom: 20px;
    margin-top: 25px;
}
.inn__vmm__az p {
    font-size: 16px;
    font-family: 'roboto-light';
    line-height: 22px;
    color: #B9B9BB;
    margin-bottom: 20px;
    text-align: left;
}
.inn__vmm__az strong{
    font-size: 16px;
    font-family: 'roboto-medium';
    line-height: 22px;
    color: #FFFFFF;
    font-weight: 400;
    text-align: left;
    display: block;
}
.inn__vmm__az.row .col:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 15px rgba(255,255,255,.5);
}
@media screen and (max-width: 800px){
    .trad__detiv .global__row strong{
        color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        display: block;
        text-align: center;
        min-height: 40px;
    }
    .trad__detiv .global__row p{
        min-height: 60px;
    }
    .row.inn__vmm__az{
        display: none;
    }
    .trad__detiv h1.fg__rer{
        margin-bottom: 20px !important;
    }
    .trad__detiv{
        padding: 50px 0px !important;
    }
}