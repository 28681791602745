.ind__banner{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689500938/ind__bg_c72nk0.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 100px 0px;
}
.ind__banner:before{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 2%;
    margin: 0 auto;
}
.ind__banner:after{
    content: '';
    width: 48%;
    height: 0.75px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);;
    display: block;
    position: absolute;
    top: 0;
    right: 2%;
    margin: 0 auto;
}
.row_ban{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
}
.row_ban .col {
    width: 50%;
    text-align: center;
    flex: 0 0 45%;
}
.col.banner_1{
    background: url(../../../images/ind_baner2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 500px;
    height: 275px;
}
.col.banner_2{
    background: url(../../../images/ind_baner1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 500px;
    height: 275px;
}
.ccc_tt {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 50%;
    padding: 40px 0px 40px 30px;
    justify-content: space-between;
    text-align: left;
}
.ccc_tt p{
    font-size: 22px;
    font-family: 'urbanist-regular';
    line-height: 30px;
    color: #FFFFFF;
}
.ccc_tt a {
    width: 135px;
    height: 44px;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 40px;
    box-shadow: 0px 0px 15px rgba(255,255,255,0.5);
    color: #FFFFFF;
    font-size: 16px;
    text-transform: capitalize;
    font-family: 'urbanist-light';
}
.ccc_tt a:hover{
    background: #FFFFFF;
    color: #000;
}
#indicies__page .row_ban .col:hover{
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.2);
}
.ccc_tt a:focus, .ccc_tt a:focus-visible{
    outline: none;
    text-decoration: none;
}