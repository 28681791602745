/************************** Body Fonts family Starts from here *************************/
@font-face {
    font-family: 'urbanist-medium';
    src: url(../fonts/Urbanist-Medium.ttf);
}
@font-face {
    font-family: 'urbanist-regular';
    src: url(../fonts/Urbanist-Regular.ttf);
}
@font-face {
    font-family: 'urbanist-light';
    src: url(../fonts/Urbanist-Light.ttf);
}
@font-face {
    font-family: 'urbanist-extralight';
    src: url(../fonts/Urbanist-ExtraLight.ttf);
}
@font-face {
    font-family: 'hauora-regular';
    src: url(../fonts/Hauora-Regular.ttf);
}
@font-face {
    font-family: 'urbanist-semibold';
    src: url(../fonts/Urbanist-SemiBold.ttf);
}
@font-face {
    font-family: 'hauora-light';
    src: url(../fonts/Hauora-Light.ttf);
}
@font-face {
    font-family: 'hauora-extralight';
    src: url(../fonts/Hauora-ExtraLight.ttf);
}
@font-face {
    font-family: "hauora-semibold";
    src: url(../fonts/Hauora-SemiBold.ttf);
}
@font-face {
    font-family: "hauora-medium";
    src: url(../fonts/Hauora-Medium.ttf);
}
@font-face {
    font-family: "poppins-regular";
    src: url(../fonts/Poppins-Regular.otf);
}
@font-face {
    font-family: "poppins-light";
    src: url(../fonts/Poppins-Light.otf);
}
@font-face {
    font-family: "poppins-medium";
    src: url(../fonts/Poppins-Medium.otf);
}
@font-face {
    font-family: "alliance-no-2-regular";
    src: url(../fonts/Alliance-no-2-regular.otf);
}
@font-face {
    font-family: 'roboto-regular';
    src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: 'roboto-regular';
    src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: 'roboto-light';
    src: url(../fonts/Roboto-Light.ttf);
}
@font-face {
    font-family: 'roboto-bold';
    src: url(../fonts/Roboto-Bold.ttf);
}
@font-face {
    font-family: 'roboto-medium';
    src: url(../fonts/Roboto-Medium.ttf);
}
/************************** Body Fonts family Ends from here *************************/

.body-overflow {
    overflow: scroll !important;
    overflow-x: hidden !important;
}
.ncx-blacklogo, .ncx-whitelogo{
    width: 122px !important;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
}
.sticky.sticky-fixed.is-sticky {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.sticky.sticky-fixed.is-sticky:not([style*="margin-top"]) {
    margin-top: 0 !important;
}
.sticky.sticky-fixed.is-sticky:not([style*="margin-bottom"]) {
    margin-bottom: 0 !important;
}
.sticky.sticky-fixed.is-absolute{
    position: absolute;
}

/************************** Body Scroll Section Starts from here *************************/
/* width */
::-webkit-scrollbar {
    width: 6px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 50px;  
}
/************************** Body Scroll Section Ends from here *************************/

section.bg-dark {
    background: #000000 !important;
}
.container{
    max-width: 1300px;
    margin: 0 auto;
    padding: 0px;
}
.container:before, .container:after{
    display: none;
}
h1, h2, h3, h4, h5, p{
    margin: 0px;
}
.appsttore svg:nth-child(3) {
    display: none;
}
.appsttore img:nth-child(3) {
    display: none;
}
.cryptopay svg:nth-child(1) {
    display: none;
}
.footer-row ul.ul-two li:nth-child(1), .footer-row ul.ul-two li:nth-child(3) {
    display: none;
}
#footerdiv > div.mobile-footer > div:nth-child(3) > ul:nth-child(2) > li:nth-child(2),
#footerdiv > div.mobile-footer > div:nth-child(3) > ul:nth-child(2) > li:nth-child(4){
    display: none;
}

/************************** HomePage Fourth Market & Exchange Section Starts from here *************************/
div#market .container {
    max-width: 1200px !important;
}
#market.market-exchange {
    background-color: #000000 !important;
    padding: 100px 0px 0px 0px !important;
    position: relative;
    margin-top: 1px;
}
div#market:before {
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
#market:after{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
#market .trending_markets {
    background: transparent !important;
    padding: 0px !important;
}
#market table#ncx_markets{
    background: #000000 !important;
}
#market #ncx_markets .hed th{
    color: rgba(255, 255, 255, 0.7) !important;
}
#market #market_table_body tr td h1{
    color: #FFFFFF;
}
#market #market_table_body tr td p{
    color: rgba(255, 255, 255, 0.7);
}
#market #market_table_body tr{
    border-bottom: 1px solid hsla(0,0%,100%,.1) !important;
    padding-top: 2px;
}
#market #market_table_body tr:hover{
    background: #000000;
    box-shadow: 0 0px 30px 0 rgba(255,255,255,.25);
    /* padding: 0px 5px !important; */
}
#market .featured_market {
    background: #101010 !important;
}
#market p.fet_tag{
    background: #5ED3F0;
}
#market .trend_pairs h1, #market .prix h3{
    color: #FFFFFF;
}
#market .trend_pairs p{
    color: #FFFFFF;
}
#market .prix p{
    color: rgba(255, 255, 255, 0.7);
}
#market #featured_slider, #market #featured_slider_mobile{
    background: transparent !important;
}
#market #featured_slider:before, #market #featured_slider:after{
    background: #101010;
}
#market .slider_market {
    text-align: left !important;
    justify-content: flex-start;
}
#market #featured_slider .slider_market .slide, #market #featured_slider_mobile .slider_market .slide{
    /* border-right: 10px solid #0E1F23 !important; */
    border-right: 10px solid #000000 !important;
}
#market .more_bt a{
    color: rgba(255, 255, 255, 0.7);
}
/* #market #featured_slider .slider_market .slide, #market #featured_slider_mobile .slider_market .slide{
    border-color: #000000 !important;
} */
.marketimg .col img {
    width: 100%;
    max-width: 320px;
}
.row.marketimg .col {
    max-width: 320px;
}
.row.marketimg {
    margin: 0px !important;
    padding: 0px 50px 30px 50px;
    display: flex;
    justify-content: center;
}
.viso__ff {
    min-height: 450px !important;
    max-height: 450px !important;
}
.gf__grthbh{
    min-height: 450px !important;
    max-height: 450px !important;
}
.viso__ff video {
    width: 100%;
    max-height: 450px;
}
.gf__grthbh video{
    max-height: 457px;
}
.bg-dark .trading__platform .ipad__gg {
    display: flex;
    justify-content: center;
    align-items: baseline;
    background-position: center top -100px;
    padding-top: 40px;
}
#bannss .col.banner_1:hover {
    box-shadow: 0 0 16px rgb(255 255 255 / 30%);
}
#bannss .col.banner_2:hover {
    box-shadow: 0 0 16px rgb(255 255 255 / 30%);
}
#bannss .row_ban p {
    text-align: left !important;
    font-size: 22px !important;
    line-height: 30px !important;
}
#bannss.ind__banner {
    background: #000000;
}


/************************** HomePage FAQ Section Starts from here *************************/
div#footer-faq {
    position: relative;
}
#faq.faq-sect .row {
    margin: 0px !important;
}
.faq-1 {
    width: 60% !important;
    padding: 0px !important;
}
.faq-0{
    width: 40% !important;
    padding: 0px !important;
}
#faq .asss h1 {
    padding: 10px 150px 30px 0px;
}
.faq-0 button {
    width: fit-content !important;
    font-size: 12px !important;
    padding: 0px 20px;
    font-family: 'urbanist-medium' !important;
    border-radius: 2px !important;
}
.faq-0 button svg {
    margin-left: 10px;
}
.faq-0 p {
    color: #FFFFFF !important;
    font-family: 'hauora-regular' !important;
    font-size: 15px;
    font-weight: 300 !important;
}
.faq-sect{
    /* background: url(../images/ncx-faq-bg.png); */
    background: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 120px 0px 80px 0px;
}


/* Other Page FAQ Starts from here */
#card-faqss details{
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 24px;
}
#card-faqss summary {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0px !important;
    border: none;
    font-family: "urbanist-semibold" !important;
    font-size: 18px;
    line-height: 26px;
}
#card-faqss span.red {
    background: transparent !important;
}
#card-faqss span.red svg{
    width: 10px !important;
    height: auto !important;
}
#card-faqss span.red svg path{
    fill: #000000 !important;
}
.contt{
    transition: all .4s cubic-bezier(1,1,1,1);
    max-height: 0;
    margin-bottom: 20px !important;
    overflow: hidden;
}
details[open] .contt {
    height: auto !important;
    margin-bottom: 20px !important;
    max-height: 9999px;
    padding-top: 20px!important;
    transition: all .4s cubic-bezier(1,1,1,1);
}
#card-faqss .accordian .contt p {
    color: #000;
    font-family: "urbanist-light" !important;
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px !important;
}
#card-faqss .contt ul{
    margin-top: 20px;
    padding-left: 0;
}
#card-faqss .contt ul li{
    font-family: "urbanist-light" !important;
    font-size: 16px!important;
    line-height: 24px;
    list-style-type: none!important;
    padding: 5px 0 5px 20px;
    position: relative;
}
#card-faqss .contt ul li:before{
    content: "-";
    left: 0;
    position: absolute;
}

.bg-dark .trading__platform .trg__ff__fe p{
    min-height: 63px !important;
}
.bg-dark .trading__platform .trg__ff__fe p span{
    display: block;
}
.bg-dark .trg__ff__fe .tiy__bd{
    margin-bottom: 40px !important;
}
/* NCX HOMEPAGE NCXCARD NEW SECTION */
/* ******************************************************** */
#card__new .pho__img img, #card__new .pla__img img{
    width: 100%;
    max-height: unset !important;
    max-width: 370px;
    border-radius: 27px;
}
#card__new .pla__img, #card__new .pho__img, #card__new .ipad__gg{
    min-height: 235px;
    max-height: 235px;
}
#card__new .trg__ff__fe p {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 30px;
    min-height: 38px;
}
#card__new .trg__ff__fe p span{
    display: block;
}
#card__new .pho__img img:hover, #card__new .pla__img img:hover, #card__new .ipad__gg img:hover{
    box-shadow: 0 0 16px rgb(255 255 255 / 30%);
}


/* NCX HOMEPAGE NCX VIDEO SECTION GENERATION */
/* ******************************************************** */
#hgff .new-generation {
    background: #000000 !important;
}
#hgff .new-generation h1, #hgff .new-generation p, #hgff .new-generation .text212 h4, #hgff .new-generation .taskdiv h3 {
    color: #FFFFFF;
}
#hgff .new-generation .taskdiv{
    background: #151515 !important;
}
#hgff .new-generation .text212 p{
    color: rgba(255,255,255, 0.5);
}
#hgff .new-generation .buttoninfo svg path{
    fill:rgba(255,255,255, 0.5);
}
#hgff .new-generation .taskdiv:hover{
    box-shadow: 0 0 16px rgb(255 255 255 / 30%);
}
#hgff .new-generation .buttoninfo a{
    border: 1px solid #5ED3F0 !important;
    background: transparent;
    color: #FFFFFF !important;
    font-family: 'urbanist-regular' !important;
}
#hgff .new-generation .buttoninfo a:hover{
    background: #5ED3F0 !important;
}
.earnncx .inner_d {
    display: none;
}
#hh__task .ncxtasksection {
    padding-top: 0px;
}
/* #hyres .earnncx {
    background: #000000;
    padding: 80px 0px 40px 0px;
} */
#hyres .ncxtasksection h1{
    color: #FFFFFF;
}
#hyres p.task, #hyres .tasktext h3{
    color: #FFFFFF;
}
#hyres p.task svg path{
    fill: #FFFFFF;
}
#hyres .taskbtn a{
    background: transparent;
    border: 1px solid #FFFFFF !important;
    font-size: 16px !important;
    color: #FFFFFF;
    font-family: 'urbanist-regular' !important;
}
.bg-dark #hyres .taskbtn a:hover{
    background: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid #FFFFFF !important;
    box-shadow: 0 0 10px rgba(255,255,255,0.5);
}
#hyres .taskbtn svg path, #hyres .tasktext-div svg path{
    fill: rgba(255, 255, 255, 0.5);
}
#hyres .tasktext p{
    color: rgba(255, 255, 255, 0.5);
}
#hyres .tasktext{
    background: #151515;
}
#hyres .ncxtasksection button.btn-primary{
    color: #FFFFFF;
}
#hyres .ncxtasksection button.btn-primary svg path{
    fill: #FFFFFF;
}
#hyres .tasktext:hover{
    box-shadow: 0 0 16px rgb(255 255 255 / 30%);
}
.bg-dark .colstake .color {
    background: rgba(31, 31, 31, 0.6);
    padding: 10px 10px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 0px;
}
.bg-dark #compare-cards .vwexx{
    padding: 22px 0px !important;
}
.uiop svg {
    width: 12px !important;
    height: auto !important;
}
.bg-dark #compare-cards .vwexx .qwrc span{
    margin-top: 0px !important;
    width: max-content;
}
.bg-dark #compare-cards .card__hrd .qwrc img{
    margin-bottom: 0px;
}
.bg-dark #compare-cards .card__bmf{
    padding-top: 0px !important;
}
.brtgh {
    position: sticky;
    top: -10px;
    z-index: 999;
    background: #000000;
}
/* NCX HOMEPAGE TOP DERIVATIVES ASSETS SECTION */
/* ******************************************************** */
.bg-dark .ind__cont {
    background: transparent !important;
    padding: 0px !important;
}
.bg-dark .inner__crypto{
    justify-content: center;
}
.bg-dark .ind__cont table tr{
    transition: all .3s;
    position: relative !important;
    min-height: 60px;
    border-bottom: 1px solid hsla(0,0%,100%,.1) !important;
    width: 100% !important;
    display: flex;
}
.bg-dark .ind__cont table tr:hover{
    background: #000000 !important;
    margin-top: -1px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-shadow: 0 0px 30px 0 rgba(255,255,255,.25);
}
.bg-dark .ind__cont table tr:hover td{
    color: #FFFFFF !important;
}





#home__ffgrr.ncxapp-section {
    padding: 0px !important;
    position: relative;
}
#home__ffgrr.ncxapp-section .row .col:first-child h1{
    color: #fff;
    font-size: 40px;
    line-height: 46px;
}
.ncxapp-section .row .col:first-child p {
    color: #fff;
    font-family: urbanist-light;
}
.apps-divi svg {
    height: auto;
    margin-bottom: 5px!important;
    width: 135px;
}
#home__ffgrr.ncxapp-section .barcodd {
    padding-top: 20px;
}
#home__ffgrr.ncxapp-section .home__btnff {
    padding-top: 20px;
    display: flex;
    column-gap: 15px;
}
#home__ffgrr.ncxapp-section #ncx_buycrypto_video video{
    max-height: unset !important;
}
a.sin__op {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    min-width: 135px;
    height: 44px;
    color: #000000;
    font-size: 16px;
    font-family: 'urbanist-light';
    text-transform: capitalize;
    box-shadow: 0 0 15px rgba(255,255,255,.5);
}
a.sin__op:focus, a.sin__op:focus-visible, a.sin__op:active,
a.cl__drp:focus, a.cl__drp:focus-visible, a.cl__drp:active{
    outline: none;
    text-decoration: none;
}
a.sin__op:hover{
    color: #FFFFFF;
}
a.cl__drp {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    height: 44px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'urbanist-light';
    text-transform: capitalize;
    min-width: fit-content;
    padding: 0 2rem;
    box-shadow: 0 0 15px rgba(255,255,255,.5);
}
a.cl__drp:hover{
    background: #FFFFFF;
    color: #000000;
    box-shadow: 0 0 15px rgba(255,255,255,.5);
}
#home__ffgrr.ncxapp-section .apps_barcod{
    min-width: 310px;
}
#home__ffgrr.ncxapp-section .apps-divi svg{
    display: block !important;
}
.bg-dark button.ind__tab.active{
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.bg-dark button.ind__tab:hover{
    border: 1px solid #FFFFFF !important;
}
.bg-dark button.crypto__tab.active, .bg-dark button.stock__tab.active{
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.bg-dark button.crypto__tab:hover, .bg-dark button.stock__tab:hover{
    border: 1px solid #FFFFFF !important;
}
.bg-dark .trading__platform .tiy__bd a.download{
    background: transparent;
    color: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border: 1px solid #FFFFFF;
}
.bg-dark .trading__platform .tiy__bd a.download:hover{
    background: #FFFFFF;
    color: #000000;
}
.bg-dark .trading__platform .tiy__bd a.sign__up:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.bg-dark .assets__ro.row .col:hover, .bg-dark .global__row.row .col:hover, .bg-dark .most_deri .col:hover{
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.bg-dark #hgff .new-generation .buttoninfo a{
    border: 1px solid #FFFFFF !important;
    color: #FFFFFF !important;
}
.bg-dark #hgff .new-generation .buttoninfo a:hover{
    border: 1px solid #FFFFFF !important;
    color: #000000 !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.plan--mbl.hompd .busi-plan{padding-bottom: 50px;}
.plan--mbl.hompd .busi-plan .pl__cont.active .wetb:last-child{margin-bottom: 0px;}
#home_coins, .trade__cfd, .plan--mbl.hompd .busi-plan{
    position: relative;
}
#home_coins:before, .trade__cfd:before, .trading__platform:before, .mrkt__cfds:before, .feature__dx:before,
.new-generation:before, .earnncx:before, .bg-dark #stake_ncxT:before, .trad__detiv:before, .plan--mbl.hompd .busi-plan:before {
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
#home_coins:after, .trade__cfd:after, .trading__platform:after, .mrkt__cfds:after, .feature__dx:after,
.new-generation:after, .earnncx:after, .bg-dark #stake_ncxT:after, .trad__detiv:after, .plan--mbl.hompd .busi-plan:after {
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
.bg-dark .tokentable-stake{
    padding-bottom: 0px !important;
}
#bannss.ind__banner div#single__part__row {
    margin: 0px;
    padding: 50px 0px 20px 0px;
}









.mbl__lpl{
    display: none;
}
.bbn__bm{
    display: none;
}













@media screen and (min-width: 801px) and (max-width: 1100px){
    .bg-dark .hom__ppo .apps_barcod h1 {
        font-size: 30px !important;
        line-height: 36px !important;
    }
    .bg-dark #home__ffgrr.ncxapp-section .apps_barcod {
        min-width: 280px;
    }
    .bg-dark .ncxapp-section .row .col:first-child{
        padding-top: 80px
    }
    .bg-dark #home__ffgrr.ncxapp-section #ncx_buycrypto_video video{
        min-height: 650px;
    }
}

    div#bgf__qas{
        display: none;
    }

@media screen and (max-width: 800px){
    .bg-dark .ind__cont{
        padding: 20px 0px 0px 0px !important;
    }
    #card-faqss summary {
        font-size: 16px!important;
        line-height: 20px!important;
        padding-right: 20px;
        font-family: "urbanist-regular" !important;
    }
    #market.market-exchange{
        background: #000000 !important;
        padding: 50px 0px 30px 0px !important;
    }
    #market .featured_market {
        background: #101010 !important;
    }
    #market #featured_slider .slider_market .slide, #market #featured_slider_mobile .slider_market .slide{
        margin: 0px !important;
        border-right: 10px solid #000000 !important;
    }
    #market table#ncx_markets {
        margin-top: 0px;
    }
    #market #featured_slider_mobile:before, #market #featured_slider_mobile:after{
        min-height: 93px !important;
        max-height: 93px !important;
        background: #101010 !important;
    }
    .bg-dark .hom__ppo .mbl__lpl{
        padding: 0px !important;
    }
    .gsfersdsd {
        padding: 20px 20px 10px 20px;
        text-align: center;
    }
    .mbl__lpl{
        display: block;
    }
    #home__ffgrr{
        display: none;
    }
    .mbl__lpl video {
        width: 100%;
        min-height: 240px;
        object-position: top;
    }
    .mbl__lpl {
        padding: 50px 20px 0px 20px;
    }
    .mbl__lpl h1 {
        font-size: 24px;
        line-height: 32px;
        font-family: 'urbanist-regular';
        color: #FFFFFF;
        margin-bottom: 5px;
    }
    .mbl__lpl p {
        font-size: 16px;
        line-height: 22px;
        font-family: 'urbanist-light';
        color: #FFFFFF;
        margin-bottom: 20px;
    }
    /* .mbl__lpl p span{
        display: block;
    } */
    .yy__tr span {
        background: rgba(33, 33, 33, 0.8);
        border-radius: 50px;
        padding: 5px 10px;
        font-size: 12px;
        color: #FFFFFF;
        font-family: 'urbanist-regular';
    }
    .yy__tr{
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
    }
    .mbl__lpl .home__btnff {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 30px 0px;
        padding: 0px 20px !important;
    }
    #wallet__main__cc .mbl__lpl{
        padding-top: 0px !important;
    }
   #wallet__main__cc .mbl__lpl h1{
        text-align: center !important;
   }
   #wallet__main__cc .mbl__lpl p{
        text-align: center !important;
   }
    #hme__gg__ff h1.fg__rer {
        display: none;
    }
    .bg-dark #hme__gg__ff.trade__derivative{
        padding: 50px 0px !important;
        display: none !important;
    }
    .bg-dark .trading__platform .plat__ggre.row .col{
        padding-top: 40px !important;
    }
    .mrkt__cfds, .feature__dx{
        padding: 50px 0px !important;
    }
    .bg-dark .trading__platform .ipad__gg{
        background: transparent !important;
    }
    .bg-dark .trading__platform .ipad__gg img{
        max-width: 250px !important;
    }
    #home_coins.wallet-slider h1 {
        font-size: 20px !important;
        line-height: 26px !important;
    }
    #home_coins p.gjg_gf {
        font-size: 12px !important;
        margin: 30px 0px 20px 0px !important;
    }
    #home_coins .coin__tb a.lnch_dx, #home_coins .coin__tb a.dx_drp{
        width: 120px !important;
        height: 36px !important;
        font-size: 14px !important;
        padding: unset !important;
    }
    .mbl__lpl .home__btnff a.sin__op{
        width: 110px !important;
        height: 36px !important;
        font-size: 14px !important;
        min-width: 110px !important;
        background: transparent !important;
        color: #FFFFFF !important;
    }
    .mbl__lpl .home__btnff a.sin__op:hover{
        background: #FFFFFF !important;
        color: #000000 !important;
    }
    /* .bg-dark .trading__platform .tiy__bd a.download{
        background: transparent;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
    }
    .bg-dark .trading__platform .tiy__bd a.download:hover{
        background: #FFFFFF;
        color: #000000;
        border: 1px solid #FFFFFF;
    } */
    .mbl__lpl .home__btnff a.cl__drp{
        height: 36px !important;
        font-size: 14px !important;
        border: 1px solid #000000 !important;
        box-shadow: none !important;
    }
    .mbl__lpl .home__btnff a.cl__drp:hover{
        border: 1px solid #FFFFFF !important;
        background: transparent !important;
        color: #FFFFFF !important;
        box-shadow: 0 0 15px rgba(255,255,255,.5) !important;
    }
    .trade__cfd .ind__cont table tr {
        padding: 15px 10px !important;
    }
    #home_coins.wallet-slider{
        padding: 50px 0px 50px 0px !important;
        margin-top: 0px !important;
    }
    #card__new .trg__ff__fe p{
        width: 100% !important;
    }
    #card__new .pho__img img, #card__new .pla__img img{
        max-width: 280px !important;
    }
    #card__new .trg__ff__fe p span {
        display: initial;
    }
    .bg-dark .global__mart .global__row.row {
        flex-flow: column;
    }
    .bg-dark .global__row img {
        height: 70px;
        width: 70px;
    }
    .bg-dark .global__row h2 {
        font-size: 18px!important;
        line-height: 28px!important;
        margin-bottom: 10px;
        margin-top: 30px;
        min-height: 0!important;
        min-height: auto!important;
    }
    .bg-dark .global__row p {
        font-size: 14px;
        line-height: 20px;
    }
    .bg-dark .global__row.row .col {
        min-height: 0;
        min-height: auto;
    }
    #bannss.ind__banner h1 {
        font-size: 24px !important;
        line-height: 30px !important;
        font-family: 'urbanist-regular' !important;
    }
    #bannss.ind__banner p {
        font-size: 14px !important;
        line-height: 20px !important;
        padding-top: 5px !important;
    }
    #bannss a.byyy, #bannss a.marr {
        width: 120px;
        height: 36px;
        font-size: 14px;
    }
    #bannss .btn__dtr{
        gap: 10px;
    }
    #bannss .row_ban .col{
        flex: 0 0 100%;
        max-height: 200px;
        min-height: 200px;
        width: 100%;
    }
    #bannss .col.banner_1 {
        margin-bottom: 30px;
    }
    #bannss .ccc_tt {
        height: 200px!important;
        padding: 25px 0 25px 25px!important;
    }
    #bannss .ccc_tt p {
        font-size: 16px !important;
        line-height: 22px !important;
    }
    #bannss .ccc_tt a {
        font-size: 14px !important;
        height: 36px !important;
        width: 110px !important;
    }
    .bg-dark .trading__platform{
        padding: 50px 0px 50px 0px !important;
    }
    #home_coins .coin__tb{
        gap: 10px !important;
    }
    .bg-dark #card__new.trading__platform {
        padding: 50px 0px 50px 0px !important;
    }
    .dex-table svg {
        width: 12px;
    }
    .black__card {
        order: 2;
    }
    .gold__card {
        order: 1;
    }
    #marketmakers_page .market-discount {
        padding-top: 0px !important;
    }
    .bg-dark .trading__platform .tiy__bd a.sign__up{
        min-width: 110px !important;
        width: unset !important;
    }
    .brtgh {
        display: none;
    }
    .bg-dark .global__mart{
        margin-top: 0px;
        padding: 50px 0px !important;
    }
    #bannss.ind__banner{
        display: none;
        padding: 50px 0px !important;
    }
    .bbn__bm #bannss.ind__banner, .bbn__bm{
        display: block !important;
    }
    #hgff .new-generation .container {
        display: flex;
        flex-direction: column;
    }
    #hgff .new-generation .container .generate-div{
        order: 2;
        margin-top: 30px;
    }
    #hgff .new-generation .container .generate-video{
        order: 1;
        margin-top: 0px !important;
    }
    #hgff .new-generation{
        padding: 50px 0px 0px 0px !important;
    }
    .mb__partner{
        padding-top: 50px !important;
    }
    .hide_mbl{
        display: none;
    }
    #bannss a.byyy{
        background: transparent;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
    }
    #bannss a.byyy:hover{
        background: #FFFFFF !important;
        color: #000000;
    }
    .card_box.nrtbv__gerg{
        margin-top: 1px;
    }
    .bg-dark .trading__platform .trg__ff__fe p{
        min-height: unset !important;
    }
    .earingingcomplet-sect{padding: 50px 0px !important;}
    p.task{margin-top: 20px !important;}
}
.uiop.tick svg{
    width: 18px !important;
    height: 18px !important;
}
details summary::-webkit-details-marker {
    display:none !important;
}