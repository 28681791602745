.row.vsds {
    margin: 0px;
    display: flex;
}
.col.vedc {
    flex: 0 0 50%;
    padding: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.col.vedc img {
    width: 100%;
    max-height: 550px;
}
.col.vedc h1, .vwed h1 {
    font-size: 40px;
    line-height: 48px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    width: 70%;
}
.card_box, .card_exprnc, .aerv, .berfvc, .range_cards, .integr__pla{
    background: #000000;
    padding: 100px 0px;
    position: relative;
}
.slvs{
    background: #000000;
    padding: 100px 0px 100px 0px;
    position: relative;
}
.col.vedc ul{
    margin-bottom: 0px;
    margin-top: 32px;
    padding: 0px 200px 0px 0px;
}
.col.vedc ul li {
    font-size: 18px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    line-height: 24px;
    font-weight: 400;
    list-style-type: none;
    margin-bottom: 16px;
    position: relative;
    padding-left: 36px;
}
a.vea_vv{
    width: 320px;
    height: 50px;
    background: #F9F9F7;
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1B;
    text-transform: initial;
    margin-top: 16px;
    letter-spacing: 0px;
    font-family: 'urbanist-medium';
}
.col.vedc ul li:before{
    content: '';
    background: url(../../../images/tickvector.svg);
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    display: block;
    position: absolute;
    left: 0px;
    top: 4px;
    background-size: 100%;
}
a.vea_vv:hover{
    background: #F9F9F7;
    color: #1D1D1B;
}
a.vea_vv:focus, a.vea_vv:hover, a.vea_vv:focus-visible{
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
.vwed h1{
    margin: 0 auto;
    text-align: center;
}
.vwed p {
    font-size: 18px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
}
.bewec a.wec {
    width: 200px;
    height: 50px;
    background: #F9F9F7;
    border-radius: 50px;
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1B;
    text-transform: inherit;
    letter-spacing: 0px;
    font-family: 'urbanist-medium';
    border: 1px solid #F9F9F7;
}
.bewec a.wec:hover{
    background: transparent;
    border: 1px solid #F9F9F7;
    color: #F9F9F7;
}
.bewec a.dwc {
    width: 200px;
    height: 50px;
    border-radius: 50px;
    font-size: 16px;
    line-height: 24px;
    color: #F9F9F7;
    text-transform: inherit;
    letter-spacing: 0px;
    font-family: 'urbanist-medium';
    border: 1px solid #F9F9F7;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
.bewec a.dwc:hover{
    background: #F9F9F7;
    color: #1D1D1B;
}
.bewec a.dwc:focus, .bewec a.dwc:focus-visible, .bewec a.wec:focus, .bewec a.wec:focus-visible{
    outline: none;
    box-shadow: none;
    text-decoration: none;
}
.bewec {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 40px;
}
.card_exprnc, .aerv, .slvs, .berfvc, .range_cards, .cont_vwef, .integr__pla, .bvc_mnb{
    position: relative;
}
.card_exprnc:after, .aerv:after, .slvs:after, .berfvc:after, .range_cards:after, .cont_vwef:after, .integr__pla:after, .card_box:after{
    /* content: '';
    background: rgba(255,255,255,0.25);
    width: -webkit-fill-available;
    height: 1px;
    display: flex;
    position: absolute;
    max-width: 1300px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; */
    content: '';
    width: 48.05%;
    height: 0.75px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    right: 2%;
    margin: 0 auto;
}
.card_exprnc:before, .aerv:before, .slvs:before, .berfvc:before, .range_cards:before, .cont_vwef:before,
.integr__pla:before, .card_box:before{
    content: '';
    width: 48.05%;
    height: 0.75px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    display: block;
    position: absolute;
    top: 0;
    left: 2%;
    margin: 0 auto;
}
.row.aeewf {
    margin: 0px;
}
.row.aeewf .col {
    padding: 0px;
}
.col.verhnb h1{
    font-size: 40px;
    line-height: 58px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
}
.col.vwev summary {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'urbanist-light' !important;
    line-height: 32px;
}
.col.vwev details .contt p{
    font-family: 'urbanist-light' !important;
}
.col.vwev details:nth-child(1) summary{
    padding-top: 10px !important;
}
.col.vwev details .contt p {
    color: #FFFFFF;
}
.col.vwev details[open] .contt{
    border-bottom: 1px solid rgba(255,255,255,0.25);
    padding-bottom: 15px;
    padding-top: 0px !important;
}
.col.vwev .accordian details {
    border-bottom: 1px solid rgba(255,255,255,0.25);
}
.col.vwev .accordian details[open] {
    border: none;
}
.col.vwev .accordian details svg {
    transition: all 0.5s;
    width: 13px;
    height: auto;
}
.col.vwev details[open] svg {
    transform: rotate(180deg);
    transition: all 0.7s;
}
.col.vwev .accordian details summary{
    padding: 32px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.slvs .carousel .csver .carousel__inner-slide img {
    max-height: 40px;
    width: fit-content;
    max-width: 140px;
}
.slvs .carousel__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px !important;
}
.tvscc h1 {
    color: #FFFFFF;
    text-align: center;
    font-size: 40px;
    font-family: 'urbanist-medium';
    line-height: 48px;
    margin-bottom: 30px;
}
.tvscc p{
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    font-family: 'urbanist-light';
    line-height: 26px;
    margin-bottom: 30px;
}
.row.vsdvs {
    margin: 0px !important;
    margin-top: 60px !important;
    display: flex;
    justify-content: space-between;
}
.row.vsdvs .col.btge {
    flex: inherit;
    width: 33.3333%;
    padding: 0px;
    text-align: left;
}
.row.vsdvs .col.btge h3 {
    margin-top: 30px;
    margin-bottom: 16px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 24px;
    line-height: 32px;
}
.row.vsdvs .col.btge p {
    color: #FFFFFF;
    font-family: 'urbanist-light';
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin: 0px !important;
}
.row.vsdvs .col.btge:nth-child(1){
    padding-right: 60px;
}
.row.vsdvs .col.btge:nth-child(2){
    padding: 0px 60px;
    border-left: 1px solid rgba(255,255,255,0.25);
    border-right: 1px solid rgba(255,255,255,0.25);
}
.row.vsdvs .col.btge:nth-child(3){
    padding-left: 60px
}

.csdvw__vew .col.vedc img {
    max-width: 600px;
}
.card_box.csdvw__vew .col.vedc:nth-child(2) {
    padding-left: 100px;
}
.card_box.csdvw__vew .col.vedc:nth-child(2) ul {
    padding: 0px;
}
.card_box .col.vedc h1 span {
    display: block;
}
.vwecx {
    display: flex;
    align-items: center !important;
    gap: 15px;
    margin-top: 32px;
}
.vwecx a.vea_vv {
    width: 170px;
    margin-top: 0px;
    border: 1px solid #F9F9F7;
}
a.evc {
    color: #F9F9F7;
    font-size: 16px;
    font-family: 'urbanist-medium';
    text-transform: capitalize;
    line-height: 24px;
    height: 50px;
    display: flex;
    width: 220px;
    border: 1px solid #f9f9f7;
    border-radius: 6px;
}
a.evc:hover, a.evc:focus, a.evc:focus-visible{
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
a.evc:hover{
    background: #F9F9F7;
    color: #1D1D1B;
}
.vwecx a.vea_vv:hover{
    background: transparent;
    color: #F9F9F7;
    border: 1px solid #F9F9F7;
}
.qwrc img {
    height: 110px;
    margin-bottom: 40px;
    width: auto;
}
.vwexx {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    border-bottom: 1px solid rgba(255,255,255,0.25);
}
.vwexx:nth-child(1){
    border: none;
    padding: 0px !important;
}
.qwrc {
    width: 200px;
    text-align: center;
}
.qwrc.fcisc {
    text-align: left;
}
.qwrc p {
    font-size: 16px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    font-weight: 400;
}
.qwrc span{
    color: rgba(255,255,255,0.60);
    font-family: 'urbanist-regular';
    margin-top: 10px;
    display: block;
}
p.cwee {
    font-family: 'urbanist-regular';
    font-size: 14px;
    margin-top: 10px;
}
.vwexx:last-child{
    border: none !important;
}
h1.rnag_gg {
    text-align: center;
    color: #FFFFFF;
    font-size: 32px;
    font-family: 'urbanist-regular';
    line-height: 40px;
    margin-bottom: 60px;
}
.berv_vwe {
    background: #000000;
    position: relative;
}
.bf_vdo video {
    width: 100%;
    max-height: 700px;
}
.bf_vdo {
    max-height: 700px;
}
.cont_vwef {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 700px;
    padding: 150px 0px;
}
.bve {
    width: 50%;
}
.bve h1 {
    font-size: 42px;
    color: #FFFFFF;
    font-family: 'urbanist-medium';
    line-height: 48px;
    padding-right: 200px;
}
.bve p {
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    line-height: 24px;
    padding-right: 200px;
    margin-top: 30px;
}
.bve .bewec {
    justify-content: left;
}
.bve .bewec a.wec{
    width: 190px !important;
}
.bve .bewec a.dwc{
    width: 160px !important;
}
.col.btge img {
    width: auto;
    height: 220px;
}
.nhy__tr {
    display: flex;
}
.mujh {
    width: 40%;
}
.tjmnh{
    width: 60%;
    padding-left: 60px
}
.mujh img {
    width: 100%;
}
.tjmnh h1 {
    font-size: 40px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    margin-bottom: 20px;
}
.hyf__gg {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    justify-content: space-between;
    margin: 0;
}
.hyf__gg .fvdd {
    flex: 0 0 48%;
    padding: 0px 30px;
    border-left: 1px solid rgba(255,255,255,0.25);
    margin-top: 40px;
}
.hyf__gg .fvdd h3 {
    font-size: 24px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 32px;
}
.hyf__gg .fvdd p {
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    line-height: 24px;
    margin-top: 15px;
}
.mujh video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* CardPage First New Section */
.new_cverd header#header-row {
    background: transparent !important;
    background-color: transparent !important;
}
.new_cverd header#header-row.darkheader {
    background: #000000 !important;
}
.new__crf {
    margin-top: -56px;
    max-height: 922px;
    position: relative;
}
.bgt_hyn{
    max-height: 860px;
}
.bgt_hyn video {
    width: 100%;
    max-height: 860px;
    object-fit: cover;
    object-position: center center;
}
.bvc_mnb {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    height: -webkit-fill-available;
}
.bvc_mnb .container {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
}
.bvc_mnb .container h1 {
    font-size: 46px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    font-weight: 400;
}
a.bnb_mki {
    width: 160px;
    height: 52px;
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid #F9F9F7;
    backdrop-filter: blur(10px);
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-transform: initial;
    font-family: 'urbanist-medium';
    /* box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5); */
}
a.bnb_mki:hover, a.bnb_mki:focus, a.bnb_mki:focus-visible, a.mki__lo:hover, a.mki__lo:focus, a.mki__lo:focus-visible{
    outline: none;
    box-shadow: none;
    text-decoration: none;
}
a.bnb_mki:hover, a.mki__lo:hover{
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: #000000;
}
a.mki__lo {
    width: 140px;
    height: 52px;
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid #F9F9F7;
    backdrop-filter: blur(10px);
    font-size: 16px;
    line-height: 24px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    text-transform: inherit;
    letter-spacing: 0;
    border-radius: 50px;
    /* box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5); */
}
.hyng {
    gap: 16px;
    display: flex;
}

/* Premium Experience Section styling */
.ncx__card__page .global__row h2 {
    min-height: unset;
}
.ncx__card__page .global__row.row .col:hover {
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 15px rgba(255,255,255,.75);
}
h1.az__ml__mm {
    text-align: center;
    font-size: 32px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    margin-bottom: 50px;
}
.global__mart.ncx__card__page {
    margin-top: 0px;
    padding: 100px 0px !important;
}
.mbl__sec__cr{
    display: none;
}
h2.za__uj{
    display: none;
}




@media screen and (max-width: 1550px){
    .bvc_mnb .container h1{
        font-size: 40px;
    }
    a.bnb_mki{
        width: 150px;
        height: 48px;
    }
    a.mki__lo{
        width: 120px;
        height: 48px;
    }
    .hyng{
        gap: 14px;
    }
}
@media screen and (max-width: 1440px){
    .bgt_hyn video, .bgt_hyn, .new__crf{
        max-height: 850px;
    }
    .bgt_hyn video{
        object-fit: contain;
    }
    .bvc_mnb .container{
        gap: 20px;
    }
}
@media screen and (max-width: 1200px){
    .bvc_mnb .container h1{
        font-size: 32px;
    }
    .bvc_mnb .container{
        gap: 15px;
    }
    a.bnb_mki{
        width: 130px;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
    }
    a.mki__lo{
        width: 100px;
        height: 40px;
        font-size: 14px;
        line-height: 20px;
    }
}


@media screen and (min-width: 800px){
    .berv_vwe{
        display: none;
    }
}






/* Responsiveness Starts from here */
.new_cverd {
    background: #000000;
}
.inservv.mobile{
    display: none;
}
img.mbL_fwc{
    display: none;
}
.card_rnag_mbl{
    display: none;
}
.mbdx_v {
    display: none;
}
.mbl_vevrf{
    display: none;
}
@media screen and (max-width: 1300px){
    .col.vedc h1, .vwed h1{
        width: 100%;
    }
    .col.vedc ul{
        padding: 0px;
    }
}
@media screen and (max-width: 1180px){
    .col.btge img {
        width: 100%;
        height: 200px;
    }
    .row.vsdvs .col.btge:nth-child(2){
        padding: 0px 30px;
    }
    .card_box.csdvw__vew .col.vedc:nth-child(2) {
        padding-left: 50px;
    }
    .bve p, .bve h1{
        padding-right: 0px;
    }
    .col.vedc h1, .vwed h1{
        width: 100%;
    }
    .row.vsdvs .col.btge:nth-child(1){
        padding-right: 30px;
    }
    .row.vsdvs .col.btge:nth-child(3){
        padding-left: 30px;
    }
    .slvs .carousel .csver .carousel__inner-slide img{
        max-width: 90px;
    }
    .col.vedc ul{
        padding: 0px;
    }
}
@media screen and (max-width: 800px){
    .bf_vdo{
        max-height: 220px;
    }
    .cont_vwef{
        max-height: inherit;
        position: relative;
        padding: 50px 0px;
        height: unset !important;
    }
    .bve{
        width: 100%;
    }
    .bve h1{
        display: none;
        font-size: 26px;
        line-height: 34px;
    }
    .bve p{
        margin-top: 15px;
        font-size: 16px;
        line-height: 24px;
    }
    .cont_vwef .vcsd .bewec a {
        width: 150px !important;
        font-size: 14px !important;
        height: 40px !important;
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    }
    .bve .bewec{
        justify-content: center;
        gap: 15px !important;
        margin-top: 30px;
    }
    .card_exprnc .container:after, .aerv .container:after, .slvs .inservv:after,
    .berfvc .container:after, .range_cards .container:after, .cont_vwef .container:after, .bvc_mnb .container:after{
        margin: 0px 20px !important;
    }
    .card_box, .card_exprnc, .aerv, .slvs, .berfvc, .range_cards, .integr__pla{
        padding: 50px 0px;
    }
    .row.vsds{
        flex-direction: column;
    }
    .col.vedc{
        width: 100%;
        flex: 0 0 100%;
    }
    .card_box.csdvw__vew .col.vedc:nth-child(2){
        padding-left: 0px;
        margin-top: 40px;
    }
    .col.vedc h1, .vwed h1, .tvscc h1, h1.rnag_gg, .col.verhnb h1, .tjmnh h1{
        font-size: 26px;
        line-height: 32px;
        font-family: 'urbanist-regular' !important;
    }
    .col.vedc ul li:before{
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
    }
    .col.vedc ul li{
        font-size: 15px;
        line-height: 20px;
        padding-left: 25px;
    }
    .vwecx a.vea_vv{
        font-size: 14px !important;
        width: 150px !important;
        height: 40px !important;
    }
    a.evc{
        font-size: 14px !important;
        width: 170px !important;
        height: 40px !important;
    }
    .vwecx{
        justify-content: center;
        gap: 15px !important;
    }
    .row.vsdvs{
        flex-direction: column;
    }
    .row.vsdvs .col.btge{
        width: 100% !important;
        padding: 0px !important;
    }
    .col.btge img{
        height: unset !important;
        width: 80%;
        margin: 0 auto;
        display: block;
    }
    .row.vsdvs .col.btge h3{
        font-size: 22px !important;
        line-height: 26px !important;
        margin-bottom: 10px !important;
    }
    .row.vsdvs .col.btge p{
        font-size: 15px !important;
        font-family: 'urbanist-light';
        line-height: 22px;
    }
    .row.vsdvs .col.btge:nth-child(2){
        border-left: none;
        border-right: none;
        margin: 40px 0px;
        padding: 40px 0px !important;
        border-top: 1px solid rgba(255,255,255,0.25);
        border-bottom: 1px solid rgba(255,255,255,0.25);
    }
    .card_bt{
        display: none;
    }
    .tvscc p{
        font-size: 15px;
        line-height: 22px;
    }
    .tvscc h1{
        margin-bottom: 10px;
    }
    a.vea_vv{
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
    .vwed p{
        width: 100%;
        font-size: 15px;
        line-height: 22px;
        margin-top: 10px;
    }
    .bewec a.wec{
        width: 170px;
        height: 40px;
        font-size: 14px;
    }
    .bewec a.dwc{
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
    .bewec{
        gap: 15px !important;
    }
    .row.aeewf{
        flex-direction: column;
    }
    .col.vwev .accordian details summary {
        font-size: 16px;
        line-height: 24px !important;
    }
    .col.vwev details .contt p{
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .col.vwev details[open] .contt{
        margin-bottom: 0px !important;
    }
    .row.aeewf .col.vwev .accordian {
        margin-top: 40px;
    }
    .col.vwev .accordian details:last-child{
        border-bottom: none !important;
    }
    .inservv.desktop{
        display: none;
    }
    .inservv.mobile{
        display: block;
    }
    img.mbL_fwc{
        display: block;
    }
    .bmld_we{
        display: none !important;
    }
    .card_rnag_mbl{
        display: block;
        position: relative;
    }
    .yuio .qwrc {
        width: 100%;
    }
    .yuio .qwrc img {
        height: 160px;
        margin-bottom: 10px;
        width: auto !important;
    }
    button.ertgb, button.mht {
        background: none;
        border: none;
        padding: 0px;
    }
    button.mht:disabled, button.ertgb:disabled {
        opacity: 0.5;
    }
    .cscs__bt {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: 50px;
        z-index: 1;
    }
    .ytrew {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px 0px 0px;
    }
    .vyth p {
        font-size: 16px;
        font-family: 'urbanist-medium';
        text-align: left;
        color: #FFF;
        margin-bottom: 10px;
        line-height: 20px;
    }
    .vyth span {
        font-size: 14px;
        color: rgba(255,255,255,0.6);
        font-family: 'urbanist-regular';
    }
    .tujhg p {
        text-align: end;
        color: #FFFFFF;
        font-size: 16px;
        font-family: 'urbanist-semibold';
        line-height: 20px !important;
    }
    .tujhg span.cwee {
        color: #FFFFFF;
        margin-top: 10px;
        display: block;
        font-size: 14px;
        font-family: 'urbanist-regular';
    }
    .vyth {
        width: 60%;
    }
    .tujhg {
        width: 40%;
        text-align: end;
    }
    .nhy__tr{
        flex-direction: column;
    }
    .mujh, .tjmnh{
        width: 100%;
    }
    .tjmnh{
        padding: 0px;
        margin-top: 40px;
    }
    .hyf__gg .fvdd{
        flex: 0 0 100%;
        border-left: none;
        padding: 0px;
        border-bottom: 1px solid rgba(255,255,255,0.25);
        padding-bottom: 40px !important;
        margin-bottom: 40px !important;
        margin-top: 0px;
    }
    .hyf__gg .fvdd h3{
        font-size: 22px;
        line-height: 26px;
    }
    .hyf__gg .fvdd p{
        font-size: 15px;
        line-height: 22px;
        margin-top: 10px;
    }
    .hyf__gg{
        gap: 0px;
        margin-top: 40px;
    }
    .hyf__gg .fvdd:last-child{
        padding: 0px !important;
        border: none !important;
        margin: 0px !important;
    }
    .col.btge svg, .col.btge img {
        display: none;
    }
    .row.vsdvs .col.btge h3{
        margin-top: 0px !important;
    }
    .mbdx_v {
        display: block;
    }
    .card_exprnc{
        display: none;
    }
    .slvs {
        margin: 2px 0px;
    }
    .mbdx_v .card_exprnc{
        display: block !important;
    }
    .mbl_vevrf, .mbl_vevrf .slvs{
        display: block !important;
    }
    .berv_vwe{
        display: block;
    }
    .new__crf{
        display: none;
    }
    .card_rnag_mbl .carousel__slider {
        min-height: 900px !important;
    }
    .card_rnag_mbl .carousel__slider .carousel__slide {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    h1.az__ml__mm {
        font-size: 26px;
        line-height: 32px;
        padding: 0px 30px;
        font-family: 'urbanist-regular' !important;
        margin-bottom: 20px !important;
    }
    h1.az__ml__mm span{
        display: block;
    }
    .mbl__sec__cr{
        display: block;
    }
    .global__mart.ncx__card__page .global__row.row {
        display: none;
    }
    .azx img {
        width: 80px !important;
        height: 80px !important;
        margin-bottom: 25px;
    }
    .azx {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.15);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        border-radius: 4px;
        padding: 25px 25px 30px 25px;
        text-align: center;
    }
    .azx:hover{
        border: 1px solid #FFFFFF;
        box-shadow: 0 0 15px rgba(255,255,255,.75);
    }
    .global__mart.ncx__card__page .carousel__slide{
        padding: 15px 30px !important;
    }
    .azx h2{
        font-size: 22px;
        font-family: 'roboto-light';
        color: #FFFFFF;
        margin-bottom: 20px;
    }
    .azx p{
        color: #B9B9BB;
        font-size: 16px;
        line-height: 22px;
        font-family: 'roboto-light';
        min-height: 132px;
    }
    .global__mart.ncx__card__page{
        padding: 50px 0px !important;
    }
    .global__mart.ncx__card__page .carousel__dot-group {
        text-align: center;
    }
    .global__mart.ncx__card__page button.carousel__dot--selected {
        width: 40px !important;
        background: #FFFFFF !important;
        border: 2px solid #FFFFFF !important;
        border-radius: 50px !important;
    }
    .global__mart.ncx__card__page button.carousel__dot--selected span{
        background: #FFFFFF;
    }
    .global__mart.ncx__card__page .carousel__dot-group {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 20px;
    }
    .global__mart.ncx__card__page button.carousel__dot {
        width: 7px;
        border: none;
        background: rgba(255,255,255,0.5);
        border-radius: 50px;
        padding: 0px;
        height: 4px;
    }
    .global__mart.ncx__card__page button.carousel__dot:hover{
        width: 20px;
        background: rgba(255,255,255,0.5);
        transition: all 0.3s;
    }
    .card_box .col.vedc h1 {
        text-align: center;
    }
    h2.za__uj{
        display: block;
        text-align: center;
        color: #FFFFFF;
        font-size: 26px;
        font-family: 'urbanist-regular';
        line-height: 36px;
    }
    a.wec {
        display: none;
    }
    .bf_vdo video{
        height: 220px;
        object-fit: cover;
    }
    .cont_vwef:before, .cont_vwef:after{
        display: none;
    }
    .new_cverd .csdvw__vew .container:before{
        content: "" !important;
        display: block;
        height: 0.75px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 48%;
        background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
        left: 2.1%;
    }
    .new_cverd .csdvw__vew .container:after{
        content: "" !important;
        display: block;
        height: 0.75px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 48%;
        background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
        right: 2.1%;
    }
    .card_box.csdvw__vew{
        position: relative;
    }
}



/* Homepage CSSS */
section.bg-dark .range_cards .container:after {
    display: none;
}