div#card-faqss {
    padding-bottom: 100px;
}
#card-faqss .accordian {
    margin: 60px 80px 0px;
}
#card-faqss .accordian h2 {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: 'urbanist-semibold' !important;
    text-align: left;
}
.freq-ask-ques{
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    font-family: 'urbanist-semibold';
    text-align: center;
}
#card-faqss .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px;
}
#card-faqss .accordian .item {
    border: 1px solid rgb(239, 239, 239);
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 10px;
}
#card-faqs .accordian {
    margin: 59px 87px 0px;
}
#card-faqss .accordian .content p {
    color: #000000;
    text-align: left;
    font-family: 'urbanist-light' !important;
    font-size: 16px;
}
#card-faqss .content.show{
    padding-top: 20px !important;
    height: auto;
    max-height: 9999px;
    transition: all 0.4s cubic-bezier(1,1,1,1);
    margin-bottom: 0px !important;
}
#card-faqss span.svg.transform {
    transform: rotate(180deg);
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
#card-faqss span.svg {
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
#card-faqss span.svg svg path {
    fill: #000000;
}
#card-faqss span.svg svg {
    width: 10px;
    height: auto;
}