.steps__tr {
    padding: 100px 0px 100px 0px;
    position: relative;
    margin-top: 1px;
}
.steps__tr:after{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
.steps__tr:before{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
.steps__ff {
    margin: 0px;
    display: flex;
    flex-flow: wrap;
    gap: 40px;
    justify-content: center;
    padding: 0px 0px 0px 0px;
}
.fyu {
    flex: 0 0 35%;
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.fyu p {
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'roboto-light';
}
.fyu h1 {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    font-size: 24px;
    font-family: 'roboto-light';
}
#indicies__page .fyu:hover h1 {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
    border: 1px solid #FFFFFF;
}