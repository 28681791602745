.bbuss {
    text-align: center;
    padding: 100px 0px 60px 0px;
}
.bbuss iframe{
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
}
.buy-ss22{
    display: none;
}

@media screen and (max-width: 800px){
    .bbuss iframe {
        width: 100%;
        height: 600px;
    }
    .bbuss{
        padding: 60px 0px 60px 0px;
    }
}
.economic_calendar {
    padding: 0px 0px 100px 0px;
}
#tradingview_widget_wrapper div:nth-child(2) {
    display: none;
}
div#tradingview_widget_wrapper {
    margin-bottom: 50px;
}