/* #ncxAmbassador-page header#header-row {
    background: transparent !important;
    background-color: transparent !important;
}
#ncxAmbassador-page header#header-row.darkheader{
    background: #000000 !important;
    background-color: #000000 !important;
}
.ambassadordiv {
    max-height: 556px;
    background: #000000;
    margin-top: -65px;
}
.ambassadordiv .container {
    position: absolute;
    top: 0;
    width: 100% !important;
    left: 0;
    right: 0;
    padding: 150px 0px 80px 0px;
}
.ambassadordiv .backgrdound-video video {
    width: 100%;
    object-fit: cover;
    margin-bottom: -5px;
    max-height: 556px !important;
}
.ambassador.row {
    margin: 0px !important;
}
.ambassador.row:before, .ambassador.row:after{
    display: none;
}
.ambassador.row .col {
    padding: 0px;
    flex: inherit;
    width: 50%;
}
.ambassador.row h1 {
    font-size: 44px;
    color: #fFFFFF;
    font-family: 'urbanist-regular';
    margin: 15px 0px 10px 0px;
}
.txt44 p {
    font-size: 19px;
    width: 70%;
    font-family: 'hauora-light';
    color: #FFFFFF;
    line-height: 28px;
    margin-bottom: 10px;
}
.ambassador a {
    width: 155px;
    height: 42px;
    border: 1px solid #FFFFFF;
    border-radius: 7px;
    font-family: 'urbanist-regular';
    font-size: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: transparent;
}
.txt44 {
    margin-bottom: 30px;
} */

/* Crypto Debit/credit Card Section */
/* .ambassador_card.row {
    margin: 0px;
    display: flex;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.08));
    background: #FFFFFF;
    min-height: 520px;
}
.col.card_txt {
    width: 31%;
    padding: 5% 5%;
    flex: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fFFFFF;
    filter: drop-shadow(50px 0px 80px rgba(0, 0, 0, 0.11));
    z-index: 1;
}
.col.card_txt .inns-div {
    max-width: 320px;
    height: fit-content;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.col.card_imga {
    width: 36%;
    padding: 0px;
    flex: inherit;
    filter: drop-shadow(50px 0px 80px rgba(0, 0, 0, 0.11));
    background: #DBDCDE;
    max-height: 520px;
    text-align: center;
}
.col.card_imgb {
    width: 33%;
    padding: 0px;
    flex: inherit;
    background: #DBDCDE;
    max-height: 520px;
}
.col.card_imga img, .col.card_imgb img {
    width: 100%;
    height: auto;
    max-height: 520px;
    object-fit: cover;
    min-height: 520px;
}
.col.card_txt h1 {
    font-size: 40px;
    font-family: 'urbanist-regular';
    line-height: 46px;
    margin-bottom: 10px;
}
.col.card_txt p{
    font-size: 15px;
    font-family: 'hauora-light';
    line-height: 24px;
    margin-bottom: 20px;
}
.bbttn {
    display: flex;
    column-gap: 10px;
    margin-top: 40px;
}
a.card_apply {
    font-size: 13px;
    font-family: 'urbanist-regular';
    background: linear-gradient(180deg, #9452EC 0%, #6552C7 100%);
    border-radius: 40px;
    color: #FFFFFF;
    min-width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    text-transform: capitalize;
}
a.card_apply:hover, a.card_apply:focus, a.card_apply:focus-visible,
a.card_learn:hover, a.card_learn:focus, a.card_learn:focus-visible{
    outline: none;
    text-decoration: none;
}
a.card_learn {
    font-size: 13px;
    font-family: 'urbanist-regular';
    background: #F0F0F1;
    border-radius: 40px;
    color: #000000;
    min-width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    text-transform: capitalize;
} */

/* New Styling */
/* .new__ambass video {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
} */
/* .new__ambass .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
} */
.new__ambass{
    position: relative;
    background: #000000;
}
.fvsd__v video {
    width: 100%;
    max-height: 550px;
}
/* .insse {
    max-width: 330px;
} */
.insse h1 {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
}
.insse a {
    font-size: 16px;
    width: 150px;
    height: 44px;
    border: 1px solid #5ED3F0;
    border-radius: 50px;
    margin-top: 30px;
    color: #FFFF;
    text-transform: capitalize;
    font-family: 'urbanist-regular';
}
.insse a:hover{
    background: #5ED3F0;
}
.insse a:focus, .insse a:focus-visible, .insse a:active{
    outline: none;
    text-decoration: none;
}
.ml__vwxf{
    display: flex;
    align-items: center;
    padding: 0px 0px 50px 0px;
}
.ml__vwxf .insse{
    width: 50%;
}
.ml__vwxf .fvsd__v{
    width: 50%;
}
.ml__vwxf .insse h1 {
    width: 330px;
}

#ncxAmbassador-page .ambassadordiv{
    display: none;
}
@media screen and (max-width: 1320px){
    .col.card_imga img, .col.card_imgb img{
        max-height: 450px;
        min-height: 450px;
    }
    .col.card_txt h1{
        font-size: 32px;
        line-height: 36px;
    }
    .col.card_txt p{
        font-size: 14px;
        line-height: 22px;
    }
    a.card_apply, a.card_learn{
        width: fit-content;
        height: fit-content;
        padding: 10px 20px;
        font-size: 12px;
    }
    .col.card_txt{
        padding: 0% 3%;
    }
}
@media screen and (max-width: 800px){
    #ncxAmbassador-page .crypto_card {
        display: block;
    }
    #ncxAmbassador-page .ambassadordiv{
        display: none;
    }
    #ncxAmbassador-page .crypto_card .ambassador_card {
        display: none;
    }
    .new__ambass {
        display: none;
    }
    #ncxAmbassador-page #mobileSlider .col.card_txt h1{
        font-size: 36px !important;
        line-height: 44px !important;
    }
    #ncxAmbassador-page #mobileSlider video {
        min-height: 360px;
        object-fit: cover;
        width: 100%;
    }
    #ncxAmbassador-page #mobileSlider .col.card_txt{
        padding: 30px 0px 0px 0px !important;
    }
    #ncxAmbassador-page .card_txt h1.border-bv:before{
        background: rgba(148,82,236,.6);
        bottom: 9px;
        content: "";
        display: block;
        height: 10px !important;
        position: absolute;
        width: 95% !important;
    }
    .col.card_txt{
        filter: none !important;
        background: transparent !important;
    }
    #ncxotc-page #mobileSlider h1.border-bv:before{
        width: 100% !important;
    }
    #ncxotc-page h3.mbl__why_otc.line:before{
        display: none !important;
    }
    #ncxotc-page h3.mbl__why_otc{
        margin-bottom: 10px !important;
    }
}