/************************** HomePage Seventh New Generation Section Starts from here *************************/
.new-generation {
    background: #FFFFFF !important;
    padding: 100px 0px;
    position: relative;
    margin-top: 2px;
}
video#ncx-main-video {
    max-width: 100%;
    object-fit: cover;
    height: 100%;
    filter: drop-shadow(4px 4px 25px rgba(0, 0, 0, 0.25));
}
.generate-div {
    width: 48%;
}
.generate-video{
    width: 52%;
}
.new-generation .container {
    display: flex;
}
.generate-div h1{
    font-size: 32px;
    line-height: 40px;
    font-family: 'urbanist-regular';
}
.generate-div p{
    font-size: 16px;
    line-height: 22px;
    width: 60%;
    margin-top: 15px;
    font-family: 'urbanist-light';
}
.taskdiv {
    background: #F7F9FE;
    border-radius: 2px;
    width: 250px;
    height: 245px;
    padding: 25px 20px;
    display: grid;
    margin-right: 20px;
    transform: translateY(0%);
    transition: .4s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.taskdiv:hover{
    background: #FFFFFF;
    box-shadow: 0 0 16px rgb(184 201 255 / 30%);
    cursor: pointer;
}
.taskdiv h3 {
    color: #40475A;
    font-size: 42px;
}
.taskdiv h3 span {
    font-size: 16px;
}
.buttoninfo a {
    background: #925BCA;
    color: #FFF;
    font-size: 15px;
    font-family: 'urbanist-regular';
    border-radius: 50px;
    text-transform: capitalize;
    min-width: 115px;
    height: 40px;
}
.buttoninfo a:hover{
    background: rgba(25, 118, 210, 0.04);
    color: #000;
}
.buttoninfo a:focus, .buttoninfo a:focus-visible{
    outline: none !important;
}
.buttoninfo a:focus, .buttoninfo a:focus-visible{
    background: #925BCA;
    color: #FFF;
    outline: none;
    box-shadow: none;
    text-decoration: none;
}
.buttoninfo {
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.buttoninfo svg{
    padding-bottom: 3px;
    width: 18px;
    height: 18px;
}
.text212 h4 {
    font-size: 15px;
    font-family: 'urbanist-medium';
}
.text212 {
    padding-bottom: 10px;
}
.text212 p {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
    color: #565672;
    line-height: 15px;
}
h2.secondtask{
    margin-bottom: 20px;
}
.ncxtask.row {
    margin: 0px !important;
    display: flex;
    margin-top: 50px !important;
}
.ncxtask.row:before, .ncxtask.row:after{
    display: none;
}

#ncx-main-video-mobile{
    display: none;
}

@media screen and (max-width: 1200px){
    .taskdiv{
        width: 46% !important;
        margin: 0px !important;
    }
    .ncxtask.row{
        justify-content: space-between;
    }
    .new-generation .container{
        column-gap: 20px;
    }
    .taskdiv h3{
        font-size: 36px;
    }
}
@media screen and (max-width: 800px) {
    #ncx-main-video-mobile{
        display: block !important;
        width: 100% !important;
    }
    #ncx-main-video{
        display: none;
    }
    .new-generation{
        padding: 50px 0px;
    }
    .generate-div, .generate-video{
        width: 100%;
    }
    .new-generation .container{
        display: block;
    }
    .generate-div h1{
        font-size: 24px;
        line-height: 32px;
        font-family: 'urbanist-regular' !important;
    }
    .generate-div p{
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        font-family: 'urbanist-light';
    }
    .ncxtask.row{
        margin-top: 30px !important;
        justify-content: space-between;
    }
    .ncxtask.row:before, .ncxtask.row:after{
        display: none;
    }
    .taskdiv {
        width: 49% !important;
        margin-right: 0px;
        max-height: 200px;
        gap: 0px !important;
        justify-content: space-between;
        padding: 15px 10px 15px 15px !important;
    }
    .buttoninfo svg{
        padding-bottom: 0px !important;
    }
    .taskdiv.one{
        gap: 29px !important;
    }
    .taskdiv h3{
        font-size: 28px;
        font-family: 'urbanist-medium';
    }
    .taskdiv h3 span{
        font-size: 12px;
        font-family: 'urbanist-regular';
    }
    .generate-video{
        margin-top: 30px;
    }
    .text212 h4 {
        font-size: 14px;
        font-family: 'urbanist-medium';
    }
    .buttoninfo a{
        width: 85px;
        height: 30px;
        font-size: 12px !important;
        min-width: unset;
    }
    video#ncx-main-video{
        width: 100% !important;
        height: auto !important;   
        max-width: inherit !important;
        object-fit: inherit !important;
    }
    .text212 p{
        font-size: 10px !important;
        line-height: 12px !important;
    }
    #hgff .ncxtask.row, #hyres .ncxtasksection h1, #hyres .ncxtasksection p.task{
        display: none;
    }
    #hyres .ncxtasksection .tasktext h1{
        display: block;
    }
}