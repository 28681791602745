/************************** HomePage Sixth CEX and DEX & Trading Features Section Starts from here *************************/
.dex-table {
    background: #000000;
    padding: 100px 0px;
    position: relative;
}
.text-table h1 {
    text-align: left;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 10px;
}
.text-table p {
    text-align: left;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 14px;
    font-weight: 300 !important;
}
.row.body-text.second .tickbox, .row.body-text.second .optionalbox {
    padding: 8px 6px;
}
.row.body-text.second .txt-cl {
    padding-top: 2px;
}
.tabledex .body {
    display: flex;
    margin: 0 auto;
    width: 95%;
    justify-content: center;
}
.tabledex .body .col.one {
    padding: 0px;
    width: 65px;
    display: block;
    max-width: 5% !important;
}
.tabledex .body .col.two {
    width: 95% !important;
    padding: 0px;
    flex: inherit;
}
.row.head {
    margin: 0px;
    padding: 15px 30px 15px 25px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
}
.tabledex {
    margin-top: 30px;
}
.row.head:before {
    content: '';
    background: rgba(255,255,255,0.25);
    width: 100%;
    height: 1px;
    position: absolute;
    display: block;
    left: 0px;
    bottom: 0px;
}
.row.head h2 {
    color: #FFFFFF;
    font-family: 'urbanist-medium';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}
.txt-cl, .txt-blue {
    width: 25%;
}
.txt-cl h3 span {
    display: block;
    font-size: 10px !important;
    font-family: 'urbanist-light' !important;
    line-height: 14px !important;
}
.row.body-text {
    margin: 0px;
    display: flex;
}
.txt-cl h3, .txt-blue h3 {
    font-size: 17px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    text-align: left;
    padding: 8px 0px;
    line-height: 21px;
}
.txt-blue h3{
    text-align: center; 
    max-width: 140px;
    margin: 0 auto;
}
.tickbox, .optionalbox {
    padding: 8px 0px;
    text-align: center;
}
.txt-blue {
    position: relative;
    z-index: 1;
}
.txt-blue:before {
    content: '';
    display: block;
    width: 100%;
    max-width: 160px;
    height: 102%;
    position: absolute;
    z-index: -1;
    border-radius: 7px;
    top: -2%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 1px solid rgba(255,255,255,0.25)
}
.row.body-text.second {
    margin-top: 20px;
}
.row.body-text.second .txt-blue:before {
    height: 104%;
}
.agreement-p {
    margin-top: 20px;
    padding-right: 65px;
}
.agreement-p p {
    text-align: end;
    color: #FFFFFF;
    font-size: 13px;
    font-family: 'urbanist-regular';
    line-height: 18px;
}
.agreement-p p span a{
    color: #65E4A1;
}
.row.body-text .txt-blue:nth-child(2):before{
    border: 1px solid #5ED3F0;
    box-shadow: 0px 0px 15px rgba(94, 211, 240, 0.75);
    background: #000;
}
.row.body-text.second .txt-blue:nth-child(2):before{
    border: 1px solid #5ED3F0;
    box-shadow: 0px 0px 15px rgba(94, 211, 240, 0.75);
    background: #000;
}

.mbL__all__device{
    display: none;
}
#mbl__slida{
    display: none;
}
div#mobile-tabsection {
    display: none;
}
@media screen and (max-width: 800px){
    .tabledex{
        display: none;
    }
    div#mobile-tabsection {
        display: block !important;
        padding-top: 30px;
    }
    .text-table h1{
        font-size: 26px;
        line-height: 32px;
    }
    .dex-table{
        padding: 40px 0px !important;
    }
    .text-table p{
        font-family: 'urbanist-light' !important;
        display: inline;
    }
    .text-table p:nth-child(2){
        padding-left: 5px;
    }
    .btn-dexmbl {
        display: flex;
    }
    .btn-dexmbl h1 {
        font-size: 16px;
        color: #FFFFFF;
        font-family: 'urbanist-medium';
        line-height: 20px;
        text-align: center;
        border-bottom: 1px solid #FFFFFF;
        padding-bottom: 10px;
    }
    .btn-dexmbl h1:nth-child(1){
        width: 34%;
        margin: 2.5px;
    }
    .btn-dexmbl h1:nth-child(2){
        width: 22%;
        margin: 2.5px;
    }
    .btn-dexmbl h1:nth-child(3){
        width: 22%;
        margin: 2.5px;
    }
    .btn-dexmbl h1:nth-child(4){
        width: 22%;
        margin: 2.5px;
    }
    #mobile-tabsection .row {
        margin: 0px !important;
        display: flex;
    }
    #mobile-tabsection .row .mbl-dexheading{
        width: 34%;
        padding: 0px;
    }
    #mobile-tabsection .row:before{
        display: none !important;
    }
    .mbl-dexcont1 {
        width: 22%;
        padding: 0px;
    }
    .mbl-dexcont2 {
        width: 22%;
        padding: 0px;
    }
    .mbl-dexcont3{
        width: 22%;
        padding: 0px;
    }
    #mobile-tabsection .row .mbl-dexheading h3, .mbl-dexcont2 h3, .mbl-dexcont3 h3, .mbl-dexcont2 .tickbox,
    .mbl-dexcont2 .optionalbox, .mbl-dexcont3 .optionalbox, .mbl-dexcont3 .tickbox{
        background: #1F1F1F;
        font-size: 11px;
        font-family: 'urbanist-regular';
        min-height: 31px;
        color: #FFFFFF;
        margin: 2.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        text-align: center;
    }
    .mbl-dexcont1 h3 {
        min-height: 31px;
        font-size: 11px;
        font-family: 'urbanist-regular';
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        background: #5ED3F0;
        margin: 2.5px;
        margin-bottom: 5px;
    }
    .mbl-dexcont1 div.tickbox{
        min-height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #5ED3F0;
        margin: 2.5px;
        margin-bottom: 5px;
    }
    .purple{
        background-color: #5ED3F0 !important;
    }
    #mobile-tabsection .optionalbox {
        padding: 0px;
    }
    .optionalbox svg {
        width: 10px;
        height: auto;
    }
    .mbl-dexheading h3:nth-child(10) {
        display: block !important;
        padding: 0px 10px;
    }
    .mbl-dexheading h3:nth-child(10) span{
        font-size: 8px !important;
        line-height: 10px !important;
        padding-left: 4px !important;
    }
    .mbl-dexheading h3:nth-child(4), .mbl-dexcont1 h3:nth-child(4), .mbl-dexcont2 h3:nth-child(4), .mbl-dexcont3 h3:nth-child(4),
    .mbl-dexheading h3:nth-child(5), .mbl-dexcont1 h3:nth-child(5), .mbl-dexcont2 h3:nth-child(5), .mbl-dexcont3 h3:nth-child(5),
    .mbl-dexheading h3:nth-child(6), .mbl-dexcont1 h3:nth-child(6), .mbl-dexcont2 h3:nth-child(6), .mbl-dexcont3 h3:nth-child(6),
    .mbl-dexheading h3:nth-child(14), .mbl-dexheading h3:nth-child(15) {
        min-height: 44px !important;
        line-height: 15px !important;
    }
    .mbl-dexheading h3:nth-child(7), .mbl-dexcont1 h3:nth-child(7), .mbl-dexcont2 h3:nth-child(7), .mbl-dexcont3 h3:nth-child(7) {
        min-height: 44px !important;
        padding: 0px 5px !important;
        line-height: 15px !important;
    }
    .mbl-dexheading h3:nth-child(10){
        min-height: 44px !important;
        padding: 4px 3px 0px 3px !important;
        line-height: 11px !important;
    }
    .mbl-dexcont1 h3:nth-child(8) {
        font-size: 10px !important;
    }
    .mbl-dexcont1 .tickbox.sas, .mbl-dexcont2 .optionalbox.sas, .mbl-dexcont3 .optionalbox.sas,
    .tickbox.purple.sas, .tickbox.sas, .optionalbox.sas{
        min-height: 44px !important;
        line-height: 15px;
    }
    .mobile-bp p {
        font-size: 8px;
        line-height: 18px;
        color: #FFFFFF;
        font-family: 'urbanist-regular';
        text-align: right;
    }
    .mobile-bp{
        margin-top: 0px;
    }
    .mobile-bp p span a{
        color: #65E4A1 !important;
    }


    /* ******************************************************************************************************** */
    #mbl__slida{
        display: block;
        padding-top: 50px;
    }
    #mbl__slida .carousel__dot-group {
        text-align: center;
    }
    #mbl__slida button.carousel__dot--selected {
        width: 40px !important;
        background: #FFFFFF !important;
        border: 2px solid #FFFFFF !important;
        border-radius: 50px !important;
    }
    #mbl__slida button.carousel__dot--selected span{
        background: #FFFFFF;
    }
    #mbl__slida .carousel__dot-group {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-top: 20px;
    }
    #mbl__slida button.carousel__dot {
        width: 7px;
        border: none;
        background: rgba(255,255,255,0.5);
        border-radius: 50px;
        padding: 0px;
        height: 4px;
    }
    #mbl__slida button.carousel__dot:hover{
        width: 20px;
        background: rgba(255,255,255,0.5);
        transition: all 0.3s;
    }
    .mbL__all__device{
        display: block;
    }
    .bg-dark .mbL__all__device .plat__ggre.row{
        display: block;
    }
    .bg-dark .plat__ggre.row{
        display: none;
    }
}
