.token_features{
    padding: 100px 0px 100px 0px;
    position: relative;
    margin-top: 1px;
}
.token_features:before{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
.token_features:after{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
h1.toke_feat{
    font-size: 34px;
    font-family: 'urbanist-regular';
    line-height: 40px;
    color: #FFFFFF;
}
h1.toke_feat span{
    font-family: 'urbanist-extralight';
}
.features-col {
    display: flex;
    column-gap: 20px;
}
.feat-row.row {
    margin: 0px !important;
    margin-top: 70px !important;
}
.feat-row.row .col{
    padding: 0px !important;
}
.token_features .feat-row.row.second .fftxt {
    min-height: 315px;
}
.token_features .feat-row.row.second .feat-line {
    height: 360px;
}
.fftxt h2 {
    font-size: 25px;
    font-family: 'urbanist-medium';
    color: #FFFFFF;
    line-height: 35px;
    width: 75%;
    margin-bottom: 10px;
}
.fftxt p {
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    width: 84%;
    letter-spacing: 0.5px;
}
.feat-line{
    background: #515151;
    height: 320px;
    transform: rotate(0deg);
    width: 1px;
}
.fftxt {
    min-height: 275px;
}
.featicon svg {
    width: 35px;
    height: 35px;
}
.features-col.hide {
    visibility: hidden;
}
h2.specf{width: 100% !important;}


.token_features .feat-row.mobile {
    display: none;
}
@media screen and (max-width: 800px){
    .token_features .feat-row.mobile {
        display: flex !important;
        margin-top: 40px !important;
    }
    .token_features .feat-row.row{
        display: none;
    }
    .token_features .feat-row.mobile .col {
        width: 50% !important;
        flex: inherit !important;
    }
    .features-col{
        column-gap: 10px !important;
    }
    .fftxt h2 {
        font-size: 17px !important;
        line-height: 24px !important;
        font-family: 'urbanist-regular' !important;
        width: 90% !important;
    }
    .featicon svg {
        width: 20px !important;
        height: 20px !important;
    }
    .fftxt p {
        font-size: 12px !important;
        line-height: 17px !important;
        width: 90% !important;
    }
    h1.toke_feat {
        font-size: 26px;
    }
    .token_features .feat-row.row.mobile.one .feat-line {
        height: 300px;
    }
    .token_features .feat-row.row.mobile.one .fftxt {
        min-height: 270px;
        max-height: 270px;
    }
    .token_features .feat-row.row.mobile.two .feat-line {
        height: 380px !important;
    }
    .token_features .feat-row.row.mobile.two .fftxt {
        max-height: 350px;
        min-height: 350px;
    }
    .token_features .feat-row.row.mobile.three .feat-line {
        height: 350px;
    }
    .token_features .feat-row.row.mobile.three .fftxt {
        max-height: 310px;
        min-height: 310px;
    }
    .token_features .feat-row.row.mobile.four .feat-line {
        height: 255px;
    }
    .token_features .feat-row.row.mobile.four .fftxt {
        min-height: 210px;
        max-height: 210px;
    }
    .token_features .feat-row.row.mobile.five .feat-line {
        height: 300px;
    }
    .token_features .feat-row.row.mobile.five .fftxt {
        min-height: 270px;
        max-height: 270px;
    }
    .token_features .feat-row.row.mobile.six .feat-line {
        height: 290px;
    }
    .token_features .feat-row.row.mobile.six .fftxt {
        min-height: 260px;
        max-height: 260px;
    }
    .token_features{
        padding: 50px 0px 50px 0px !important;
    }
    #presale_ncxT .stake_ncx h1{
        font-size: 20px !important;
        line-height: 26px !important;
    }
    #presale_ncxT .stake_ncx svg {
        width: 100px;
        height: auto;
    }
    #presale_ncxT .stake_ncx{
        margin-bottom: 40px;
    }
    #token_allocation .feat-row.row.mobile.one .feat-line {
        height: 280px;
    }
    #token_allocation .feat-row.row.mobile.one .fftxt {
        min-height: 250px;
        max-height: 250px;
    }
    #token_allocation .feat-row.row.mobile.two .feat-line {
        height: 370px !important;
    }
    #token_allocation .feat-row.row.mobile.two .fftxt {
        max-height: 340px;
        min-height: 340px;
    }
    #token_allocation .feat-row.row.mobile.three .feat-line {
        height: 300px;
    }
    #token_allocation .feat-row.row.mobile.three .fftxt {
        max-height: 270px;
        min-height: 270px;
    }
    #token_allocation .feat-row.row.mobile.four .feat-line {
        height: 210px;
    }
    #token_allocation .feat-row.row.mobile.four .fftxt {
        min-height: 180px;
        max-height: 180px;
    }
}
