.trading__platform {
    padding: 100px 0px;
    margin-top: 1px;
    position: relative;
}
.plat__ggre.row {
    display: flex;
    flex-flow: wrap;
    margin: 0px !important;
    justify-content: space-between;
    padding: 50px 0px 0px 0px;
}
.plat__ggre.row .col{
    flex: 0 0 48%;
    padding: 0px !important
}
.tra__go h2 {
    font-size: 30px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    line-height: 38px;
}
.tra__go {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
}
.trading__platform h1.fg__rer{
    margin-bottom: 20px;
}
.trg__ff__fe p{
    font-size: 16px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    line-height: 21px;
    text-align: center;
    margin-bottom: 30px;
}
.trg__ff__fe .tiy__bd {
    display: flex;
    justify-content: center;
    margin-bottom: 60px !important;
}
.pla__img, .pho__img, .ipad__gg {
    width: 100%;
    text-align: center;
    max-height: 375px;
    min-height: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pho__img{
    position: relative;
}
#ghrthf-we .pho__img {
    max-height: 450px;
    min-height: 450px;
}
video#phomsdf{
    max-height: 450px;
}
.pho__img img{
    max-height: 400px;
    width: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: .4s ease;
}
.pla__img img {
    width: 100%;
    max-width: 550px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: .4s ease;
}
.ipad__gg img{
    width: 100%;
    max-width: 400px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: .4s ease;
}
.ipad__gg{
    align-items: flex-start !important;
    padding-top: 20px !important;
}
.pho__img img:hover, .pla__img img:hover, .ipad__gg img:hover{
    cursor: pointer;
    transform: translateY(0) scale(1.06);
    -webkit-transform: translateY(0) scale(1.06);
    -moz-transform: translateY(0) scale(1.06);
}
@media screen and (min-width: 1200px){
    .trg__ff__fe p{
        padding: 0px 50px;
    }
}