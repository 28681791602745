#mbl__wal{
    display: none;
}
@media screen and (max-width: 800px){
    #mbl__wal{
        display: block;
        padding: 80px 0px;
    }
    .wall__cont {
        display: none;
    }
    .wall__cont.active {
        display: block;
    }
    h1.hed_x {
        font-size: 28px;
        font-family: 'hauora-medium';
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        padding: 30px 0px;
    }
    .wallet__tab {
        border: 1px solid #fff;
        border-radius: 100px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -ms-flexbox;
        display: flex;
        margin: 28px auto 32px;
        padding: 4px;
        width: 100%;
        justify-content: space-between;
        /* overflow-x: scroll; */
    }
    button.wall__tab {
        font-size: 20px;
        font-weight: 500;
        justify-content: center;
        line-height: 16px;
        max-width: 33%;
        padding: 15px 10px;
        background: none;
        border: none;
        color: #FFFFFF;
        min-width: 33%;
        max-width: 33%;
    }
    button.wall__tab.active{
        color: #000000;
        background: #FFFFFF;
        border-radius: 40px;
    }
    .cont__txt {
        text-align: center;
    }
    .cont__txt h1 {
        font-size: 26px !important;
        line-height: 38px !important;
        color: #FFFFFF;
        text-align: center;
        min-height: 152px;
        margin-bottom: 30px;
    }
    .cont__txt h1 span{
        color: #78E4FF;
    }
    .cont__txt p {
        font-size: 14px;
        width: 100%;
        color: #FFFFFF;
        text-align: center;
        margin: 20px 0px 40px 0px;
        display: none;
    }
    .cont__txt img {
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
    }
    div#ncxt__stake{
        padding-bottom: 30px !important;
    }
}