#marketmakers_page header#header-row.darkheader{
    background-color: #000000 !important;
    background: #000000 !important;
}
#marketmakers_page .gradient {
    padding-top: 30px;
}
h1.market_benefits {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 38px;
    font-family: 'hauora-medium';
}
#marketmakers_page .row.gradient-text ul {
    margin-top: 10px;
    margin-bottom: 0px !important;
}
#marketmakers_page .row.gradient-text p {
    min-height: 42px;
    font-size: 15px !important;
}
#marketmakers_page .gradient-text .col h1{
    font-size: 23px !important;
    line-height: 28px !important;
}
#marketmakers_page .row.gradient-text ul li {
    font-size: 15px;
    font-family: 'hauora-regular';
    line-height: 20px;
    margin-bottom: 5px;
}
.transaction_rebates {
    margin-top: 100px;
}
h1.market_benefits span, .transaction_rebates .hsd13 h3 span {
    background: linear-gradient(90deg, #924DE9 50%, #4A3DAF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.transaction_rebates .amm_body_row p {
    font-size: 16px;
    font-family: 'hauora-medium';
}
#marketmakers_page .ambassadordiv .backgrdound-video video{
    display: block !important;
}
#marketmakers_page .ambassadordiv .container{
    position: absolute;
}
#marketmakers_page #mkt__mkr__ndd .card_imgb video {
    height: 100% !important;
}
p.mkr_rebate_mbl {
    display: none;
}

@media screen and (max-width: 800px){
    #marketmakers_page .ambassadordiv {
        margin-top: 0px !important;
        padding: 50px 0px 50px 0px;
    }
    #marketmakers_page .ambassadordiv .backgrdound-video {
        display: none;
    }
    #marketmakers_page .ambassadordiv .container {
        position: relative !important;
        left: inherit !important;
        right: inherit !important;
    }
    #marketmakers_page .ambassador.row .col {
        width: 100% !important;
    }
    #marketmakers_page .ambassador.row h1 {
        font-size: 24px;
        line-height: 32px;
    }
    #marketmakers_page .txt44 {
        margin-bottom: 20px;
    }
    #marketmakers_page .txt44 p {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;
    }
    #marketmakers_page .ambassador a {
        width: 140px;
        height: 40px;
        font-size: 14px;
    }
    .market-discount{
        padding: 50px 0px 20px 0px !important;
    }
    .market-discount h1{
        font-size: 26px !important;
        line-height: 32px !important;
        width: 100%;
        display: inline-block !important;
    }
    #marketmakers_page .market-tabs-btn {
        display: flex;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        width: 100%;
    }
    #marketmakers_page button.market-tabs {
        width: 50% !important;
        padding: 0px;
        font-size: 13px !important;
        line-height: 16px !important;
        font-family: 'hauora-semibold' !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 46px;
        min-width: unset !important;
    }
    #marketmakers_page .market-content{
        border-radius: 0px;
        background: transparent;
        box-shadow: none;
        padding: 30px 0px 0px 0px;
    }
    .hsd11 h3, .hsd13 h3{
        font-size: 20px !important;
        line-height: 25px !important;
    }
    .market-content p {
        font-size: 14px !important;
        line-height: 20px !important;
        font-family: 'hauora-medium';
        display: grid;
    }
    .head_table{
        margin: 0px 10px !important;
    }
    #marketmakers_page .discount_table .col p {
        display: inherit !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }
    .table_body_row {
        margin: 0px 10px !important;
    }
    .head_table p span{
        font-size: 6px !important;
        padding: 5.5px 2px !important;
        line-height: 7px !important;
        margin-right: 3px !important;
    }
    .table_body_row.boder-color{
        margin: 0px !important;
        padding: 25px 10px !important;
    }
    #marketmakers_page .fees_details h4{
        margin-bottom: 10px;
    }
    #marketmakers_page .fees_details p{
        line-height: 20px !important;
    }
    .amm_markettable .amm_head{
        padding: 20px;
    }
    .amm_markettable .amm_body_row {
        margin: 0px 20px;
    }
    h1.market_benefits{
        font-size: 26px;
        line-height: 32px;
    }
    #marketmakers_page .row.gradient-text p{
        min-height: inherit !important;
    }
    .transaction_rebates{
        margin-top: 50px;
    }
    p.mkr_rebate_mbl {
        display: block !important;
    }
    p.rebat_mkr_dsk {
        display: none;
    }
    .amm_markettable .amm_head {
        padding: 12px 20px;
    }
    .amm_head p {
        font-size: 10px !important;
        line-height: 16px !important;
    }
}