.ncxdebitcreditcardpage .earnncx {
    padding-bottom: 100px;
    padding-top: 50px;
}
/* .ncxdebitcreditcardpage header#header-row {
    position: fixed !important;
    background: transparent !important;
    background-color: transparent !important;
} */
.ncxdebitcreditcardpage header#header-row.darkheader{
    background: #000000 !important;
    background-color: #000000 !important;
}
#ncxcard_cardset .cardtrade-row h1, #ncxcard_cardset .cardtrade-row p{
    color: #FFFFFF !important;
}
.ncxdebitcreditcardpage div#card-faqss {
    padding-bottom: 50px;
    padding-top: 100px;
}
.ncxallcarddiv {
    padding-bottom: 100px;
}
.ncxdebitcreditcardpage #powered_by .apptexts:before{
    background: rgba(17, 17, 17, 0.6) !important;
    height: 1px !important;
}
.cryptopays svg {
    width: 80px;
    height: auto;
}
.cryptopays{
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
}
#mobile-showcard{
    display: none;
}
.new_cverd .card_box.csdvw__vew:before, .new_cverd .card_box.csdvw__vew:after{
    display: none !important;
}
@media screen and (max-width: 800px){
    #mobile-showcard{
        display: block;
        padding: 0px !important;
    }
    .card-row.row .col:nth-child(3){
        display: none;
    }
    .cardvideo-sect{
        background: #FFFFFF !important;
    }
    .cardvideo-sect #Homevideosection{
        display: none !important;
    }
    .cardvideo-sect .container{
        position: relative !important;
        top: inherit !important;
        left: inherit !important;
        right: inherit !important;
        padding: 0px 0px 0px 0px !important;
    }
    .cardvideo-sect #ncxcard_cardset .ncx-card-trade {
        position: relative !important;
        /* background: url(../../images/ncx-card-traebg.png) !important; */
        background: #000000 !important ;
        background-repeat: no-repeat;
        background-size: cover !important;
        background-position: center !important;
        margin: 0px !important;
        padding: 70px 20px 50px 20px !important;
        min-height: inherit !important;
        max-height: inherit !important;
    }
    .cardvideo-sect #ncxcard_cardset{
        max-height: inherit !important;
    }
    .cardvideo-sect .cardtrade-row .col.product-img img{
        position: relative !important;
        top: unset !important;
        left: unset !important;
        /* right: 25px !important; */
        min-width: 400px !important;
    }
    .cardvideo-sect #ncxcard_cardset .ncx-card-trade .bullets span{
        color: #000000 !important;
        background: #FFFFFF !important;
        /* background: rgba(0,0,0,0.5) !important; */
        font-size: 15px !important;
        padding: 5px 13px !important;
    }
    body .cardvideo-sect #cardgradient{
        padding-top: 50px !important;
        background: #FFF !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 50px !important;
    }
    .cardvideo-sect .gradient-text .col h3 {
        font-family: 'hauora-regular' !important;
        font-size: 14px !important;
        text-align: left !important;
        margin-bottom: 10px !important;
        color: #000000 !important;
    }
    .cardvideo-sect .gradient-text .col h1 {
        font-family: 'urbanist-medium' !important;
        font-size: 24px !important;
        line-height: 32px !important;
        text-align: left !important;
        margin-bottom: 15px !important;
        color: #000000 !important;
    }
    .cardvideo-sect .gradient-text .col p {
        font-family: 'hauora-regular' !important;
        font-size: 16px !important;
        text-align: left !important;
        color: #000000 !important
    }
    .cardvideo-sect #cardgradient .gradient-text .col:nth-child(2){
        margin: 0px;
        margin-top: 35px;
    }
    .carddetails {
        padding: 50px 0px 0px 0px !important;
    }
    .carddetailsimg{
        padding-bottom: 50px !important;
    }
    .carddetails .row{
        display: block !important;
    }
    .carddetails .row .col{
        width: 100% !important;
        padding: 0px !important;
    }
    .carddetails .row h1{
        font-size: 26px !important;
        line-height: 32px !important;
        margin-bottom: 20px;
    }
    .carddetails .row ul {
        padding-left: 35px !important;
    }
    .ncxcard{
        padding: 50px 0px !important;
    }
    .freq-ask-ques{
        font-size: 26px !important;
        line-height: 32px !important;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    #card-faqss .accordian{
        margin: 0px !important;
    }
    #card-faqss .accordian .item{
        padding: 16px !important;
    }
    div#card-faqss{
        padding-bottom: 0px !important;
        padding-top: 50px !important;
    }
    #mobile-showcard h1 {
        font-size: 26px;
        line-height: 32px;
        font-family: 'hauora-semibold';
    }
    .trprr{
        font-family: 'hauora-semibold';
        color: #FFFFFF;
        -webkit-text-stroke: 1px black;
    }
    form#email-subscription input{
        width: 70% !important;
        background: #FFFFFF !important;
        filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.05)) !important;
    }
    form#email-subscription button{
        width: 30% !important;
        margin: 0px !important;
    }
    form#email-subscription{
        width: 100%;
    }
    .cryptopay svg{
        margin-top: 15px !important;
        margin-right: 15px !important;
    }
    .card-row.row .col{
        width: 100% !important;
        padding: 0px !important;
    }
    .ncx-iphoneimages img, .transact-iphone {
        width: 100%;
    }
    .cardmobileimg{
        margin-top: 60px;
    }
    .carddetailsimg img{
        padding: 20px !important;
        border-radius: 25px !important;
    }
    .bullets{
        display: none;
    }
    #mobile-butller{
        display: block !important;
    }
    #mobile-butller .bullets{
        display: flex !important;
        margin-bottom: 15px !important;
    }
    #ncxcard_cardset .ncx-card-trade p.cardp1,  #ncxcard_cardset .ncx-card-trade p.cardp2{
        font-size: 16px !important;
        line-height: 24px !important;
    }
    #ncxcard_cardset .ncx-card-trade p.cardp2{
        margin-top: 30px;
        margin-bottom: 10px !important;
    }
    #card-faqss .accordian h2{
        font-family: 'urbanist-medium' !important;
    }
    div#ncxcardvideo-desk {
        display: none;
    }
    #ncxcardvideo-mbl{
        display: block !important;
        margin-top: 50px;
    }
    #ncxcardvideo-mbl video#video-scss {
        position: relative !important;
        margin-top: 0px !important;
        width: 100% !important;
        min-height: 350px !important;
        object-fit: cover;
    }
    .ncx-iphoneimages img, .transact-iphone{
        filter: none !important;
    }
    .carddetailsimg img:hover {
        transform: none !important;
        cursor: unset !important;
    }
    .ncxdebitcreditcardpage #ncxcard_cardset .cardtrade-row .col:nth-child(1){
        padding-top: 0px !important;
    }
    #ncx_cryptoCard{
        display: block !important;
    }
    .cardvideo-sect{
        display: none !important;
    }
    #ncx_cryptoCard .ambassador_card.row {
        display: none;
    }
    #mobileSlider .carousel .carousel__inner-slide img {
        min-height: 400px;
    }
    #mobileSlider .col.card_txt {
        position: relative;
        width: 100%;
        /* height: 550px; */
        padding: 30px 0px !important;
    }
    #mobileSlider #mobile-butller .bullets span {
        font-size: 15px !important;
        padding: 5px 13px !important;
        background: #F0F0F1;
        color: #000000 !important;
    }
    #mobileSlider p.cardp2 {
        margin: 30px 0px 20px 0px !important;
    }
    #mobileSlider p.cardp1 {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: 'hauora-regular' !important;
    }
    #mobileSlider .carousel {
        max-height: 600px;
        text-align: center;
    }
    #mobileSlider .carousel .carousel__slider {
        max-height: 400px;
        background: #DBDCDE !important;
    }
    #mobileSlider .carousel button:disabled{
        display: block;
    }
    #mobileSlider .carousel button{
        display: inline-block !important;
        margin: 5px !important;
        background: none !important;
        border: none !important;
        padding: 0px !important;
    }
    #mobileSlider .circle {
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background: #d6d6d8;
    }
    #mobileSlider .col.card_txt h1 {
        font-size: 36px !important;
        line-height: 44px !important;
        position: relative !important;
        width: fit-content;
    }
    #mobileSlider h1.border-bv:before{
        height: 10px !important;
        width: 100% !important;
    }
    #mobileSlider .col.card_txt h1.border-bv {
        width: fit-content !important;
    }
    #mobileSlider .col.card_txt h1.border-bv:before {
        width: 100% !important;
    }
    #mobileSlider .col.card_txt .inns-div{
        width: 100% !important;
        top: 0px !important;
        transform: unset !important;
        padding: 0px 20px !important;
        max-width: unset !important;
        position: relative !important;
    }
    #mobileSlider .bbttn{
        margin-top: 20px;
    }
    body #ncx_cryptoCard .ffd23 h1 {
        font-size: 20px !important;
        margin-bottom: 10px !important;
    }
    #mobileSlider{
        display: block !important;
    }
    #card-faqss details{
        padding: 15px !important;
    }
    #card-faqss span.red{
        width: 10px;
        padding: 0px !important;
    }
}
.cardvideo-sect{
    display: none;
}
#mobileSlider{
    display: none;
}