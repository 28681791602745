.ncxtasksection h1 {
    font-size: 32px;
    font-family: 'urbanist-regular';
    color: #40475A;
    text-align: center;
    margin-bottom: 50px;
}
.ncxtasksection {
    padding-top: 100px;
}
p.task {
    font-size: 20px;
    font-family: 'urbanist-regular';
    display: flex;
    align-items: center;
    column-gap: 10px;
    max-width: 1131px;
    margin: 0 auto;
}
.tasksrow {
    display: flex;
    column-gap: 25px;
    max-width: 1131px;
    margin: 0 auto;
    margin-top: 20px;
}
.tasksrow .col {
    width: 25%;
}
.tasktext {
    width: 264px;
    height: 245px;
    background: #F7F9FE;
    padding: 20px 25px;
}
.tasktext:hover{
    background: #FFFFFF;
    box-shadow: 0 0 16px rgb(184 201 255 / 30%);
}
.tasktext h1 {
    font-size: 42px;
    font-family: 'urbanist-medium';
    text-align: left;
    line-height: 50px;
    margin-bottom: 88px;
}
.tasktext h1 span {
    font-size: 16px;
    font-family: 'urbanist-medium';
    text-align: left;
    line-height: 24px;
    padding-left: 10px;
}
.taskbtn a {
    background: #925BCA;
    font-size: 15px;
    font-family: 'urbanist-regular';
    text-transform: capitalize;
    color: #FFFFFF;
    width: fit-content;
    height: 40px;
    min-width: 115px;
    border-radius: 50px;
    padding: 6px 15px !important;
}
#hyres .taskbtn a:hover{
    background: transparent;
    color: #FFFFFF;
    border: 1px solid #5ED3F0 !important;
}
.taskbtn a:focus, .taskbtn a:focus-visible{
    outline: none !important;
    text-decoration: none;
}
.taskbtn {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 10px;
}
.tasktext h3 {
    font-size: 15px;
    font-family: 'urbanist-medium';
    color: #2C2236;
    margin-bottom: 5px;
    line-height: 23px;
}
.tasktext p {
    font-size: 12px;
    font-family: 'urbanist-regular';
    color: #565672;
    line-height: 14px;
}
.tasktext-div {
    display: flex;
    justify-content: space-between;
    align-items: end;
    min-height: 68px;
}
.tasktext-div .col:first-child {
    width: 95% !important;
    flex: inherit;
}
.tasktext-div .col:last-child {
    width: 5% !important;
    flex: inherit;
}
.tasktext.signup h1 {
    margin-bottom: 30px;
}

.ncxtasksection button.btn-primary{
    background: none;
    border: none;
    padding: 0px;
    color: #925bca;
    font-size: 15px;
    font-family: 'urbanist-medium';
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px !important;
}
.ncxtasksection button.btn-primary:hover, .ncxtasksection button.btn-primary:focus, .ncxtasksection button.btn-primary:focus-visible{
    outline: none;
    border: none;
    box-shadow: none;
    background: none !important;
}
.ncxtasksection button.btn-primary svg path {
    fill: #925bca;
}
.ncxtasksection button.btn-primary svg{
    margin-left: 10px;
}
svg.straight {
    transform: rotate(90deg);
    width: 9px;
    height: auto;
    margin-bottom: 4px;
    margin-left: 12px !important;
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
svg.turn {
    width: 9px;
    height: auto;
    margin-bottom: 4px;
    margin-left: 12px !important;
    transform: rotate(-270deg);
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
.tasksrow.mobile{
    display: none;
}
.tasktext.popop h1 {
    margin-bottom: 72px;
}
@media screen and (min-width: 801px) and (max-width: 1100px){
    .tasktext.signup h1 {
        margin-bottom: 20px;
    }
    .tasktext.popop h1 {
        margin-bottom: 58px !important;
    }
}

@media screen and (max-width: 800px){
    .ncxtasksection{
        padding: 50px 0px 0px 0px !important;
    }
    .ncxtasksection h1{
        font-size: 26px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 0px;
    }
    .tasksrow{
        display: block;
        column-gap: inherit;
        max-width: 100%;
    }
    .tasksrow .col{
        width: 49% !important;
        display: inline-block;
    }
    .tasktext{
        width: 100%;
        min-height: 170px;
        height: 100%;
        padding: 15px 10px 15px 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .taskbtn{
        margin-top: 10px;
    }
    .tasktext h3{
        font-size: 13px;
        line-height: 16px;
    }
    .tasktext p{
        font-size: 10px;
        line-height: 12px;
    }
    .tasksrow{
        display: none;
    }
    .tasksrow.mobile{
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
        margin-top: 10px;
    }
    .tasktext.signup h1{
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 0px;
    }
    .tasktext.signup h1 span{
        font-size: 12px;
        font-family: 'urbanist-medium';
    }
    .tasktext.signup.mobilpopop {
        min-height: 200px;
    }
    .taskbtn a{
        width: fit-content;
        min-width: unset !important;
        height: 30px !important;
        font-size: 12px !important;
    }
    #hyres .taskbtn a{
        width: fit-content;
        min-width: unset !important;
        height: 30px !important;
        font-size: 12px  !important;
    }
    .tasktext-div .col:first-child{
        width: 90% !important;
    }
    .tasktext-div .col:last-child{
        width: 10% !important;
    }
    .ncxtasksection button.btn-primary{
        font-size: 14px !important;
    }
    .ncxtasksection button.btn-primary svg{
        width: 8px !important;
        margin-left: 10px !important;
    }
    svg.straight{
        margin-left: 10px !important;
    }
}