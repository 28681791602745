img.buy-ss22 {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 80px 0px;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2));
}
#buyCryptoPage .rewardheadrow {
    padding: 0px;
}
#buyCryptoPage ul.faq_li li {
    font-family: 'urbanist-light' !important;
}
#mobile-shoss{
    display: none;
}
#buy_crypto iframe {
    width: 100%;
}

@media screen and (min-width: 1300px){
    /* .buycrypt-row .col.coinsect {
        padding: 0px 170px 0px 50px !important;
    } */
    #ncxbuycrypto-desk video{
        min-height: 650px !important;
        object-position: right bottom;
    }
    .buycrypto-page .coinsect{
        min-height: 650px;
    }
}
@media screen and (max-width: 1399px){
    #ncxbuycrypto-desk video{
        min-height: 530px !important;
        object-position: right bottom;
    }
}
/* .container990 {
    max-width: 1450px !important;
    margin: 0 auto !important;
} */
/* .col.coinsect{
    padding: 0px 0px 0px 50px !important;
} */
@media screen and (max-width: 1100px){
    .crypto-assets.row .col.coin-details {
        width: 30% !important;
    }
    #ncxbuycrypto-desk video{
        object-position: center center !important;
    }
    .col.coinsect h1{
        font-size: 42px !important;
        width: 100% !important;
        line-height: 60px !important;

    }
    .crypto-assets.row .col.coin-details h1 {
        font-size: 14px !important;
    }
    .col.coinsect p{
        font-size: 14px !important;
        line-height: 22px !important;
        max-width: 100% !important;
    }
    .inner__cc {
        max-width: 400px !important;
    }
    .crypto-assets.row .col.coin-details p {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 800px){
    #mobile-shoss{
        display: block;
        padding: 0px !important;
    }
    #mobile-shoss h1, #mobile-shoss p{
        display: none;
    }
    #mobile-shoss .crypto-assets.row {
        margin-top: 0px !important;
        column-gap: 10px !important;
    }
    #mobile-shoss .crypto-assets.row p, #mobile-shoss .crypto-assets.row h1{
        display: flex !important;
        text-align: left !important;
    }
    body #mobile-shoss p.change {
        color: #65b07a !important;
        display: flex;
        align-items: center !important;
    }
    #mobile-shoss .crypto-assets.row .col{
        display: block !important;
    }
    img.buy-ss22{
        padding-bottom: 40px !important;
    }
    .crypto-assets.row{
        display: none !important;
    }
    #mobile-shoss .crypto-assets.row {
        display: none !important;

    }
    .buycrypt-row .col.coinsect{
        padding: 0px !important;
    }
    #buyCryptoPage #card-faqss{
        padding-bottom: 50px !important;
    }
}