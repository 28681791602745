div#indicies__page {
    background: #000000;
    background-color: #000000;
}
/* #indicies__page a.signin{
    background: #5ED3F0 !important;
    border-color: #5ED3F0 !important; 
}
#indicies__page .launch-dex{
    border-color: #5ED3F0 !important; 
}
#indicies__page ul.navlink li:after{
    background: #5ED3F0 !important;;
}
#indicies__page .main_service a:hover, #indicies__page .social_link_inner a:hover{
    color: #5ED3F0 !important;;
}
#indicies__page .mega_menu .social-icons .icons a:hover{
    background: #5ED3F0 !important;
}
#indicies__page #main-header .login-buttons a.signin:hover{
    background: transparent !important;
    border: 1px solid #5ED3F0 !important; 
}
#indicies__page .launch-dex:hover{
    background: #5ED3F0 !important; 
}
#indicies__page  .footer-row ul li a:after{
    background-color: #5ED3F0 !important;
}
#indicies__page  .social-icons .icons a:hover{
    background-color: #5ED3F0 !important;
}
#indicies__page .fot-newsleter form button{
    background: #5ED3F0 !important; 
    border: 1px solid #5ED3F0 !important; 
} */

/* ***************************************************************** */
#indicies__page.new__d .sieze__ppp:before, #indicies__page.new__d .sieze__ppp:after,
#indicies__page.new__d .ind__fq:before, #indicies__page.new__d .ind__fq:after,
#indicies__page.new__d .global__mart:before, #indicies__page.new__d .global__mart:after,
#indicies__page.new__d .ind__banner:before, #indicies__page.new__d .ind__banner:after{
    height: 0.5px !important;
}
#indicies__page.new__d .ind__banner, #indicies__page.new__d .ind__fq,
#indicies__page.new__d .sieze__ppp{
    background: transparent !important;
}
#indicies__page.new__d .sieze__ppp:before, #indicies__page.new__d .ind__fq:before,
#indicies__page.new__d .global__mart:before, #indicies__page.new__d .ind__banner:before{
    left: 2.05% !important;
}
/* #indicies__page.new__d .ind__fq{
    background: url(https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689492622/indices_ellipse2_nk0ofm.png) !important;
    background-size: 100% 100% !important;
    background-position: center top !important;
} */


/* Media queries starts from here */
@media screen and (max-width: 1360px){
    .col.mgg img{
        width: 49% !important;
        max-width: unset !important;
    }
    .tvt__f h1{
        width: 80% !important;
    }
}
@media screen and (max-width: 1200px){
    .tvt__f h1{
        width: 80% !important;
    }
    .points__ff{
        column-gap: 10px !important;
    }
    .col.tvt__f {
        flex: 0 0 48% !important;
    }
    .col.mgg{
        flex: 0 0 52% !important;
    }
    .indices__hdd{
        padding: 40px 0px !important;
    }
}
@media screen and (max-width: 1080px){
    .indices__hdd .tvt__f h1{
        font-size: 32px;
        width: 90% !important;
        margin-bottom: 20px !important;
    }
    .points__ff span{
        font-size: 13px !important;
        padding: 6px 12px !important;
    }
    .points__ff{
        margin-bottom: 15px !important;
    }
    .hh__btn{
        padding-top: 10px !important;
    }
    .hh__btn a.up_sin{
        width: 125px !important;
        height: 40px !important;
        font-size: 14px !important;
    }
    .hh__btn a.get__ap{
        font-size: 14px !important;
        min-width: 130px !important;
        height: 40px !important;
    }
}

@media screen and (max-width: 950px){
    #indicies__page .indices__hdd .row{
        display: block;
    }
    /* .col.mgg{
        margin-top: 50px;
    } */
}
@media screen and (max-width: 800px){
    .indices__hdd{
        padding: 0px 0px 50px 0px !important;
    }
    .col.mgg video{min-height: 420px;}
    .hh__btn{justify-content: center;}
    #indicies__page .trade__derivative .row.most_deri{
        display: flex !important;
    }
    .indices__hdd .tvt__f h1{
        font-size: 26px !important;
        line-height: 32px !important;
        text-align: center;
        margin: 0 auto !important;
        margin-bottom: 20px !important;
    }
    .tvt__f{
        display: none !important;
    }
    .col.tvt__f.mobile{
        display: block !important;
        padding-top: 20px !important;
    }
    #indicies__page .points__ff{
        column-gap: 5px !important;
        margin-bottom: 10px !important;
        justify-content: center !important;
    }
    .trade__derivative{
        padding: 0px !important;
    }
    .points__ff span{
        font-size: 12px !important;
        padding: 5px 10px !important;
    }
    .tiy__bd{
        justify-content: center !important;
    }
    .sieze__ppp .tiy__bd a.download, .sieze__ppp .tiy__bd a.sign__up{
        width: 110px;
        height: 36px;
        font-size: 14px;
        padding: 0px !important;
    }
    .sieze__ppp .tiy__bd a.sign__up{
        min-width: 100px;
        padding: 0px 15px !important;
        width: inherit !important;
    }
    .sieze__ppp{
        padding: 50px 0px 0px 0px !important;
    }
    .global__mart h1.fg__rer {
        text-align: center !important;
    }
    #indicies__page .global__row.row {
        flex-direction: column;
    }
    #indicies__page .global__row img {
        width: 70px;
        height: 70px;
    }
    #indicies__page .global__row h2 {
        font-size: 18px !important;
        line-height: 28px !important;
        min-height: unset !important;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    #indicies__page .global__row.row .col {
        min-height: unset;
    }
    #indicies__page .global__row p {
        font-size: 14px;
        line-height: 20px;
    }
    .trading__platform h1.fg__rer {
        text-align: center !important;
        margin-bottom: 0px !important;
    }
    .trading__platform .tra__go h2 {
        font-size: 20px !important;
        line-height: 26px;
    }
    .trading__platform .plat__ggre.row{
        padding: 0px;
    }
    .trading__platform .tra__go svg {
        width: 30px;
        height: 32px;
    }
    .trading__platform .tiy__bd a.download {
        width: 110px;
        height: 36px !important;
        font-size: 14px;
        padding: 0px !important;
    }
    .trading__platform .tiy__bd a.sign__up {
        font-size: 14px;
        width: 110px;
        height: 36px !important;
        padding: 0px !important;
    }
    .plat__ggre.row .col{
        flex: 0 0 100% !important;
    }
    .pho__img img{
        max-height: 300px !important;
    }
    .trading__platform .trg__ff__fe .tiy__bd{
        margin-bottom: 40px !important;
    }
    #indicies__page .trading__platform .col.trg__ff__fe {
        padding-top: 30px !important;
    }
    .pla__img, .pho__img, .ipad__gg{
        max-height: unset !important;
        min-height: unset !important;
    }
    .pla__img img, .ipad__gg img{
        max-width: 300px !important;
    }
    .mrkt__cfds h1.fg__rer {
        text-align: center !important;
    }
    .mrkt__cfds .assets__ro.row {
        flex-direction: column;
    }
    .mrkt__cfds .assets__ro.row .col p {
        font-size: 14px;
        padding: 0px;
    }
    .trade__cfd h1.fg__rer {
        text-align: center !important;
    }
    div#section__7 {
        padding: 30px 0px 70px 0px !important;
    }
    #indicies__page .row_ban .col {
        width: 100%;
        flex: 0 0 100%;
        min-height: 200px;
        max-height: 200px;
    }
    #indicies__page .ccc_tt p {
        font-size: 16px;
        line-height: 22px;
    }
    #indicies__page .ccc_tt{
        height: 200px !important;
        padding: 25px 0px 25px 25px !important;
    }
    #indicies__page .ccc_tt a {
        width: 110px;
        height: 36px;
        font-size: 14px;
    }
    #indicies__page .col.banner_1 {
        margin-bottom: 30px;
    }
    #indicies__page .contt p{
        font-size: 14px !important;
        line-height: 20px !important;
    }
    #indicies__page .row_ban{
        padding: 50px 0px !important;
    }
    #indicies__page .ind__banner{
        padding: 0px !important;
        display: none;
    }
    .indices__hdd{
        background: #000000 !important;
        padding-bottom: 50px !important;
    }
    #indicies__page .mbl__stng strong {
        font-family: 'roboto-light' !important;
        font-weight: 100 !important;
    }
    .ind__cont{
        background: transparent !important;
    }
    .most_deri .col{
        padding: 20px 17px !important;
    }
}