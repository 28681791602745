.roadMap_slider {
    padding: 100px 0px 100px 0px;
    position: relative;
    margin-top: 1px;
}
.roadMap_slider:before{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
.roadMap_slider:after{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
.road-head {
    font-size: 40px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    text-align: center;
    margin-bottom: 80px;
}
.single_map h3 {
    font-size: 16px !important;
    font-family: 'urbanist-semibold' !important;
    line-height: 24px !important;
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
}
.single_map p{
    font-size: 14px !important;
    font-family: 'urbanist-light' !important;
    line-height: 19px !important;
    text-align: center;
    color: #FFFFFF;
    width: 80%;
    margin: 0 auto;
}
.border-map {
    width: 100%;
    height: 5px;
    background: #E6E6E6;
    margin-top: 20px;
    position: relative;
}
.border-map.active{
    background: #5ED3F0;
}
.border-map:before {
    content: '';
    background: #e6e6e6;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    border-radius: 50px;
    border: 2px solid #FFFFFF;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -8px;
}
.border-map.active:before{
    background: #5ED3F0;
}
.roadMap_slider .carousel .road_slider {
    max-height: 200px;
}
.roadMap_slider .carousel{
    position: relative;
    text-align: center;
}
.roadMap_slider .carousel button {
    background: none;
    border: none;
    padding: 20px !important;
}
.roadMap_slider .carousel button.tokenback{
    transform: rotate(180deg);
}
.roadMap_slider .carousel button:disabled{
    opacity: 0.6 !important;
    display: unset !important;
}
.carousel.mobile {
    display: none;
}
.single_map p span {
    display: block;
}
@media screen and (max-width: 800px){
    .carousel.mobile {
        display: block;
    }
    .carousel.desktop {
        display: none;
    }
    .road-head {
        font-size: 26px;
        margin-bottom: 50px;
    }
    .single_map p{
        width: 100%;
        padding: 0px 10px;
        font-size: 12px !important;
        line-height: 16px !important;
        padding-top: 5px !important;
    }
    .single_map h3{
        font-size: 14px !important;
    }
    .roadMap_slider .carousel button{
        margin-top: 40px;
    }
}