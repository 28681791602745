.blo__btns {
    padding: 100px 0px !important;
}
.blg__btn {
    display: flex;
    flex-flow: wrap;
    gap: 15px;
    justify-content: center;
    margin: 0;
}
.blo__btns h1 {
    text-align: center;
    font-size: 52px;
    font-family: 'roboto-light';
    font-weight: 300 !important;
    line-height: 56px;
    margin-bottom: 30px;
}
.blg__btn a {
    font-size: 16px;
    font-family: 'urbanist-regular';
    text-transform: capitalize;
    color: #333;
    border: 1px #979797 solid;
    border-radius: 50px;
    padding: 5px 20px;
    min-width: 100px;
    max-width: fit-content;
}
.blg__btn a:hover{
    background: #000000;
    border-color: #000000;
    color: #FFFFFF;
}
.blg__btn a:focus, .blg__btn a:focus-visible{
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

/* Listing Section styling starts from here */
.nsghts.row {
    margin: 0px;
    display: flex;
    justify-content: space-between;
    margin: 0px 50px;
}
.nsghts.row .lft_br{
    padding: 0px;
    flex: 0 0 68% !important;
}
.nsghts.row .rght_br{
    padding: 0px 0px 30px 0px;
    flex: 0 0 28% !important;
    height: fit-content;
}
.art_grf.row {
    margin: 0px;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}
.art_grf.row a.col{
    flex: 0 0 47% !important;
    padding: 0px !important;
    text-decoration: none;
}
.art_grf.row a.col img {
    width: 100%;
    object-fit: cover;
    transition: .3s;
    height: 250px;
}
.art_grf.row a .fdd-d{
    min-height: 250px;
    max-height: 250px;
    overflow: hidden;
}
.art_grf.row a:hover img{-webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.col.rght_br {
    background: #2c2e39;
}
.art_grf.row a.col p.dat {
    font-size: 14px;
    font-family: 'roboto-light';
    padding: 20px 0px 10px 0px;
    color: #666666;
}
.art_grf.row a.col h1 {
    font-size: 22px;
    font-family: 'roboto-light';
    color: #000000;
    line-height: 28px;
    /* min-height: 60px; */
    margin-bottom: 10px;
}
.art_grf.row a.col p {
    font-size: 14px;
    font-family: 'roboto-light';
    color: #000000;
    line-height: 22px;
    letter-spacing: 0px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
a.red__mor {
    font-size: 16px;
    font-family: 'roboto-light';
    text-transform: capitalize;
    color: #333;
    border: 1px #979797 solid;
    border-radius: 50px;
    padding: 10px 20px;
    min-width: 140px;
    margin: 50px 0 0px 0;
}
h1.tile_hed {
    font-size: 32px;
    font-family: 'roboto-light';
    color: #333;
    margin: 0px 0px 20px 0px;
}
.pst__lstng {
    padding: 0px 0px 50px 0px;
}
a.red__mor:hover{
    background: #000000;
    border-color: #000000;
    color: #FFFFFF;
}
a.red__mor:focus, a.red__mor:focus-visible{
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
.category_se {
    margin-bottom: 60px;
}
.full__blog {
    padding: 0px;
}
.full__blog .art_grf.row a.col {
    flex: 0 0 31% !important;
    padding: 0px !important;
    text-decoration: none;
}
.article-page .pagination {
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
}
.article-page .blog__articles .page-item.active .page-link{
    background: #000000;
    background-color: #000000;
    border-color: #000000;
}
.article-page .page-item.disabled{
    color: #dee2e6;
}
.article-page .pagination li a {
    color: #000000;
}


/* SideBar Settings  */
.bardd .fdd-d img {
    width: 100%;
}
.bardd a.col {
    margin: 20px !important;
    display: block;
    background: #373945;
    padding-bottom: 20px;
}
h1.ma__rere {
    font-size: 24px;
    font-family: 'hauora-light';
    color: #FFFFFF;
    padding: 25px 10px 5px 10px;
    text-align: center;
}
.bardd a.col p.dat {
    font-size: 14px;
    color: #FFFFFF;
    padding: 10px 15px;
    font-family: 'roboto-light';
}
.bardd a.col h1 {
    font-size: 20px;
    font-family: 'roboto-light';
    color: #FFFFFF;
    padding: 0px 15px 10px 15px;
    line-height: 26px;
}
.bardd a.col p {
    font-size: 14px;
    font-family: 'roboto-light';
    color: #FFFFFF;
    line-height: 22px;
    letter-spacing: 0px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 15px;
}



@media screen and (max-width: 800px){
    .article-page .blg__btn, .single__page .blg__btn{
        display: none;
    }
    .blo__btns h1 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 0px;
    }
    .blo__btns{
        padding: 50px 0px 50px 0px;
    }
    #ncx__blgg .blo__btns h1{
        margin-bottom: 30px;
    }
    .blg__btn{
        gap: 10px;
    }
    .blg__btn a {
        font-size: 12px;
        padding: 5px 15px;
        min-width: 110px;
    }
    .nsghts.row {
        display: block;
    }
    .nsghts.row .rght_br{
        display: none;
    }
    .art_grf.row{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    a.red__mor{
        margin: 40px 0px 0px 0px;
        min-width: 130px;
        width: 100%;
        height: 44px;
    }
    h1.tile_hed{
        font-size: 26px;
        line-height: 32px;
    }
    .articles__gg .wrap{
        flex-direction: column !important;
        margin: 0px !important;
    }
    .articles__gg .wrap .article{
        width: 100% !important;
    }
    .article-page .pagination{
        margin-top: 0px !important;
        margin-bottom: 50px !important;
    }
    .article-wrap{
        width: 100% !important; 
        margin: 0px !important;
        padding: 0px !important;
    }
    .single__page{
        padding: 50px 0px !important;
    }
    h1.article-title{
        font-size: 26px !important;
        line-height: 32px !important;
        margin-bottom: 20px !important;
    }
}