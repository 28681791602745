#stake_ncxT {
    background: #000000;
    padding: 100px 0px;
    position: relative;
    margin-top: 1px;
}
#stake_ncxT .row{
    margin: 0 auto !important;
}
.stake_ncx h1 {
    font-size: 32px;
    font-family: 'urbanist-regular';
    color: #FFFFFF;
    padding-left: 5px;
}
#presale_ncxT .stake_ncx h1 span {
    font-family: 'urbanist-extralight' !important;
}
#presale_ncxT .stake_ncx{
    display: flex;
    align-items: center;
}
#presale_ncxT .stake_ncx svg {
    width: 150px;
    height: 33px;
}
#presale_ncxT .stake_ncx h1{
    font-size: 33px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    padding-left: 5px;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid #FFFFFF;
}
.tokentable-stake{
    padding: 50px 0px 0px 0px;
}
.tokenhead-stake{
    display: flex;
    justify-content: space-between;
}
.tokenhead-stake p {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    border-bottom: 1px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    width: 13.5%;
    margin-bottom: 15px;
    padding-right: 10px;
}
.tokenhead-stake p.q1:nth-child(1) {
    width: 16%;
    margin-left: 0px;
}
.tokenhead-stake p.q1:nth-child(9){
    margin-right: 0px;
}
.tokencolum-stake {
    display: flex;
    justify-content: space-between;
}
#ncx_token_Page .color {
    background: rgba(31, 31, 31, 0.6);
    padding: 10px 10px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 0px;
}
.color div {
    width: 23px;
    height: 23px;
    border-radius: 50%;
}
.colstake:nth-child(1){
    width: 16%;
}
.colstake:nth-child(1) p {
    background: none;
    padding: 0px !important;
    height: inherit;
    margin-bottom: 0px !important;
}
.colstake {
    width: 13.5%;
}
.colstake p{
    background: rgba(31, 31, 31, 0.6);
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    height: 43px;
    margin-bottom: 5px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    margin-right: 0px;
}
.colstake.ticken p.checkmark{
    justify-content: center;
}
.tokentable-stake p.checkmark {
    justify-content: center;
}
.colnine p {
    height: 89px;
    margin-right: 0px !important;
}

.mobile-tokentable{
    display: none;
}
p.height_q {
    height: 91px;
}
p.black.height_q{
    height: 187px;
}
video#NCX_Token_mobile {
    display: none;
}
h1.wegwscd{
    font-size: 23px !important;
}
@media screen and (max-width: 800px){
    .tokentable-stake{
        display: none;
    }
    .mobile-tokentable{
        display: block;
    }
    #stake_ncxT{
        padding: 50px 0px !important;
    }
    video#NCX_Token_mobile {
        display: none;
        width: 100%;
        margin-bottom: 50px;
    }
}



/* ********************************* NCXT PreSale Styling ********************************* */
div#presale_ncxT {
    padding: 50px 0px;
}
#presale_ncxT .colstake:nth-child(1), #presale_ncxT .tokenhead-stake p.q1:nth-child(1){
    width: 15% !important;
}
#presale_ncxT .colstake:nth-child(2), #presale_ncxT .colstake:nth-child(3), #presale_ncxT .tokenhead-stake p.q1:nth-child(2),
#presale_ncxT .tokenhead-stake p.q1:nth-child(3){
    width: 14% !important;
}
#presale_ncxT .colstake:nth-child(4), #presale_ncxT .tokenhead-stake p.q1:nth-child(4){
    width: 25% !important;
}
#presale_ncxT .colstake:nth-child(5), #presale_ncxT .colstake:nth-child(6), #presale_ncxT .colstake:nth-child(7),
#presale_ncxT .tokenhead-stake p.q1:nth-child(5), #presale_ncxT .tokenhead-stake p.q1:nth-child(6), #presale_ncxT .tokenhead-stake p.q1:nth-child(7){
    width: 15% !important;
}
#presale_ncxT.ncx__vewd .colstake .color svg {
    width: 30px;
}

@media screen and (max-width: 800px){
    #presale_ncxT.ncx__vewd .mbl-tokrowitems .color{
        width: 15.5% !important;
    }
    #presale_ncxT.ncx__vewd .mbl-tokrowitems.vsdc .color p {
        padding-top: 10px !important;
    }
}