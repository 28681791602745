.buycrypto-page {
    padding: 0px 0px;
    /* background: #000000; */
}
.buycrypt-row {
    display: flex;
    margin: 0px;
    align-items: center;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.08));
    background: #FFFFFF;
}
#buyCryptoPage .ml__vwxf .insse h1 {
    width: 370px;
}
.reward-center p a {
    color: #FFFFFF;
    text-decoration: none;
}
.reward-center p a:hover{
    color: #E1C8FF;
    text-decoration: none;
}
.buycrypt-row .col {
    width: 50%;
    flex: inherit;
    padding: 0px;
}
.crypto-assets.row {
    display: flex;
    margin: 0px !important;
    column-gap: 10px;
    margin-top: 40px !important;
}
.crypto-assets.row:before, .crypto-assets.row:after{
    display: none;
}
.crypto-assets.row .col.coin-details {
    width: 31%;
}
.col.buysell-sect{
    text-align: center;
}
.col.buysell-sect img{
    width: 100% !important;
    max-width: 450px;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.2));
}
/* .col.coinsect {
    padding: 9% 9% 9% 8%;
} */
.col.coinsect {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fFFFFF;
    filter: drop-shadow(50px 0px 80px rgba(0, 0, 0, 0.11));
}
.inner__cc {
    max-width: 530px;
}
.col.coinsect h1 {
    font-size: 58px;
    font-family: 'hauora-regular';
    width: 80%;
    line-height: 80px;
    margin-bottom: 20px;
    color: #000000;
}
.inner__cc h1 span {
    position: relative;
    width: fit-content;
    display: inline-block;
}
.inner__cc h1 span:before {
    content: '';
    height: 15px;
    background: rgba(148, 82, 236, 0.5);
    display: block;
    position: absolute;
    width: 100%;
    bottom: 12px;
}
.col.coinsect p {
    font-size: 16px;
    line-height: 24px;
    font-family: 'hauora-light';
    color: #747989;
    text-align: justify;
    max-width: 80%;
}
a.b__y {
    width: 160px;
    height: 46px;
    background: linear-gradient(180deg, #9452EC 0%, #6552C7 100%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'urbanist-regular';
}
a.mrkt {
    width: 150px;
    height: 46px;
    background: #F0F0F1;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: 'urbanist-regular';
    font-size: 14px;
}
.buy__bsx {
    display: flex;
    gap: 10px;
    margin-top: 40px;
}
.coin-graph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.coin-graph svg {
    width: 32px;
    height: auto;
}
.coin-details h1 {
    font-size: 14px !important;
    font-family: 'hauora-regular' !important;
    width: 100% !important;
    margin-bottom: 5px !important;
    line-height: 16px !important;
    color: #000000 !important;
}
.coin-details p {
    font-size: 14px !important;
    font-family: 'hauora-medium' !important;
    line-height: 16px !important;
    color: #000000 !important;
}
.col.coin-details {
    border: 0.5px solid rgb(239, 239, 239);
    background: #FFFFFF;
    min-height: 150px;
    padding: 15px;
    border-radius: 5px;
}
.coin-details .change {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #65b07a !important;
    column-gap: 5px;
}
#buyCryptoPage .currencies-options span.balance, #buyCryptoPage .currencies-options p {
    color: #000000 !important;
}
#ncxbuycrypto-desk video {
    width: 100% !important;
    height: 100% !important;
    min-height: 730px;
    object-fit: cover;
    object-position: right bottom;
    margin-bottom: -5px;
}
.buycrypto-page .reward-row{
    background: #000000;
}


#mbl_cr__sd{
    display: none;
}

@media screen and (max-width: 800px){
    #buyCryptoPage .earingingcomplet-sect{
        padding: 50px 0px;
    }
    .buycrypto-page{
        padding: 0px 0px 20px 0px;
    }
    .container990 {
        padding: 50px 20px 0px 20px !important;
    }
    #ncxbuycrypto-desk video{
        min-height: inherit !important;
        max-height: inherit !important;
    }
    .buycrypt-row{
        display: block;
    }
    .buycrypt-row .col.coinsect, .buycrypt-row .col.buysell-sect {
        width: 100%;
    }
    .buycrypt-row .col.coinsect{
        padding: 0px;
    }
    .col.coinsect h1{
        width: 100% !important;
        font-size: 52px !important;
        line-height: 70px !important;
        margin-bottom: 10px;
    }
    .crypto-assets.row{
        margin-top: 30px !important;
        column-gap: inherit !important;
        justify-content: space-between;
    }
    .crypto-assets.row .col.coin-details{
        padding: 15px 10px 10px 10px !important;
        min-height: inherit !important;
        max-height: 130px;
    }
    .coin-graph svg{
        width: 22px;
        height: auto;
    }
    .coin-details h1{
        font-size: 13px !important;
    }
    .coin-details p{
        font-size: 12px !important;
    }
    .coin-details .change{
        margin-top: 3px;
    }
    .buycrypt-row .col.buysell-sect{
        margin-top: 30px;
    }
    .getocodesect{
        padding-top: 30px;
    }

    #mbl_cr__sd{
        display: block;
        background: #000000 !important;
        padding-bottom: 20px !important;
    }
    #dek__top{
        display: none;
    }
    #mbl_cr__sd .buycrypt-row, #mbl_cr__sd .col.coinsect{
        background: transparent !important;
        filter: none !important;
    }
    #mbl_cr__sd .col.coinsect h1 {
        font-size: 32px !important;
        line-height: 32px !important;
        margin-bottom: 10px !important;
        color: #FFFFFF !important;
        font-family: 'hauora-medium' !important;
    }
    #mbl_cr__sd .col.coinsect p {
        font-size: 14px !important;
        line-height: 22px !important;
        max-width: 100% !important;
        color: #FFFFFF !important;
        text-align: left !important;
    }
}