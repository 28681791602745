.mbl__sec__cr .carousel__dot-group {
    text-align: center;
}
.mbl__sec__cr button.carousel__dot--selected {
    width: 40px !important;
    background: #FFFFFF !important;
    border: 2px solid #FFFFFF !important;
    border-radius: 50px !important;
}
.mbl__sec__cr button.carousel__dot--selected span{
    background: #FFFFFF;
}
.mbl__sec__cr .carousel__dot-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}
.mbl__sec__cr button.carousel__dot {
    width: 7px;
    border: none;
    background: rgba(255,255,255,0.5);
    border-radius: 50px;
    padding: 0px;
    height: 4px;
}
.mbl__sec__cr button.carousel__dot:hover{
    width: 20px;
    background: rgba(255,255,255,0.5);
    transition: all 0.3s;
}
@media screen and (max-width: 800px){
    .bg-dark .global__row.row {
        display: none;
    }
    .bg-dark .mbl__sec__cr .global__row.row {
        display: block !important;
    }
    .mbl__sec__cr .global__row .carousel__slide {
        padding: 10px 30px !important;
    }
    .mbl__sec__cr .global__row.row .col{
        min-height: 280px;
    }
    .mbl__sec__cr .global__row p{
        margin-bottom: 0px;
    }
}