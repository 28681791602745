/* .blogList-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }
  
  @media (max-width: 768px) {
    .blogList-wrap {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .blogList-wrap {
      grid-template-columns: repeat(1, 1fr);
    }
  }
   */


#insight_page:before, #insight_page:after{
  display: none;
}
.blogItem-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: .3s;
}
.blog-image{
  height: 230px;
  overflow: hidden;
  width: 380px;
  min-width: 380px;
}
.blog-image a:hover img {
  transform: scale(1.1);
}
p.chip {
  display: none;
}
.blogItem-wrap footer {
  display: none;
}
.blogItem-wrap {
  display: flex;
}
.blogItem-wrap {
  display: flex;
  column-gap: 40px;
}
.blogList-wrap .blogItem-wrap {
  margin-bottom: 40px;
}
.blog-heading {
  padding: 10px 0px;
}
.blog-heading p {
  font-size: 14px;
  line-height: 20px;
  font-family: 'urbanist-regular';
}
.ttdfe h3 {
  font-size: 26px;
  line-height: 32px;
  font-family: 'urbanist-medium';
  margin: 10px 0px;
}
.blog-desc p {
  font-size: 16px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'urbanist-regular';
  /* min-height: 69px; */
}
.blog-desc p a{
  color: #000000 !important;
}
.blogItem-link {
  width: fit-content;
  display: block;
  text-align: end;
  font-size: 16px;
  font-family: 'urbanist-regular';
  color: #34A9FF;
  text-decoration: none;
  margin: 0 auto;
  margin-left: 0px;
  margin-top: 12px;
}
.blogItem-link:hover{
  text-decoration: none;
  color: #34A9FF;
}
a.blogItem-link:hover svg {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: move;
  animation-iteration-count: infinite;
  animation-name: move;
  animation-timing-function: ease-out;
}
a.blogItem-link svg {
  margin-left: 10px;
  vertical-align: middle;
}
a.blogItem-link svg path{
  fill: #34A9FF;
}
@keyframes move{
  0%{
    transform:translateX(0);
  }
  20%{
    transform:translateX(10px);
  }
}
div#insight_page {
  display: flex;
  column-gap: 35px;
  padding: 100px 20px !important;
  position: relative;
}
div#fixed_sticky_insights.sticky {
  position: sticky;
  top: 100px;
}
/* #insight_page .blog.blog-stick {
  margin-left: 255px;
} */
div#insight_page.container.sefeds {
  position: relative;
  display: flex;
}
#insight_page .blog {
  padding-left: 35px;
  border-left: 1px solid #eee;
}
.blog_heading ul {
  padding: 0px;
  margin: 0px;
  margin-top: 40px;
}
.blog_heading ul li {
  list-style-type: none;
  padding: 8px 0px;
}
.blog_heading ul li a {
  font-family: 'urbanist-regular';
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.blog_heading h1 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: 'urbanist-regular';
}
.blog_heading .searchBar-wrap {
  margin-top: 10px;
} 
.blog_heading button.btn01.signup {
  width: 80% !important;
  margin-top: 30px;
}
/* .blogList-wrap .blogItem-wrap {
  transform: rotate(-180deg);
}
.blogList-wrap {
  transform: rotate(180deg);
} */



@media screen and (min-width: 1300px){
  div#insight_page{
    padding: 100px 0px !important;
  }
  #BlogdetailsHeader div#insight_page {
    padding: 50px 20px !important;
  }
}

@media screen and (max-width: 800px){
  .blog-header .row .row-col{
      width: 100% !important;
  }
  .blog-page .blog-header .row-col:nth-child(1){
      padding: 0px !important;
  }
  .blog-header h1{
      font-size: 26px;
      line-height: 32px;
  }
  .blog-header input[type=email]{
      width: 60%;
      padding-bottom: 10px;
      border-radius: 0px !important;
  }
  div#insight_page{
      display: block !important;
      width: 100% !important;
      padding: 50px 20px !important;
  }
  #insight_page .blog_heading{
      width: 100% !important;
      max-width: inherit !important;
  }
  #insight_page .blog_heading h1, #insight_page .blog_heading p, #insight_page .blog_heading button.signup, 
  #insight_page .blog_heading .social-links{
      display: none;
  }
  #insight_page .blog .blogItem-wrap {
      margin-bottom: 20px;
      column-gap: 20px !important;
      width: 100% !important;
  }
  #insight_page .blog .blogItem-wrap .blog-image {
      width: 40% !important;
      min-width: inherit;
      height: inherit;
  }
  #insight_page .blog .blogItem-wrap .blog-heading {
      width: 60%;
      padding: 0px !important;
  }
  #insight_page .blog-desc, .blog-heading p {
      display: none;
  }
  .ttdfe h3{
      margin-top: 0px !important;
  }
  .blogItem-link{
      margin-top: 8px !important;
  }
  #insight_page .ttdfe h3 {
      font-size: 16px !important;
      line-height: 24px !important;
  }
  div#insight_page.container .blog{
      padding-top: 30px;
      padding-left: 0px;
      border-left: 0px !important;
  }
  .blog-header button{
      width: 30% !important;
      top: 0px;
  }
  .header-form .newsletter_form button p{
    padding: 8px 0px !important;
  }
  div#fixed_sticky_insights.sticky {
    position: inherit !important;
    top: inherit !important;
  }
  #insight_page .blog.blog-stick {
    margin-left: inherit !important;
  }
  div#insight_page.container.sefeds {
    position: relative;
    display: flex;
  }
  #fixed_sticky_insights section#slider-blog {
    display: none;
  }
  #BlogdetailsHeader #insight_page .container {
    padding: 0px !important;
  }
  #BlogdetailsHeader #insight_page .container .col.second {
    padding: 0px !important;
    border: none !important;
  }
  .blogItem-cover{
    min-height: 105px;
  }
  .blog-subCategory{
    display: none;
  }
  .cover-image {
    margin-bottom: 20px;
  }
  .profile h1 a {
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    font-family: 'urbanist-regular';
  }
}