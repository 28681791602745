/************************** HomePage First Section Starts from here *************************/
.hero-section{
    background-color: #000000 !important;
}
/* ********************************************* */

/* ********************************************* */
#hero-video {
    width: 100% !important;
    max-width: 270px;
}
.hero-section .bsss {
    width: 45%;
}
.hero-section .asss {
    width: 55%;
    padding-top: 70px;
    padding-left: 50px;
}
.hero-section{
    padding: 30px 0px 100px 0px;
}
.bsss{
    text-align: center;
}
.asss h1 {
    font-size: 43px;
    line-height: 50px;
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    margin-bottom: 2px;
}
.hero-section .asss img {
    padding: 20px 0px;
}
.hero-section .asss p {
    font-size: 16px;
    font-family: 'urbanist-light';
    color: #FFFFFF;
    line-height: 22px;
}
.hashtag{
    padding-bottom: 10px;
    column-gap: 10px;
    display: flex;
}
.hashtag span {
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'urbanist-regular';
    background: #212121;
    padding: 5px 15px;
    border-radius: 50px;
    width: fit-content !important;
}
.btn-signin {
    line-height: 24px !important;
    width: 115px !important;
    height: 38px !important;
    color: #FFFFFF !important;
    background: #24A9FF !important;
    position: relative !important;
    visibility: visible !important;
    border-radius: 7px !important;
    font-size: 16px !important;
    font-family: 'urbanist-regular' !important;
    text-transform: capitalize !important;
}
.btn-singup {
    line-height: 24px !important;
    width: 160px !important;
    height: 38px !important;
    color: #FFFFFF !important;
    background: #24A9FF !important;
    position: relative !important;
    visibility: visible !important;
    border-radius: 7px !important;
    font-size: 16px !important;
    font-family: 'urbanist-regular' !important;
    text-transform: capitalize !important;
}

/************************** HomePage Second Nnumbering Section Starts from here *************************/
/* Number Update Section */
.currencies-options.row {
    max-width: 1300px;
    margin: 0 auto;
}
.currencies-options span.balance{
    font-size: 38px !important;
    line-height: 44px !important;
    font-family: 'urbanist-medium' !important;
    display: block;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
}
.currencies-options p{
    font-family: 'urbanist-light' !important;
    font-size: 16px !important;
    display: block;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
}
.bar-grade33{
    margin-bottom: 60px !important;
    overflow: hidden !important;
    margin-top: 30px;
    padding: 0px !important;
}
.btnsdc {
    display: flex;
    column-gap: 12px;
    align-items: center;
    margin-top: 20px;
}
.img001 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    width: 67px;
    height: 34px;
    border: 1px solid #FFFFFF;
    border-radius: 7px
}
.img001 svg path {
    fill: #FFFFFF;
}
@media screen and (min-width: 801px){
    .hashtag.mobile {
        display: none;
    }
    .mobile-currenciesrow{
        display: none;
    }
}
.img001{
    display: none;
}
img.mbl-pic {
    display: none;
}
#buy_ssd_dd{
    display: none;
}
#NCX_poweredby #buy_ssd_dd {
    display: block;
}
@media screen and (max-width: 800px){
    div#NCX_poweredby {
        display: none;
    }
    #buy_ssd_dd{
        display: block;
    }
    .hero-section .row.container {
        display: block !important;
    }
    img.mbl-pic {
        display: block;
    }
    .hashtag{
        display: none;
    }
    .hashtag.mobile{
        display: flex !important;
        column-gap: 5px !important;
    }
    .hashtag.mobile span {
        font-size: 13.5px;
        padding: 5px 8px;
    }
    .hero-section .asss h1{
        padding: 0px !important;
        font-family: 'urbanist-medium';
    }
    .hero-section .asss h1 span{
        font-family: 'urbanist-light' !important;
    }
    .btn-signin{
        width: 100px !important;
        height: 36px !important;
        font-size: 14px !important;
    }
    .btn-singup{
        width: 120px !important;
        height: 36px !important;
        font-size: 14px !important;
    }
    .hero-section .asss{
        padding-left: 0px;
        padding-top: 0px !important;
        padding-right: 0px !important;
    }
    .img001{
        display: flex !important;
        height: 36px !important
    }
    .btnsdc{
        margin-top: 10px !important;
    }
    img.desk-pic {
        display: none;
    }
    .hero-section .asss p{
        margin-bottom: 20px;
    }
    div#videosection{
        width: 100% !important;
    }
    #hero-video{
        max-width: 300px;
        border-radius: 50px;
    }
    .currencies-options.row {
        padding: 0px 20px;
    }
    .bar-grade33{
        width: 100% !important;
        margin-bottom: 30px !important;
        padding: 0px !important;
    }
    .currencies-options.row .col{
        display: none;
    }
    .mobile-currenciesrow .onembl .col {
        display: block !important;
        width: 50% !important;
    }
    .mobile-currenciesrow{
        padding: 0px !important;
    }
    .mobile-currenciesrow .onembl {
        display: flex !important;
        column-gap: 60px;
        margin-bottom: 30px;
    }
    .mobile-currenciesrow .onembl span.balance {
        font-size: 28px !important;
        line-height: 38px !important;
        text-align: center !important;
        display: block;
        color: #FFFFFF !important;
    }
    .mobile-currenciesrow .onembl p{
        font-size: 14px !important;
        line-height: 18px !important;
        text-align: center !important;
        padding: 0px 15px !important;
    }
}
@media screen and (max-width: 380px){
    .hashtag.mobile span{
        font-size: 12px !important;
        padding: 5px 10px !important;
    }
    body .cardvideo-sect #ncxcard_cardset .ncx-card-trade .bullets span {
        font-size: 13px !important;
    }
}


/* ********************************** */
#homecarouselslides div#powered_by {
    background: none !important;
}
#homecarouselslides #ncx_buycrypto_video video{
    max-height: 600px !important;
    object-fit: cover !important;
}
#homecarouselslides .ncxapp-section .row .col:first-child {
    padding: 50px 0px 50px 0px !important;
}
#homecarouselslides .ncxapp-section .row .col:first-child h1 {
    color: #FFFFFF !important;
    margin-bottom: 10px !important;
}
#homecarouselslides #powered_by.ncxapp-section .row .col:first-child p{
    color: #FFFFFF !important;
}
#NCX_poweredby div#buy_ssd_dd {
    margin: 0px !important;
}