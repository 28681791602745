.card_box.nrtbv__gerg{
    position: relative !important;
}
.card_box.nrtbv__gerg .col.vedc:nth-child(2) {
    padding-left: 100px;
}
.card_box.nrtbv__gerg ul {
    padding: 0px !important;
}
.card_box.nrtbv__gerg h1 {
    width: 100% !important;
}
.card_box.nrtbv__gerg .container:after{
    display: none;
}
.card_box.nrtbv__gerg:before, .bg-dark #compare-cards:before{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
.card_box.nrtbv__gerg:after, .bg-dark #compare-cards:after{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
a.bgb_bf {
    background: transparent;
    font-size: 16px;
    font-family: 'urbanist-regular';
    text-transform: inherit;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    width: 160px;
    height: 44px;
    border-radius: 70px;
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 15px rgba(255,255,255,0.5);
}
a.bgb_bf:hover, a.bgb_bf:focus, a.bgb_bf:focus-visible, a.bgfgb:hover, a.bgfgb:focus, a.bgfgb:focus-visible{
    text-decoration: none;
    box-shadow: none;
    outline: none;
}
a.bgb_bf:hover{
    background: #FFFFFF;
    color: #000000;
}
a.bgfgb {
    background: transparent;
    font-size: 16px;
    font-family: 'urbanist-regular';
    text-transform: inherit;
    color: #FFFFFF;
    letter-spacing: 0;
    width: 130px;
    height: 44px;
    border-radius: 70px;
    border: 1px solid #000000
}
a.bgfgb:hover{
    border-color: #5ED3F0;
}
.card_box.nrtbv__gerg .vwecx{
    gap: 10px
}
.slvs .inservv:after{
    display: none;
}

@media screen and (max-width: 800px){
    .card_box.nrtbv__gerg .col.vedc:nth-child(2) {
        padding-left: 0px;
        margin-top: 40px;
    }
    #bgf__qas .new__crf{
        display: block;
        margin-top: 0px;
    }
    #bgf__qas .bgt_hyn {
        display: none;
    }
    #bgf__qas .bgt_hyn video, #bgf__qas .bgt_hyn, #bgf__qas .new__crf {
        width: 100% !important;
        min-height: 230px;
    }
    #bgf__qas .new__crf .bvc_mnb h1 {
        font-size: 24px;
    }
    #bgf__qas .new__crf .bvc_mnb a.bnb_mki {
        width: 110px;
        height: 36px;
        font-size: 12px;
    }
    #bgf__qas .new__crf .bvc_mnb a.mki__lo {
        width: 90px;
        height: 36px;
        font-size: 12px;
        line-height: 20px;
    }
    .bg-dark #bgf__qas .bvc_mnb .container {
        padding-bottom: 0px !important;
        gap: 15px;
    }
    .bvc_mnb {
        position: relative !important;
        height: 100% !important;
        min-height: 230px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        top: unset !important;
        left: unset !important;
        right: unset !important
    }
}

#bgf__qas .new__crfm, #bgf__qas .bgt_hyn, #bgf__qas .bgt_hyn video {
    max-height: 800px;
}
#bgf__qas .bvc_mnb .container:after{
    display: none;
}
#bgf__qas .new__crf{
    margin-top: 1px !important;
}
#bgf__qas{
    position: relative;
}
#bgf__qas .new__crf:before{
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 2.1%;
}
#bgf__qas .new__crf:after {
    content: "";
    display: block;
    height: 0.75px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 48%;
    background: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    right: 2.1%;
}
#bgf__qas .bvc_mnb .container{
    padding-bottom: 110px !important;
}