#mkt__mkr__ndd .col.card_imga {
    display: flex;
    align-items: center;
    justify-content: center;
}
#mkt__mkr__ndd .col.card_imgb {
    display: flex;
    align-items: center;
    justify-content: center;
}
#mkt__mkr__ndd .col.card_imga img{
    width: 100% !important;
    max-height: 410px;
    min-height: 350px;
    object-fit: contain;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
}
#mkt__mkr__ndd .inns-div h1 {
    width: 106% !important;
}

@media screen and (max-width: 800px){
    #mkt__mkr__ndd .ambassador_card.row{
        display: none;
    }
    /* #mkt__mkr__ndd #mobileSlider .carousel .carousel__slider {
        max-height: 400px;
        background: #DBDCDE !important;
        min-height: 400px;
        display: flex;
        align-items: center;
    } */
    #mkt__mkr__ndd #mobileSlider{
        display: block !important;
    }
    #mkt__mkr__ndd #mobileSlider video {
        min-height: 360px !important;
        object-fit: cover !important;
        width: 100% !important;
        background: #000000;
    }
    #mkt__mkr__ndd #mobileSlider .col.card_txt{
        padding: 30px 0px 50px 0px !important;
    }
    #mkt__mkr__ndd .col.card_txt p{
        text-align: justify;
        font-family: 'hauora-extralight';
    }
    #mkt__mkr__ndd #mobileSlider  .carousel__slider-tray{
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 400px;
    }
    #mkt__mkr__ndd #mobileSlider .carousel__slider-tray .carousel__slide:nth-child(2) {
        padding: 50px 0px !important;
    }
    #mkt__mkr__ndd #mobileSlider .carousel__slider-tray .carousel__slide:nth-child(2) img {
        min-height: 300px !important;
        max-height: 300px !important;
        object-fit: contain !important;
    }
    #mkt__mkr__ndd .inns-div h1 {
        width: 100% !important;
    }
    #mkt__mkr__ndd #mobileSlider .col.card_txt h1{
        font-size: 36px !important;
        line-height: 44px !important;
    }
}