.Support_Searchbar video {
    margin-bottom: -5px;
    max-height: 490px!important;
    object-fit: cover;
    width: 100%;
}
.Support_Searchbar {
    background: #000;
    margin-top: -65px;
    max-height: 490px;
    position: relative;
}
/* #supportPage header#header-row {
    background: transparent;
} */
.Support_Searchbar div#search_container {
    left: 0;
    padding: 150px 0 0px 0px;
    position: absolute;
    right: 0;
    bottom: 0px;
    width: 100%!important;
    max-width: 1024px;
    margin: 0 auto;
}
#search_container h1 {
    font-size: 64px;
    color: #FFFFFF;
    line-height: 70px;
    font-family: 'urbanist-medium';
    margin-bottom: 50px;
}
div#question_tabs {
    padding: 100px 0px;
    position: relative;
}
.search_bar {
    z-index: 1;
    position: relative;
}
/* .search_bar .cmyNjn {
    min-height: 86px !important;
} */
.search_bar .wrapper {
    height: 100% !important;
    position: relative !important;
    border: 1px solid #e2e8f0 !important;
    background-color: #fff !important;
    width: 100% !important;
    border-radius: 15px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 22px;
    font-family: 'urbanist-regular' !important;
    box-shadow: 0px 29px 44px -33px rgb(0 0 0 / 37%) !important;
    min-height: 86px;
    padding-top: 20px;
}
.search_bar .wrapper:hover{
    box-shadow: 0px 29px 44px -33px rgb(0 0 0 / 37%);
}
.search_bar .wrapper input::placeholder{
    font-size: 22px;
    font-family: 'urbanist-regular' !important;
}
/* .search_bar .cmyNjn .wrapper .fDbOPw {
    min-height: 86px;
} */
.search_bar .cmyNjn .wrapper svg {
    width: 34px !important;
    height: auto;
}
.ellipsis {
    font-size: 16px;
    font-family: 'urbanist-regular';
}
.search_bar .wrapper ul {
    background: #FFFFFF;
    box-shadow: 0px 29px 44px -33px rgb(0 0 0 / 37%) !important;
    border: 1px solid #e2e8f0;
    margin-top: 30px;
    border-radius: 15px;
    padding-top: 10px;
}
.search_bar .wrapper .line{
    display: none;
}
.search_bar .wrapper ul li {
    padding: 15px 10px;
    cursor: pointer;
}
#question_tabs .col.first, #question_tabs .col.second, #question_tabs .col.third {
    background-color: #f7f9fe;
    min-height: 245px;
    padding: 30px 25px;
    /* border-radius: 10px; */
    max-width: 267px;
    flex: inherit;
    width: 33.333%;
    /* margin: 0 2rem; */
}
.question_row a.col:hover, .question_row a.col:focus, .question_row a.col:focus-visible{
    text-decoration: none;
    outline: none;
}
/* #question_tabs .col.second {
    background-color: #7650FF;
    min-height: 220px;
    padding: 30px 25px;
    border-radius: 10px;
    max-width: 300px;
    flex: inherit;
    width: 33.333%;
    margin: 0 2rem;
}
#question_tabs .col.third {
    background-color: #7650FF;
    min-height: 220px;
    padding: 30px 25px;
    border-radius: 10px;
    max-width: 300px;
    flex: inherit;
    width: 33.333%;
    margin: 0 2rem;
} */
.question_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    column-gap: 30px;
}
/* #question_tabs .container {
    max-width: 852px;
} */
#question_tabs .container{
    display: flex;
    column-gap: 40px;
    justify-content: center;
}
#question_tabs .container:before, #question_tabs .container:after{
    display: none;
}
#question_tabs .blog_heading {
    padding-right: 20px;
    max-width: 250px;
}
p.ticker_pass, p.ticker_pass a{
    color: #000000 !important;
    font-size: 14px;
    line-height: 24px;
    font-family: 'urbanist-regular';
}
p.ticker_pass a{
    text-decoration: underline;
}
a.support_singbtn {
    background: #5ED3F0 ;
    border: none;
    color: #FFFFFF;
    height: 44px;
    width: 170px;
    font-size: 16px;
    font-family: 'urbanist-medium';
    line-height: 24px;
    border-radius: 8px;
    margin-top: 20px;
}
a.support_singbtn:hover{
    background: #5ED3F0 ;
}
a.support_singbtn:hover, a.support_singbtn:focus, a.support_singbtn:focus-visible{
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
}
.question_ssdedaa {
    padding-left: 40px;
    border-left: 1px solid #eeeeee;
}
h1.open-ticket {
    font-family: 'urbanist-regular';
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 20px;
    margin-top: 20px;
}
.question_row h1 {
    font-size: 26px;
    color: #40475a;
    font-family: 'urbanist-medium';
    margin-bottom: 15px;
}
.question_row p {
    font-size: 16px;
    font-family: 'urbanist-regular';
    color: #40475a;
}
.animation\:fade-in-up {
    animation-name: fadeInUp;
}
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
/* #support_faqdetails header#header-row {
    background: transparent;
    background-color: transparent;
} */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0,2rem,0)
    }

    to {
        opacity: 1;
        transform: none
    }
}
.question_row a:hover {
    background: #FFFFFF !important;
    box-shadow: 0 0 16px rgb(184 201 255 / 30%);
}
.support_leftbar ul {
    padding: 0px;
    margin: 0px;
    margin: 20px 0px;
}
.support_leftbar ul li{
    list-style-type: none;
    padding: 8px 0px;
}
.support_leftbar ul li a {
    font-family: 'urbanist-regular';
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.support_leftbar ul li a:hover, .support_leftbar ul li a:focus, .support_leftbar ul li a:focus-visible{
    color: #5ED3F0 ;
    text-decoration: none;
}
.sub_title_ss{
    font-size: 22px;
    margin: 20px 0px;
    font-family: 'urbanist-regular';
}
#learnfaq .accordian {
    margin: 60px 0px 0px;
}
#learnfaq .accordian .item {
    border: 1px solid rgb(239, 239, 239);
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 10px;
}
#learnfaq .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px;
}
#learnfaq .accordian h2 {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    font-family: 'urbanist-semibold' !important;
    text-align: left;
}
#learnfaq span.svg {
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
#learnfaq span.svg.transform {
    transform: rotate(180deg);
    transition: all 0.4s cubic-bezier(1,1,1,1);
}
#learnfaq span.svg svg {
    width: 10px;
    height: auto;
}
#learnfaq span.svg{
    padding: 0px !important;   
}
#learnfaq span.svg svg path {
    fill: #000000;
}
#learnfaq .content.show {
    padding-top: 20px !important;
    height: auto;
    max-height: 9999px;
    transition: all 0.4s cubic-bezier(1,1,1,1);
    margin-bottom: 0px !important;
}
#learnfaq .accordian .content p {
    color: #000000;
    text-align: left;
    font-family: 'urbanist-regular' !important;
    font-size: 16px;
    margin-bottom: 10px;
}

/* ************************** */
#learnfaq .accordian details {
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 24px;
}
#learnfaq .accordian summary {
    padding: 0px !important;
    border: none;
    font-size: 18px !important;
    font-family: 'urbanist-semibold' !important;
}
.aas__ss svg {
    width: 10px;
    height: auto;
}
.aas__ss svg path{
    fill: #000000 !important;
}
#learnfaq .accordian .aas__ss{
    transition: all .4s cubic-bezier(1,1,1,1);
}
#learnfaq .accordian details[open] span.aas__ss {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: all .4s cubic-bezier(1,1,1,1);
}




/* *************************************** Details Page ********************************************* */
.details_faqs {
    padding: 150px 0px;
    min-height: 600px;
}
.details_faqs .col.fest {
    flex: inherit;
    padding-left: 0;
    padding-right: 40px;
    position: relative;
    width: 20%;
}
.details_faqs .col.sect {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 0 40px;
    position: relative;
    width: 60%;
    flex: inherit;
}
.details_faqs .col.thest {
    padding-left: 40px;
    padding-right: 0;
    position: relative;
    width: 20%!important;
    flex: inherit;
}
#artiles_faq .slider {
    height: 100% !important;
    min-height: 300px;
}
#artiles_faq .slider .carousel__slider-tray-wrapper {
    height: 100%;
    min-height: 300px;
    max-height: 300px;
}
#artiles_faq .slider .carousel__slide--hidden {
    height: 300px;
    max-height: 300px;
}
#artiles_faq .slider .carousel__slider-tray {
    height: 300px;
    max-height: 300px;
}
div#artiles_faq {
    padding: 60px 16px 16px!important;
    background: linear-gradient(192.05deg,#444 -355.42%,#141414 101.9%);
    max-height: 300px;
}
.faq-mod p {
    color: #FFFFFF;
    font-family: 'urbanist-regular';
    font-size: 20px;
    line-height: 28px;
    min-height: 56px;
    margin-bottom: 130px;
}
.faq-mod a {
    font-size: 18px;
    font-family: 'urbanist-regular';
    color: #5ED3F0 ;
    line-height: 24px;
    width: 100%;
    position: relative;
    display: block;
    text-align: end;
}
#artiles_faq button {
    background: none;
    border: none;
}
#artiles_faq button svg{
    width: 10px;
    height: auto;
}
#artiles_faq button svg path{
    fill: #FFFFFF;
}
#artiles_faq button.movenxt {
    position: absolute;
    top: 20px;
    right: 10px;
}
#artiles_faq button.moveback{
    position: absolute;
    top: 20px;
    left: 10px;
}
#artiles_faq button.moveback svg{
    transform: rotate(-180deg);
}
.details_faqs .row {
    margin: 0px !important;
}
.faq_nav {
    display: flex;
    column-gap: 13px;
    margin-top: 40px;
}
.faq_nav a {
    font-size: 16px;
    font-family: 'urbanist-medium';
    color: #000000;
    line-height: 24px;
}
.faq_nav p {
    font-size: 16px;
    font-family: 'urbanist-medium';
    color: #5ED3F0 ;
    line-height: 24px;
}
.arr_sv svg {
    width: 8px;
    display: block;
}
.arr_sv svg path{
    fill: rgba(0,0,0,0.2);
}
.arr_sv {
    padding-top: 3px;
}
ul.details_faqul {
    padding-left: 0px;
    padding-top: 10px;
}
ul.details_faqul li{
    list-style-type: none;
    padding: 15px 0px;
    font-size: 16px;
    font-family: 'urbanist-regular';
    color: #000000;
}
ul.details_faqul li button{
    font-size: 16px;
    font-family: 'urbanist-regular';
    color: #000000;
    background: none;
    padding: 0px;
    border: none;
    text-align: left;
}
ul.details_faqul li button:hover{
    text-decoration: none;
    color: #5ED3F0 ;
}
.blog_heading ul li{
    position: relative;
    width: fit-content;
}
.blog_heading ul li:after{
    content: "";
    position: absolute;
    display: block;
    top: 31px;
    width: 0;
    height: 2px;
    background-color: #5ED3F0;
    transition: 1s;
}
.blog_heading ul li:hover:after {
    width: 100%;
}
ul.details_faqul li button:focus, ul.details_faqul li button:focus-visible{
    text-decoration: none;
}
ul.details_faqul li button.active {
    color: #5ED3F0 ;
}
h1.support_ticket {
    font-size: 24px;
    line-height: 32px;
    font-family: 'urbanist-regular';
    margin-bottom: 20px;
}
p.support_txt {
    color: #000000;
    font-size: 14px;
    line-height: 24px;
    font-family: 'urbanist-regular';
}
p.support_txt a {
    color: #000000;
    text-decoration: underline;
}
a.support_signin {
    color: #FFFFFF;
    background: #5ED3F0 ;
    width: 170px;
    height: 44px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'urbanist-regular';
    margin-top: 30px;
}
.details_faqs .col.fest .social-icons {
    margin-top: 40px;
}
.details_faqs .col.fest .social-icons a {
    background: rgba(0,0,0,0.2);
    width: 44px;
    height: 44px;
}
.details_faqs .col.fest .social-icons a:hover{
    background: #5ED3F0 ;
}
.sprt-content{
    display: none;
}
.sprt-content.active{
    display: block;
}
.sprt-content h1 {
    font-size: 48px;
    line-height: 68px;
    font-family: 'urbanist-regular';
}
.sprt-content p {
    font-size: 16px;
    line-height: 24px;
    font-family: 'urbanist-regular';
    margin: 15px 0px;
}
.sprt-content img {
    width: 100%;
    margin: 25px 0px;
    filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.06));
}
#support_faq_details .sticky-bar {
    position: sticky;
    top: 100px;
}
.sprt-content ol{
    margin: 15px 0px;
}
.sprt-content li {
    font-size: 16px;
    padding: 10px 0px;
    line-height: 24px;
    font-family: 'urbanist-regular';
}
/* *************************************** Details Page ********************************************* */


/* *************************************** TableRow Miniumum despoit and withdrawal Starts ********** */
.coin-wrapper table {
    width: 100%;
    margin: 40px 0px;
}
tr.coin-headrow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0px;
    width: 100%;
}
tr.coin-headrow th {
    font-size: 16px;
    font-family: 'urbanist-regular' !important;
    font-weight: 400;
    color: rgba(0,0,0,0.4);
}
tr.coin-row {
    padding: 15px 0px !important;
    border-bottom: 1px solid #eeeeee !important;
    justify-content: space-between;
}
tr.coin-row td.logo-title {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
tr.coin-row td p, tr.coin-row td img{
    margin: 0px !important;
}
tr.coin-row td.logo-title img {
    width: 24px;
    height: auto;
}
.coin-row td, tr.coin-headrow th {
    width: 25%;
}
.coin-row td:nth-child(3), tr.coin-headrow th:nth-child(3){
    text-align: center;
}
.coin-row td:last-child, tr.coin-headrow th:last-child{
    text-align: end;
}
tr.coin-row td a{
    font-size: 16px;
    color: #5ED3F0 ;
    font-family: 'urbanist-regular';
}
tr.coin-row td a:hover{
    text-decoration: underline;
}
/* *************************************** TableRow Miniumum despoit and withdrawal end ************* */

/* *************************************** Calculation Table start ********************************** */
.calculation_table table{
    margin: 50px 0px;
    border: 1px solid #eeeeee !important;
    width: 100%;
    display: block;
}
.calculation_table table tr {
    padding: 0px;
    border-bottom: 1px solid #eeeeee !important;
    align-items: flex-start !important;
}
.calculation_table table tr th {
    padding: 15px 20px;
    width: 33.33%;
    font-size: 16px;
    font-family: 'urbanist-semibold' !important;
    font-weight: 600;
    min-height: 60px;
}
.calculation_table table tr td {
    padding: 15px 20px;
    width: 33.33%;
    flex: inherit;
    font-family: 'urbanist-regular';
}
.calculation_table table tr th:nth-child(2), .calculation_table table tr td:nth-child(2) {
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}
.calculation_table table p {
    margin: 0px 0px 20px 0px !important;
}
.calculation_table table tr:nth-child(4){
    border-bottom: 0px !important;
}
.calculation_table table tr:nth-child(3) td {
    min-height: 250px;
    max-height: 400px;
}
/* *************************************** Calculation Table end ***********************((*********** */



@media screen and (min-width: 1300px){
    .details_faqs .container {
        max-width: 1360px;
    }
}


@media screen and (max-width: 800px){
    .question_row{
        display: block;
    }
    .question_row .col{
        width: 100% !important;
        max-width: 100% !important;
    }
    #search_container h1 {
        font-size: 26px;
        line-height: 32px;
        margin: 20px;
    }
    .search_bar{
        margin: 0px 20px;
    }
    .search_bar .cmyNjn .wrapper .fDbOPw svg{
        width: 24px !important;
        height: auto !important;
    }
    .search_bar .wrapper input::placeholder, .search_bar .wrapper input{
        font-size: 16px;
        line-height: 24px;
        font-family: 'urbanist-regular' !important;
    }
    .search_bar .wrapper ul{
        margin-top: 10px;
    }
    .search_bar .cmyNjn .wrapper .sc-gswNZR.hCpHso li{
        padding: 10px 0px;
    }
    .question_row .col {
        width: 100% !important;
        max-width: inherit !important;
        position: relative;
        display: block;
        margin: 0px;
        /* max-height: 180px !important; */
        min-height: inherit;
        margin-bottom: 20px;
    }
    .question_row{
        margin-bottom: 20px !important;
    }
    div#question_tabs{
        padding: 80px 0px;
    }
    .details_faqs .col.thest {
        display: none;
    }
    .details_faqs .col.fest {
        width: 100%;
    }
    .details_faqs .col.sect{
        width: 100%;
        padding: 0px 0px;
        margin-top: 20px;
        border: none;
    }
    h1.support_ticket {
        display: none;
    }
    p.support_txt, a.support_signin, .details_faqs .col.fest .social-icons {
        display: none;
    }
    .details_faqs{
        padding: 100px 0px;
    }
    ul.details_faqul li{
        padding: 7px 0px;
    }
    .faq_nav{
        margin-top: 20px;
    }
    .sprt-content h1 {
        font-size: 26px;
        line-height: 32px;
    }
    .sprt-content p{
        font-size: 14px;
        line-height: 20px;
    }
    #question_tabs .container{
        display: block !important;
    }
    #question_tabs .container .blog_heading ul, h1.open-ticket, p.ticker_pass, .support_singbtn, #question_tabs .container .social-links{
        display: none;
    }
    .question_ssdedaa{
        padding-left: 0px !important;
        border: none !important;
        margin-top: 40px;
    }
    #support_faqdetails #insight_page .container {
        padding: 40px 0px !important;
    }
    .calculation_table table tr td, .calculation_table table tr th{
        padding: 15px 10px !important;
    }
    .calculation_table table tr td:nth-child(1), .calculation_table table tr th:nth-child(1){
        width: 25% !important;
    }
    .calculation_table table tr td:nth-child(2), .calculation_table table tr th:nth-child(2){
        width: 37.5% !important;
    }
    .calculation_table table tr td:nth-child(3), .calculation_table table tr th:nth-child(3){
        width: 37.5% !important;
    }
    .calculation_table table p {
        font-size: 12px;
    }
    .calculation_table table tr:nth-child(3) td {
        min-height: 300px;
        max-height: 600px;
    }
    #insight_detail .sticky-bar, .leftsidebar.blog-stick, .rightsidebar.blog-stick, .support_leftbar.blog-stick, .support_articles.blog-stick{
        position: relative;
        top: 0px;
    }
    .Support_Searchbar .backgrdound-video {
        display: none;
    }
    .Support_Searchbar div#search_container {
        position: relative;
        padding: 120px 0px 0px 0px;
    }
    .search_bar .wrapper{
        min-height: 66px;
        padding-top: 10px;
    }
    #insight_page h1 {
        font-size: 26px;
        line-height: 32px;
    }
    #insight_page h3.sub_title_ss {
        font-size: 16px;
        margin: 10px 0px 0px 0px;
        line-height: 24px;
    }
}