.trade__cfd {
    background: #000000;
    padding: 100px 0px;
    margin-top: 1px;
}
.trade__cfd .container {
    max-width: 1100px;
}
.indices__tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 70rem;
}   
button.ind__tab {
    border: 1px solid hsla(0,0%,100%,.15);
    color: #FFFFFF;
    padding: 10px 0px !important;
    background-color: #000;
    font-weight: 400;
    border-radius: 50px;
    flex: 0 0 33.333%;
    text-align: center;
    font-size: 16px;
    font-family: 'urbanist-regular';
    max-width: 32.5%;
    margin-bottom: 15px;
    height: 46.86px;
}
button.ind__tab.active {
    border: 1px #FFFFFF solid;
    color: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
button.ind__tab:hover{
    border: 1px #FFFFFF solid;
}
.ind__cont_tabs {
    margin-top: 50px;
}
.ind__cont {
    width: 100%;
    background: #111111;
    padding: 20px 40px 50px 40px;
}
.ind__cont table{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ind__cont table tr{
    transition: all .3s;
    position: relative;
    flex: 0 0 46%;
    justify-content: space-between;
    padding: 20px 10px !important;
    border-bottom: 1px solid hsla(0,0%,100%,.1) !important;
}
.ind__cont table tr td{
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'urbanist-light';
    text-align: end;
}
.ind__cont {
    display: none;
}
.ind__cont.active {
    display: block;
}
.ind__cont table tr:hover {
    background: #000000 !important;
    margin-top: -1px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-shadow: 0 0px 30px 0 rgba(255,255,255,.25);
}
.ind__cont table tr:hover td{
    color: #FFFFFF;
}
h1.hs_dpp {
    text-align: center;
    color: #FFFFFF;
    font-family: 'urbanist-light';
    font-size: 32px;
    margin-bottom: 50px;
}
.inner__contt .crypto__cont {
    display: none;
}
.inner__contt .crypto__cont.active {
    display: block;
}
.inner__crypto {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 40px;
    margin: 20px 0px 40px 0px;
}
button.crypto__tab {
    border: 1px solid hsla(0,0%,100%,.15);
    color: #FFFFFF;
    padding: 0px 10px !important;
    background-color: #000;
    font-weight: 400;
    border-radius: 50px;
    flex: 0 0 13%;
    text-align: center;
    font-size: 16px;
    font-family: 'urbanist-regular';
    margin-bottom: 15px;
    height: 40px;
}
button.crypto__tab:hover{
    border: 1px #FFFFFF solid;
}
button.crypto__tab.active, button.stock__tab.active{
    border: 1px #FFFFFF solid;
    color: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}
button.stock__tab{
    border: 1px solid hsla(0,0%,100%,.2);
    color: #FFFFFF;
    padding: 0px 10px !important;
    background-color: #000;
    font-weight: 400;
    border-radius: 50px;
    flex: 0 0 22%;
    text-align: center;
    font-size: 16px;
    font-family: 'urbanist-regular';
    margin-bottom: 15px;
    height: 40px;
}
@media screen and (max-width: 800px){
    .indices__tab{
        gap: 10px;
    }
    button.ind__tab{
        padding: 0px !important;
        font-size: 14px !important;
        max-width: unset !important;
        margin-bottom: 0px !important;
        flex: auto !important;
        min-width: 100px !important;
        height: 36px !important;
    }
    .ind__cont{
        padding: 20px 0px 40px 0px !important;
    }
    .ind__cont table tr td{
        font-size: 14px;
    }
    .ind__cont table tr{
        flex: 0 0 100%;
    }
    h1.hs_dpp{
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 30px;
    }
    .ind__cont_tabs{
        margin-top: 20px;
    }
    button.crypto__tab, button.stock__tab{
        padding: 0px !important;
        font-size: 14px !important;
        max-width: unset !important;
        margin-bottom: 0px !important;
        flex: auto !important;
        min-width: 80px !important;
        height: 36px !important;
    }
    .inner__crypto{
        max-height: inherit;
    }
}